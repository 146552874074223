/*
 *   File : settings.js
 *   Author URI : www.evoqins.com
 *   Description : Container screen to list available settings.
 *   Integrations : null
 *   Version : v1.0
 *   Created : 
 */

// Packages
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "bootstrap";

// Custom components
import { CustomTabBar } from "../../../Components/Tab";
import { CustomTitle } from "../../../Components/Title";
import { PrimaryButton } from "../../../Components/Buttons";
import { ListBanners } from "./Helpers";
import { ManageBannerModal } from "../../../Components/Modal";

// import services
import APIService from "../../../Services/api-service";

const TAB_OPTIONS = [
    {
        label: "FAQ",
        id: 2
    },
    {
        label: "Terms & conditions",
        id: 3
    },
    {
        label: "Privacy policy",
        id: 4
    },
    {
        label: "Disclaimer",
        id: 5
    },
    {
        label: "Value pair setting",
        id: 6
    },
    {
        label: "User category settings",
        id: 7
    }
]

const Settings = () => {
    const navigate = useNavigate();
    const [tabIndex, setTabIndex] = useState(TAB_OPTIONS[0].id);

    const [bannerDetail, setBannerDetail] = useState(null);
    const [openBannerModal, setOpenBannerModal] = useState(false);

    const [pageLoader, setPageLoader] = useState(true);
    const [bannerList, setBannerList] = useState([]);

    const [bannerOptions, setBannerOptions] = useState([]);
    const [navTypeOptions, setNavTypeOptions] = useState([]);
    const [resourceOptions, setResourceOptions] = useState([]);
    const [goalOptions, setGoalOptions] = useState([]);

    useEffect(() => {
        _getBannerList();
        _getBannerFilter();
        _getResources();
        _getGoals();
    }, []);


    useEffect(() => {
        if (openBannerModal === true) {
            const modal = new Modal(document.getElementById("manage-banner"));
            modal.show();
        }
    }, [openBannerModal])

    function _handleTab(tab_index) {
        setTabIndex(tab_index);
        switch (tab_index) {
            case 1:
                navigate('/app-settings');
                break;
            case 2:
                navigate('faq');
                break;
            case 3:
                navigate('terms-conditions');
                break;
            case 4:
                navigate('privacy-policy');
                break;
            case 5:
                navigate('disclaimer');
                break;
            case 6:
                navigate('value-pair-setting');
                break;
            case 7:
                navigate('user-category-setting');
                break;
            default:
                break;
        }
    }

    function _handleBannerModal(status, detail) {
        setOpenBannerModal(status);
        setBannerDetail(detail);
    }

    // API - banner list
    const _getBannerList = () => {
        const url = "/banner/list";
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setBannerList(response.data);
            } else {
                setBannerList([])
            }
            setPageLoader(false);
        })
    }

    // API - Banner filter 
    const _getBannerFilter = async () => {
        const url = "/banner/filter-data";

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setBannerOptions(response.data.banner_type);
                setNavTypeOptions(response.data.nav_type);
            }
        });
    }

    // API - get resources
    const _getResources = () => {
        const url = "/banner/get-resource";

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setResourceOptions(response.data);
            }
        });
    }

    // API - get goals
    const _getGoals = () => {
        const url = "/banner/get-goals";

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setGoalOptions(response.data);
            }
        });
    }


    return (
        <Fragment>
            <div className="e-bg-light-lavender pt-3 padding-52px-lr">
                <CustomTitle title="App Settings" type={1} />
            </div>
            <div className="e-body-wrapper">
                <div className="bg-white border-radius-32px padding-40px-bottom padding-26px-top">
                    <div className="row padding-32px-lr">
                        <div className="col-12">
                            <CustomTabBar data={TAB_OPTIONS}
                                selectedTab={tabIndex}
                                onSelectTab={_handleTab} />
                        </div>
                        <div className="col-12 padding-32px-top margin-22px-bottom">
                            <div className="d-flex justify-content-between align-items-center">
                                <h4 className="e-montserrat-semi-bold e-font-16 e-line-height-20 color-dark-charcoal mb-0">
                                    Banner images
                                </h4>
                                <PrimaryButton
                                    label="Add banner"
                                    className="padding-10px-tb px-4"
                                    handleClick={() => _handleBannerModal(true, null)} />
                            </div>
                        </div>
                    </div>
                    {
                        pageLoader === true ?
                            <div className="h-80vh d-flex align-items-center justify-content-center">
                                <img src={require("../../../Assets/Images/loader/page-loader.gif")}
                                    alt={"loader"}
                                    width={60}
                                    height={60}
                                />
                            </div>
                            :
                            <ListBanners bannerList={bannerList}
                                handleEdit={(row) => _handleBannerModal(true, row)}
                                updateList={_getBannerList} />
                    }

                </div>

            </div>
            {
                openBannerModal === true && <ManageBannerModal bannerOptions={bannerOptions}
                    navTypeOptions={navTypeOptions}
                    resourceOptions={resourceOptions}
                    goalOptions={goalOptions}
                    banner={bannerDetail}
                    closeModal={() => _handleBannerModal(false, null)}
                    bannerUpdated={() => {
                        _getBannerList();
                        _handleBannerModal(false, null)
                    }} />
            }

        </Fragment>
    )
}

export default Settings