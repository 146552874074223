
/*
 *   File : nudge-preview.js
 *   Author URI : https://evoqins.com
 *   Description : Nudge preview which shows how the nudges are going to be displayed in mobile application.
 *   Integrations : null
 *   Version : v1.1
 */

import { Fragment, useEffect } from "react";


// Custom styles
import style from "../../Styles/Components/nudge-preview.module.scss";
import Icon from "../Icon/icon";


const NudePreviewModal = (props) => {

    useEffect(() => {
        var my_modal = document.getElementById("nudge-preview");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
        });
        //eslint-disable-next-line
    }, [props.close]);

    return (
        <Fragment>
            <div className={`modal fade ${style.e_modal}`}
                id="nudge-preview"
                tabIndex="-1"
                aria-labelledby="nudge-preview"
                aria-hidden="true"
                data-bs-backdrop="true" >
                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                    <div className={`modal-content padding-32px-lr padding-40px-top pb-0 ${style.e_content} `}>
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <h5 className="e-font-16 e-line-height-24 e-montserrat-semi-bold color-dark-charcoal mb-0">
                                View nudge
                            </h5>
                            <Icon icon="close"
                                size={18}
                                id="close-modal"
                                data-bs-dismiss="modal"
                                className='cursor-pointer' />
                        </div>
                        <div className={`d-flex align-items-center justify-content-center position-relative `}>
                            <img src={require("../../Assets/Images/notification/view-notification.png")}
                                alt="View notification"
                                className="h-100" />
                            <div className={`position-absolute border-radius-6px padding-12px-all ${style.e_notification}`}>
                                <div className="margin-6px-bottom d-flex align-items-center gap-8px">
                                    {/* <img src={props.data.logo_image}
                                        alt="Notification_image"
                                        width={12} /> */}

                                    <p className="e-montserrat-semi-bold e-font-6 e-line-height-12 mb-0 color-white">{props.data.title}</p>
                                </div>
                                <p className="e-montserrat-regular e-font-6 e-line-height-12 mb-0 color-white  text-break">{props.data.content}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default NudePreviewModal