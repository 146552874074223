/*
 *   File : detail.js
 *   Author : https://evoqins.com
 *   Description : Ticket Detail
 *   Version : 1.0.0
*/


// packages
import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Custom components
import { Breadcrumb } from "../../../Components/Others";
import { Badge } from "../../../Components/Badge";
import Config from "../../../Helper/config";
import APIService from "../../../Services/api-service";


const TicketDetails = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [ticketDetail, setTicketDetail] = useState({});
    const [loader, setLoader] = useState(true);


    useEffect(() => {
        if (location.state !== null) {
            _getTicketDetails(location.state.id)
        }
    }, [location.state])

    function _handleNavigate() {
        navigate(-1);
    }

    // API - ticket details
    const _getTicketDetails = (id) => {
        const url = "/support/detail";

        const request = {
            ticket_id: id
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setTicketDetail(response.data);
            }
            setLoader(false);
        })
    }
    return (
        loader === true ?
            <div className="h-60vh d-flex align-items-center justify-content-center">
                <img src={require("../../../Assets/Images/loader/page-loader.gif")}
                    alt={"loader"}
                    width={60}
                    height={60}
                />
            </div>
            :
            <Fragment>
                <div className="e-bg-light-lavender pt-3 padding-52px-lr">
                    <div className="padding-110px-bottom">
                        <Breadcrumb handleNavigate={_handleNavigate}
                            mainTitle="Support tickets"
                            subTitle={ticketDetail.ticket_no} />
                    </div>
                </div>
                <div className="e-body-wrapper">
                    <div className="row">
                        <div className="col-12">
                            <div className="bg-white border-radius-16px padding-36px-tb padding-32px-lr">
                                <div className="e-bg-pale-lilac p-3  row-gap-16px">
                                    <div className="row">
                                        <div className="col-2">
                                            <h6 className="color-dark-gray e-montserrat-medium e-font-14 e-line-height-24 mb-2">
                                                Ticket ID
                                            </h6>
                                            <p className="color-dark-charcoal pt-xl-0 pt-0 e-font-14 e-line-height-24 e-montserrat-semi-bold mb-0">
                                                {ticketDetail.ticket_no}
                                            </p>
                                        </div>
                                        <div className="col-2">
                                            <h6 className="color-dark-gray e-montserrat-medium e-font-14 e-line-height-22 mb-2">
                                                Created on
                                            </h6>
                                            <p className="color-dark-charcoal pt-xl-0 pt-0 e-font-14 e-line-height-24 e-montserrat-semi-bold mb-0">
                                                {ticketDetail.created_on}
                                            </p>
                                        </div>
                                        <div className="col-2">
                                            <h6 className="color-dark-gray e-montserrat-medium e-font-14 e-line-height-22 mb-2">
                                                Category
                                            </h6>
                                            <p className="e-montserrat-semi-bold e-font-14 e-line-height-24 mb-0 color-dark-charcoal  pt-xl-0 pt-0">
                                                {ticketDetail.category ? ticketDetail.category : "-"}
                                            </p>
                                        </div>
                                        <div className="col-2">
                                            <h6 className="color-dark-gray e-montserrat-medium e-font-14 e-line-height-22 mb-2">
                                                Assigned agent
                                            </h6>
                                            <p className="color-dark-charcoal pt-xl-0 pt-0 e-font-14 e-line-height-24 e-montserrat-semi-bold mb-0">
                                                {ticketDetail.agent ? ticketDetail.agent : "-"}
                                            </p>
                                        </div>
                                        <div className="col-2">
                                            <h6 className="color-dark-gray e-montserrat-medium e-font-14 e-line-height-22 mb-2">
                                                Priority
                                            </h6>
                                            <p className="color-dark-charcoal pt-xl-0 pt-0 e-font-14 e-line-height-24 e-montserrat-semi-bold mb-0">
                                                {ticketDetail.priority_text ? ticketDetail.priority_text : "-"}
                                            </p>
                                        </div>
                                        <div className="col-2">
                                            <h6 className="color-dark-gray e-montserrat-medium e-font-14 e-line-height-22 mb-2">
                                                Ticket status
                                            </h6>
                                            <Badge status={ticketDetail.ticket_status}
                                                color={ticketDetail.ticket_status === "Resolved" || ticketDetail.ticket_status === "Closed" ? Config.BADGE_COLORS.green : ticketDetail.ticket_status === "Assigned" ? Config.BADGE_COLORS.blue : Config.BADGE_COLORS.orange} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-white border-radius-32px py-4 padding-32px-lr mt-4">
                                <h5 className="e-font-16 e-line-height-24 e-montserrat-semi-bold color-dark-charcoal mb-0">
                                    Ticket details
                                </h5>
                                <div className={`mt-4`}>
                                    <div className="border-1px border-color-mint-gray p-4 border-radius-16px">
                                        <p className="mb-3 color-dark-gray e-montserrat-medium e-font-14 e-line-height-20 border-bottom-1px border-color-mint-gray pb-3">Subject: <span className="color-dark-charcoal e-montserrat-semi-bold">{ticketDetail.subject}</span></p>
                                        <div className="row">
                                            <div className="col-4">
                                                <span className="color-dark-gray e-montserrat-medium e-font-14 e-line-height-20 margin-2px-bottom">ID</span>
                                                <p className="color-dark-charcoal e-font-14 e-line-height-20 e-montserrat-semi-bold mb-3">{ticketDetail.ticket_no}</p>
                                            </div>
                                            <div className="col-4">
                                                <span className="color-dark-gray e-montserrat-medium e-font-14 e-line-height-20 margin-2px-bottom">Created on</span>
                                                <p className="color-dark-charcoal e-font-14 e-line-height-20 e-montserrat-semi-bold mb-3">{ticketDetail.created_on}</p>
                                            </div>
                                            <div className="col-4">
                                                {ticketDetail.image && <Fragment>
                                                    <span className="color-dark-gray e-montserrat-medium e-font-14 e-line-height-20 margin-2px-bottom">Screenshot/Photo</span>
                                                    <button className="e-customize-btn  e-font-14 e-line-height-18 e-montserrat-semi-bold py-1 px-2 d-flex gap-4px align-items-center" onClick={() => window.open(ticketDetail.image, "_blank")}>
                                                        <img src={require('../../../Assets/Images/Tickets/image.png')} alt="ticket"
                                                            width={18}
                                                            height={16} />
                                                        {ticketDetail.image ? ticketDetail.image.split("?")[0].split("/").splice(-1) : "-"}
                                                    </button>
                                                </Fragment>}
                                            </div>

                                            <div className="col-12">
                                                <span className="color-dark-gray e-montserrat-medium e-font-14 e-line-height-20 margin-2px-bottom">Description</span>
                                                <p className="color-dark-charcoal e-font-14 e-line-height-20 e-montserrat-semi-bold mb-3">
                                                    {ticketDetail.description}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="border-1px border-color-mint-gray p-4 border-radius-16px mt-3">
                                        <p className="mb-3 color-dark-gray e-montserrat-medium e-font-14 e-line-height-20 border-bottom-1px border-color-mint-gray pb-3">
                                            Basic profile information & account status
                                        </p>
                                        <div className="row">

                                            <div className="col-4">
                                                <p className="color-dark-gray e-montserrat-medium e-font-14 e-line-height-20 margin-2px-bottom">Name</p>
                                                <p className="color-dark-charcoal e-font-14 e-line-height-20 e-montserrat-semi-bold mb-3">{ticketDetail.username}</p>
                                            </div>
                                            <div className="col-4">
                                                <p className="color-dark-gray e-montserrat-medium e-font-14 e-line-height-20 margin-2px-bottom">Email</p>
                                                <p href={`mailto:${ticketDetail.email}`} className="e-font-14 e-line-height-18 e-montserrat-semi-bold color-rebecca-purple mb-0 text-decoration-hover-underline cursor-pointer">
                                                    {ticketDetail.email === null ? "-" : ticketDetail.email}
                                                </p>
                                            </div>
                                            <div className="col-4">
                                                <p className="color-dark-gray e-montserrat-medium e-font-14 e-line-height-20 margin-2px-bottom">Phone</p>
                                                <p href={`tel:+91${ticketDetail.mobile}`} className="e-font-14 e-line-height-18 e-montserrat-semi-bold color-rebecca-purple mb-0 text-decoration-hover-underline cursor-pointer">
                                                    {ticketDetail.mobile === null ? "-" : `+91-${ticketDetail.mobile}`}
                                                </p>
                                            </div>
                                            <div className="col-4">
                                                <p className="color-dark-gray e-montserrat-medium e-font-14 e-line-height-20 margin-2px-bottom">Account status</p>
                                                <p className="color-dark-charcoal e-font-14 e-line-height-20 e-montserrat-semi-bold mb-3">{ticketDetail.ticket_status}</p>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        ticketDetail.orders.length !== 0 &&
                                        <div className="border-1px border-color-mint-gray pt-4 px-4 pb-2 border-radius-16px mt-4">
                                            <p className="mb-3 color-dark-gray e-montserrat-medium e-font-14 e-line-height-20 border-bottom-1px border-color-mint-gray pb-3">
                                                Last 3 orders
                                            </p>
                                            <div className="row pb-3">
                                                <div className="col-4">
                                                    <span className="color-dark-gray e-montserrat-medium e-font-14 e-line-height-20">Order ID</span>

                                                </div>
                                                <div className="col-4">
                                                    <span className="color-dark-gray e-montserrat-medium e-font-14 e-line-height-20">Created on</span>
                                                </div>

                                                <div className="col-4">
                                                    <span className="color-dark-gray e-montserrat-medium e-font-14 e-line-height-20">Status</span>
                                                </div>

                                            </div>
                                            {
                                                ticketDetail.orders.map((item, index) => {
                                                    return (
                                                        <div key={index} className={index > 0 ? 'border-top-1px border-color-mint-gray py-2 mt-2' : ''}>
                                                            <div className="row align-items-center">
                                                                <div className="col-4">
                                                                    <p className="color-dark-charcoal e-font-14 e-line-height-20 e-montserrat-semi-bold mb-0 cursor-pointer"
                                                                        data-bs-dismiss="modal"
                                                                        onClick={() => navigate("/orders/detail", {
                                                                            state: {
                                                                                id: item.order_id
                                                                            }
                                                                        })}>{item.order_no}</p>
                                                                </div>
                                                                <div className="col-4">
                                                                    <p className="color-dark-charcoal e-font-14 e-line-height-20 e-montserrat-semi-bold mb-0">{item.created}</p>
                                                                </div>

                                                                <div className="col-4">
                                                                    <Badge color={item.status === "Successful" ? Config.BADGE_COLORS.green :
                                                                        item.status === "Pending" ? Config.BADGE_COLORS.orange : Config.BADGE_COLORS.red} status={item.status} />
                                                                </div>

                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Fragment>
    )
}

export default TicketDetails