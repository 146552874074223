/*
 *   File : create-referral.js
 *   Author : https://evoqins.com
 *   Description : Container screen to create referral.
 *   Version : 1.0.0
*/

// Packages
import { Fragment, useEffect, useState } from "react";

// Components
import { CustomTitle } from "../../../Components/Title";
import { Modal } from "bootstrap";
import { PrimaryButton, SecondaryButton } from "../../../Components/Buttons";
import { CustomTextArea, CustomTextInput1, RadioGroup } from "../../../Components/FormElements";
import { ReferralModal } from "../../../Components/Modal";

// Helper
import { _emailValidator } from "../../../Helper/helper";


const RADIO_OPTIONS = [
    {
        label: "Email",
        id: 1
    },
    {
        label: "SMS",
        id: 2
    },
    {
        label: "WhatsApp message ",
        id: 3
    }
]
const CreateReferral = () => {
    const [invitationType, setInvitationType] = useState(RADIO_OPTIONS[0].id);

    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");

    const [subject, setSubject] = useState("");
    const [subjectError, setSubjectError] = useState("");

    const [content, setContent] = useState("");
    const [contentError, setContentError] = useState("");

    const [refer, setRefer] = useState(false);


    useEffect(() => {
        if (refer === true) {
            const modal = new Modal(document.getElementById("referral"));
            modal.show();
        }
    }, [refer])

    // handle Invitation type
    function _handleInvitation(id) {
        setInvitationType(id);
    }

    // handle Email
    function _handleEmail(input_value) {
        setEmail(input_value);
        setEmailError("");
    }

    // handle Referral modal
    function _handleReferral(status) {
        setRefer(status);
    }

    // handle Content
    function _handleContent(input_value) {
        setContent(input_value);
        setContentError("");
    }

    // handle Subject
    function _handleSubject(input_value) {
        setSubject(input_value);
        setSubjectError("");
    }

    // handle form validation
    function _handleSubmitForm() {
        let valid = true;

        if (email === "") {
            valid = false;
            setEmailError("Email is required");
        }
        if (email !== "" && _emailValidator(email) === false) {
            valid = false;
            setEmailError("Invalid email");
        }
        if (subject === "") {
            valid = false;
            setSubjectError("Subject is required");
        }

        if (content === "") {
            valid = false;
            setContentError("Content is required");
        }

        if (valid === true) {
            _clearFields();
        }
    }

    // clear fields
    function _clearFields() {
        setEmail("");
        setEmailError("");
        setSubject(null);
        setSubjectError("");
        setContent("");
        setContentError("");

    }
    return (
        <Fragment>
            <div className="e-bg-light-lavender pt-3 padding-52px-lr">
                <CustomTitle title="Create Reference" type={1} />
            </div>
            <div className="e-body-wrapper">
                <div className="bg-white border-radius-32px padding-40px-top padding-70px-bottom padding-32px-lr">
                    <div className="d-flex align-items-center justify-content-between">
                        <h5 className="e-font-16 e-line-height-20 e-montserrat-semi-bold color-dark-charcoal mb-0">
                            Invite new users
                        </h5>
                        <PrimaryButton label="Create referral code/ URL"
                            className="padding-10px-tb padding-12px-lr"
                            handleClick={() => _handleReferral(true)} />
                    </div>
                    <div className="row pt-4">
                        <div className="col-12 pb-4 mb-1">
                            <p className="e-montserrat-medium color-dark-charcoal e-font-14 e-line-height-18 cursor-text mb-2 letter-spacing-025px">Invitation type
                                <span className='color-deep-carmine-pink'>
                                    *
                                </span>
                            </p>
                            <RadioGroup data={RADIO_OPTIONS}
                                id={invitationType}
                                radioHandler={_handleInvitation} />
                        </div>
                        <div className="row">
                            <div className="col-6 pb-4 mb-1">
                                <CustomTextInput1 label="Email address"
                                    type="email"
                                    postfix={"*"}
                                    error={emailError}
                                    value={email}
                                    placeHolder="Email"
                                    handleChange={_handleEmail} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6 pb-4 mb-1">
                                <CustomTextInput1 label="Email subject"
                                    type="text"
                                    postfix={"*"}
                                    error={subjectError}
                                    value={subject}
                                    placeHolder="Email subject"
                                    handleChange={_handleSubject} />
                            </div>
                        </div>
                        <div className="row pb-5">
                            <div className="col-6">
                                <CustomTextArea label="Upload email content"
                                    postfix={"*"}
                                    error={contentError}
                                    value={content}
                                    placeHolder="Email content"
                                    handleChange={_handleContent} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div className="d-flex justify-content-end gap-16px">
                                    <SecondaryButton label="Cancel"
                                        className="px-4 padding-14px-tb"
                                        handleClick={_clearFields} />
                                    <PrimaryButton label="Send Invitation"
                                        className="px-4 padding-14px-tb"
                                        handleClick={_handleSubmitForm} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                refer === true && <ReferralModal closeModal={() => _handleReferral(false)} />
            }
        </Fragment>
    )

}
export default CreateReferral