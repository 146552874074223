import { toast } from "react-toastify";
import APIService from "../Services/api-service";
import store from "../Store";

// API - Get file path
const _getFilePath = async (request) => {
    return new Promise(async (resolve, reject) => {
        const url = '/file/upload';
        await APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                resolve(response);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
        }).catch((error) => {
            reject(error);
        });
    });
};

// API - Get Order filter data
const _getOrderFilterData = async () => {
    return new Promise(async (resolve, reject) => {
        const url = "/orders/filter-data";

        await APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                resolve(response)
            }
        }).catch((error) => {
            reject(error);
        });
    });
}


const _listContent = async (request) => {
    return new Promise(async (resolve, reject) => {
        const url = "/content/list-content";

        await APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                resolve(response)
            } else {
                resolve(response)
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

const _manageContent = async (request) => {
    return new Promise(async (resolve, reject) => {
        const url = "/content/update";

        await APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                resolve(response)
            } else {
                toast.dismiss()
                toast.error(response.message, {
                    type: 'error'
                });
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

const _getNotificationCount = async () => {
    return new Promise(async (resolve, reject) => {
        const url = "/notification/count";

        await APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                store.dispatch({
                    type: "NOTIFICATION_COUNT",
                    payload: response.data.notification_count
                })
            }
        }).catch((error) => {
            reject(error);
        });
    });
}
export { _getFilePath, _getOrderFilterData, _listContent, _manageContent, _getNotificationCount }