/*
 *   File : segment.js
 *   Author : https://evoqins.com
 *   Description : Normal bar component
 *   Integrations : react-guage-component
 *   Version : 1.0.0
*/

// Packages
import { memo } from "react";
import GaugeComponent from "react-gauge-component";

// Configuration
import Config from "../../Helper/config";

const CustomSegmentChart = (props) => {

    return (
        <div className="position-relative ">
            <GaugeComponent
                type="semicircle"
                className="e-guage-component mx-auto"
                style={{
                    width: "100%",
                    height: "100px"
                }}
                arc={{
                    colorArray: Config.SEGMENT_COLORS,
                    padding: 0.04,
                    cornerRadius: 100,
                    radial: 0.8,
                    width: 0.20,
                    subArcs:
                        [
                            { limit: 2, length: 0.2 },
                            { limit: 4, length: 0.2 },
                            { limit: 6, length: 0.2 },
                            { limit: 8, length: 0.2 },
                            { limit: 10, length: 0.2 },
                        ]
                }}
                pointer={{
                    type: "blob",
                    animationDelay: 0,
                    color: "#FE625F",
                    width: 20
                }}
                labels={{
                    valueLabel: {
                        hide: true
                    },
                    tickLabels: {
                        hideMinMax: true
                    }
                }}
                value={props.value * 10}

            />

        </div>
    )
}

export default memo(CustomSegmentChart)