/*
 *   File : referral.js
 *   Author URI : https://evoqins.com
 *   Description : Referral modal
 *   Integrations : null
 *   Version : v1.1
 */

import { useEffect, } from "react";

// Custom components
import { Icon } from "../Icon";
import { toast } from "react-toastify";
import { CustomTextInput1 } from "../FormElements";
import { TextButton } from "../Buttons";

// Custom styles
import style from "../../Styles/Components/modal.module.scss";

const ReferralModal = (props) => {

    useEffect(() => {
        var my_modal = document.getElementById("referral");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
        });
        //eslint-disable-next-line
    }, []);

    return (
        <div className={`modal fade ${style.e_modal_wrapper}`}
            id="referral"
            tabIndex="-1"
            aria-labelledby="referral"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content padding-32px-all ${style.e_content} `}>
                    <div className="d-flex justify-content-between align-items-center">
                        <h5 className="e-font-16 e-line-height-24 e-montserrat-semi-bold color-dark-charcoal mb-0">
                            Share referral code
                        </h5>
                        <Icon icon="close"
                            size={24}
                            id="close-modal"
                            data-bs-dismiss="modal"
                            className='cursor-pointer' />
                        <span id="close" data-bs-dismiss="modal" className="d-none"></span>
                    </div>
                    <div className="row pt-4">
                        <div className="col-12 position-relative">
                            <CustomTextInput1 label="Referral code/URL"
                                value="https://example.com/referral/ABCD2345"
                                isDisabled={true} />
                            <TextButton name="Copy link" hide_arrow={true}
                                className="position-absolute top-36px right-40px e-font-14"
                                onPress={() => {
                                    navigator.clipboard.writeText(window.location.href);
                                    toast.dismiss();
                                    toast.success("URL copied");
                                }} />
                        </div>
                        <div className="col-12 pt-4">
                            <label className="color-eerie-black e-montserrat-medium e-font-14 e-line-height-18 margin-20px-bottom">Share in:</label>
                            <div className="d-flex gap-16px">
                                <img src={require("../../Assets/Images/Referral/facebook.png")}
                                    alt="facebook"
                                    width={42}
                                    height={42}
                                    className="cursor-pointer"
                                    onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${"https://example.com/referral/ABCD2345"}`)} />
                                <img src={require("../../Assets/Images/Referral/twitter.png")}
                                    alt="twitter"
                                    width={42}
                                    height={42}
                                    className="cursor-pointer"
                                    onClick={() => window.open(`https://twitter.com/intent/tweet?url=${"https://example.com/referral/ABCD2345"}`)} />
                                <img src={require("../../Assets/Images/Referral/linked-in.png")}
                                    alt="linked"
                                    width={42}
                                    height={42}
                                    className="cursor-pointer"
                                    onClick={() => window.open(`https://www.linkedin.com/?url=${"https://example.com/referral/ABCD2345"}`)} />
                                <img src={require("../../Assets/Images/Referral/telagram.png")}
                                    alt="telagram"
                                    width={42}
                                    height={42}
                                    className="cursor-pointer"
                                    onClick={() => window.open(`https://web.telegram.org/a/?u=${"https://example.com/referral/ABCD2345"}`)} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReferralModal