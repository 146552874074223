/*
 *   File : manage-faq.js
 *   Author URI : https://evoqins.com
 *   Description : Manage FAQ modal 
 *   Integrations : null
 *   Version : v1.1
 */

// Packages 
import { memo, useEffect, useState } from "react";
import { toast } from "react-toastify";

// Custom components
import { Icon } from "../Icon";
import { CustomSelect, CustomTextArea, CustomTextInput1 } from "../FormElements"
import { PrimaryButton, SecondaryButton } from "../Buttons";

// Custom styles
import style from "../../Styles/Components/modal.module.scss";

// Services
import APIService from "../../Services/api-service";


const ManageFaqModal = (props) => {

    const [question, setQuestion] = useState("");
    const [questionError, setQuestionError] = useState("");

    const [answer, setAnswer] = useState("");
    const [answerError, setAnswerError] = useState("");

    const [faqType, setFaqType] = useState(null);
    const [faqTypeError, setFaqTypeError] = useState("");

    const [apiLoader, setApiLoader] = useState(false);
    const [faqId, setFaqId] = useState(null);


    useEffect(() => {
        var my_modal = document.getElementById("manage-faq");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
        });
        //eslint-disable-next-line
    }, [props.closeModal]);

    useEffect(() => {
        if (props.data !== null) {
            setQuestion(props.data.question);
            setAnswer(props.data.answer);
            setFaqId(props.data.faq_id);
            const faq_type_index = props.faq_types.findIndex((item) => item.value === props.data.faq_type_id);
            const faq_type = props.faq_types[faq_type_index]
            setFaqType(faq_type)
        }
        //eslint-disable-next-line
    }, [props.data])

    // question value handler
    function _handleQuestion(input_value) {
        setQuestion(input_value);
        setQuestionError("");
    }

    // answer handler
    function _handleAnswer(input_value) {
        setAnswer(input_value);
        setAnswerError("");
    }

    // faq type handler
    function _handleFaqType(type) {
        setFaqType(type)
    }

    // validation submit
    function _submitFaq() {
        let valid = true;

        if (question === "") {
            setQuestionError("Question is required");
            valid = false;
        }

        if (faqType === null) {
            setFaqTypeError("Faq type is required");
            valid = false;
        }

        if (answer === "") {
            setAnswerError("Answer is required");
            valid = false;
        }

        if (valid === true) {
            _manageFaq()
        }
    }


    // API - manage faq
    const _manageFaq = () => {
        setApiLoader(true);
        const url = "/content/upsert-faq";

        const request = {
            faq_id: faqId,
            faq_type_id: faqType.value,
            question: question,
            answer: answer.trim()
        }
        APIService(true, url, request).then((response) => {
            toast.dismiss()
            if (response.status_code === 200) {
                toast.success(props.data === null ? "Faq created" : "Faq updated", {
                    type: 'success'
                });
                props.faqUpdated()
                document.getElementById("close-modal").dispatchEvent(new Event("click"));
            } else {
                toast.error(response.message, {
                    type: "error"
                });
            }
            setApiLoader(false);
        })
    }

    return (
        <div className={`modal fade ${style.e_modal_wrapper}`}
            id="manage-faq"
            tabIndex="-1"
            aria-labelledby="manage-faq"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content padding-32px-all ${style.e_content} `}>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <h5 className="e-font-16 e-line-height-24 e-montserrat-semi-bold color-dark-charcoal mb-0">
                            {
                                props.data === null ?
                                    "Add FAQ"
                                    :
                                    "Update FAQ"
                            }
                        </h5>
                        <Icon icon="close"
                            size={24}
                            id="close-modal"
                            data-bs-dismiss="modal"
                            className='cursor-pointer' />
                        <span id="close" data-bs-dismiss="modal" className="d-none"></span>
                    </div>
                    <div className="row">
                        <div className="col-12 mb-4">
                            <CustomSelect type={3}
                                label="Faq type"
                                postFix={true}
                                options={props.faq_types}
                                value={faqType}
                                error={faqTypeError}
                                placeholder="Faq type"
                                selectChange={_handleFaqType} />
                        </div>
                        <div className="col-12 mb-4">
                            <CustomTextInput1 label="Question"
                                type="text"
                                postfix={"*"}
                                error={questionError}
                                value={question}
                                placeHolder="Question"
                                handleChange={_handleQuestion} />
                        </div>
                        <div className="col-12 mb-4">
                            <CustomTextArea label="Answer"
                                type="text"
                                postfix={"*"}
                                error={answerError}
                                value={answer}
                                placeHolder="Answer"
                                handleChange={_handleAnswer} />
                        </div>
                    </div>
                    <div className="d-flex justify-content-end gap-16px mt-3">
                        <SecondaryButton label="Cancel"
                            cancel="modal"
                            className="padding-14px-tb padding-50px-lr" />
                        <PrimaryButton label={props.data === null ? "Add" : "Update"}
                            className="padding-14px-tb padding-60px-lr"
                            disabled={apiLoader}
                            loader={apiLoader}
                            handleClick={_submitFaq} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(ManageFaqModal)