/*
 *   File : portfolio.js
 *   Author : https://evoqins.com
 *   Description : Portfolio Card component
 *   Integrations : js-cookie
 *   Version : 1.0.0
*/

// Packages
import { Fragment, memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Custom components
import { TextButton } from "../Buttons";
import { CustomTabBar } from "../Tab";

const TAB_OPTIONS = [

    {
        label: "Active",
        short_name: "active",
        id: 1
    },
    {
        label: "Passive",
        short_name: "passive",
        id: 2
    },

]

const PortfolioCard = (props) => {
    const navigate = useNavigate();
    const [showTooltip, setShowTooltip] = useState(null);

    const [tabIndex, setTabIndex] = useState(TAB_OPTIONS[0].id);

    const [basketType, setBasketType] = useState(TAB_OPTIONS[0].short_name);

    useEffect(() => {
        _handleTab(TAB_OPTIONS[0].id);
    }, [props.index])

    function _handleTooltip(id) {
        setShowTooltip(id);
    }

    function _handleTab(tab_index) {
        setTabIndex(tab_index);
        const index = TAB_OPTIONS.findIndex((item) => item.id === tab_index);
        setBasketType(TAB_OPTIONS[index]['short_name']);

    }

    function _handleEdit() {
        navigate("manage-model", {
            state: {
                portfolio: props.data,
                type: props.index,
                tab: tabIndex
            }
        })
    }

    function _totalAllocation() {
        let totalAllocation = 0;

        const activeFunds = props.data.funds[`${basketType}`].fund_data;
        for (let assetClass in activeFunds) {
            //eslint-disable-next-line
            activeFunds[assetClass].forEach(fund => {
                if (fund.primary_fund === true) {
                    totalAllocation += fund.allocation;
                }
            });
        }

        return totalAllocation
    }

    return (
        <Fragment>
            <div className="border-1px border-lilac-frost e-bg-white-lilac padding-32px-tb px-3 border-radius-32px position-relative h-100">
                {
                    props.data.is_primary &&
                    <div className="position-absolute top-10px left-32px">
                        <img src={require("../../Assets/Images/Icons/primary.svg").default}
                            alt="primary"
                            height={18}
                            width={62}
                            className="cursor-pointer"
                            onMouseEnter={() => _handleTooltip(props.data.id)}
                            onMouseLeave={() => _handleTooltip(null)} />


                    </div>
                }
                {
                    showTooltip === props.data.id &&
                    <div className="position-absolute e-font-14 top-38px e-montserrat-medium e-line-height-18 color-jett-black e-box-shadow-tooltip p-3  border-radius-8px bg-white z-index-10 w-95">
                        This model portfolio will be the default option for this specified risk type
                    </div>
                }
                <div className="d-flex justify-content-between align-items-center px-3">
                    <h6 className="color-dark-charcoal e-font-14 e-montserrat-bold e-line-height-24 mb-0">
                        {props.data.title}
                    </h6>
                    <TextButton name="Edit"
                        hide_arrow={true}
                        className="e-montserrat-bold e-line-height-24 e-font-14"
                        onPress={_handleEdit} />
                </div>
                <span className="color-dark-gray e-montserrat-regular e-font-12 e-line-height-18 px-3">
                    Total allocation: <span className="color-dark-charcoal e-montserrat-semi-bold e-line-height-24">{_totalAllocation()}%</span>
                </span>

                <div className="col-12 pb-1 px-3">
                    <CustomTabBar data={TAB_OPTIONS}
                        selectedTab={tabIndex}
                        onSelectTab={_handleTab} />
                </div>

                <div className="e-bg-pastel-lavender p-3 border-radius-12px e-box-shadow-black36 mt-2">
                    <div className="row pb-2">
                        <div className="col-8">
                            <h6 className="e-font-14 e-line-height-24 color-dark-gray e-montserrat-bold mb-0">Fund name</h6>
                        </div>
                        <div className="col-4 text-end">
                            <h6 className="e-font-14 e-line-height-24 color-dark-gray e-montserrat-bold mb-0">Allocation</h6>
                        </div>
                    </div>

                    {

                        Object.keys(props.data.funds[`${basketType}`].fund_data).length > 0 ?

                            Object.entries(props.data.funds[`${basketType}`].fund_data).map(([item, funds], index) => {

                                return (
                                    <Fragment>
                                        {
                                            //  eslint-disable-next-line 
                                            funds.map((fund_obj) => {
                                                if (fund_obj.primary_fund === true) {
                                                    return (
                                                        <div className="row pb-3">
                                                            <div className="col-8">
                                                                <span className="e-font-12 e-line-height-18 color-dark-charcoal e-montserrat-medium">{fund_obj.fund_name}</span>
                                                            </div>
                                                            <div className="col-4 text-end">
                                                                <span className="e-font-14 e-line-height-20 color-dark-charcoal e-montserrat-semi-bold">{fund_obj.allocation}%</span>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            })

                                        }
                                    </Fragment>


                                )
                            })
                            :

                            <span className="e-font-12 e-line-height-18 color-dark-charcoal e-montserrat-medium">No funds added</span>
                    }
                    {
                        props.data.funds[`${basketType}`].benchmark_data &&
                        <Fragment>
                            <div className="w-100 border-bottom-1px border-color-mint-gray"></div>
                            <div className="d-flex justify-content-between align-items-center pt-3">
                                {props.data.funds[`${basketType}`].benchmark_data.benchmark_name && <h6 className="e-font-12 e-line-height-18 color-black e-montserrat-semi-bold mb-0">
                                    Benchmark :  {props.data.funds[`${basketType}`].benchmark_data.benchmark_name}
                                </h6>}
                                <TextButton name="View more"
                                    hide_arrow={true}
                                    onPress={() => props.viewMore(props.data.funds[`${basketType}`].fund_data)} />
                            </div>
                        </Fragment>
                    }

                </div>
            </div>

        </Fragment >
    )
}

export default memo(PortfolioCard)