const _uploadDocumentToS3 = async (data, file) => {
    var formdata = new FormData();
    formdata.append("key", data.upload_info.fields.key);
    formdata.append("x-amz-algorithm", data.upload_info.fields['x-amz-algorithm']);
    formdata.append("x-amz-credential", data.upload_info.fields['x-amz-credential']);
    formdata.append("x-amz-date", data.upload_info.fields['x-amz-date']);
    formdata.append("policy", data.upload_info.fields['policy']);
    formdata.append("x-amz-signature", data.upload_info.fields['x-amz-signature']);
    formdata.append("file", file, file.name);

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    return fetch(data.upload_info.url, requestOptions)
        .then(response => response.text())
        .then(result => {
            return result
        }).catch(error => {
            return error
        })
}

export {
    _uploadDocumentToS3
}