/*
 *   File : password.js
 *   Author : https://evoqins.com
 *   Description : Password component
 *   Integrations : null
 *   Version : 1
*/

// packages
import React, { useCallback, useRef } from 'react';

// components
import { Icon } from '../Icon';

// styles
import style from "../../Styles/Components/password.module.scss"
import Color from '../../Styles/color.module.scss'

const CustomPasswordInput = React.memo((props) => {

    const inputRef = useRef(null);

    const _handleChange = useCallback((event) => {
        const input_value = event.target.value;
        if (props.maxLength) {
            if (input_value.length <= props.maxLength) {
                if (props.pan) {
                    props.handleChange(input_value.toUpperCase());
                    return;
                }
                props.handleChange(input_value);
            }
            return;
        }
        if (props.is_phone === true) {
            const indian_phone_regex = /^[6-9]\d*$/;
            if (input_value.length <= 10) {
                if (indian_phone_regex.test(input_value)) {
                    props.handleChange(input_value);
                    return true
                } else {
                    props.handleChange('');
                    return false
                }
            }
        }
        // Check if it's a name and allow only space and dot characters
        if (props.personName) {
            const validNameRegex = /^[A-Za-z. ]*$/;
            if (validNameRegex.test(input_value)) {
                props.handleChange(input_value);
            }
        } else {
            props.handleChange(input_value);
        }
        //eslint-disable-next-line
    }, [props.handleChange, props.maxLength, props.pan, props.personName]);

    // Preventing non numeric characters if type is number 
    // Validating because Firefox and Safari don't support type number 
    const _validateNumber = (e) => {
        const key_code = e.keyCode || e.which;
        const is_ctrl_pressed = e.ctrlKey || e.metaKey; // Check if Ctrl key is pressed
        const allowed_key_codes = [9, 8, ...Array.from({ length: 10 }, (_, i) => 48 + i), ...Array.from({ length: 10 }, (_, i) => 96 + i), ...Array.from({ length: 4 }, (_, i) => 37 + i)];

        if (is_ctrl_pressed && [65, 67, 88, 86].includes(key_code)) {
            // Ctrl+A, Ctrl+C, Ctrl+X, or Ctrl+V
            return null;
        }

        if (!allowed_key_codes.includes(key_code)) {
            e.preventDefault();
        }
    };

    const _onKeyDown = (e) => {
        if (props.type === 'number') {
            _validateNumber(e);
            // Disable arrow up and down keys
            if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                e.preventDefault();
            }
            if (e.key === '0' && props.value === '') {
                e.preventDefault();
            }
        }
        if (e.keyCode === 13) {
            props.onSubmit()
        }
        if (e.key === ' ' && e.target.selectionStart === 0) {
            e.preventDefault(); // Prevent the space from being entered
        }
    }

    function _handleWheel(event) {
        document.activeElement.blur();
    }

    function _handleToggle() {
        props.togglePassword()
        if (inputRef.current) {
            setTimeout(() => {
                inputRef.current.focus();
                inputRef.current.setSelectionRange(
                    inputRef.current.value.length,
                    inputRef.current.value.length
                );
            }, 0);
        }
    }


    return (
        <div className={
            props.error && props.error.length !== 0 ?
                `${props.className} ${style.e_input_wrapper} ${style.e_error_input} position-relative w-100` :
                `${props.className} ${style.e_input_wrapper} position-relative w-100`}>
            {/* label */}
            <p className={`${props.labelClass} e-montserrat-medium color-dark-charcoal e-font-14 e-line-height-18 cursor-text margin-6px-bottom bg-transparent`}
                htmlFor={props.label}>
                {props.label}
                <span className='color-deep-carmine-pink'>
                    {props.postfix}
                </span>
            </p>

            {props.prefix && <span className="position-absolute left-14px top-38px e-montserrat-medium e-font-14 e-line-height-20">{props.prefix}</span>}
            {/* Input box */}
            <input ref={inputRef}
                disabled={props.isDisabled}
                value={props.value}
                placeholder={props.placeHolder}
                id={props.id}
                autoComplete={props.autoComplete}
                type={props.type ? props.type : 'text'}
                className={`${style.e_input} ${props.inputClass} color-charleston-green e-montserrat-medium e-font-16 e-line-height-18 w-100 no-outline`}
                onKeyDown={_onKeyDown}
                onChange={_handleChange}
                onWheel={_handleWheel}
                style={props.prefix ? { paddingLeft: "28px" } : {}}
                required />

            <Icon icon={props.show ? "eye-active-icon" : "eye-icon"} color={Color.dark_gray}
                size={20}
                className="position-absolute top-38px right-20px cursor-pointer"
                onClick={_handleToggle} />


            {/* Error text */}
            {
                props.error &&
                <div className='position-absolute d-flex align-items-center margin-6px-top w-max-content'>
                    {/* <Icon icon="info-circle"
                        size={16}
                        className="me-1" /> */}
                    <p className='color-deep-carmine-pink  e-montserrat-medium e-font-12  e-line-height-16 mb-0 '>
                        {props.error}
                    </p>
                </div>
            }
        </div>
    )
})

CustomPasswordInput.defaultProps = {
    vlue: null,
    handleChange: () => { },
    className: '',
    error: null,
    autoComplete: "off",
    labelClass: 'bg-white'
};

export default CustomPasswordInput;