/*
 *   File : checkbox.js
 *   Author URI : www.evoqins.com
 *   Description : Checkbox component
 *   Integrations : NA
 *   Version : v1.1
 */
/* import packages */
import { useEffect, useState } from "react";

/* import components */
import Icon from "../Icon/icon";
import { Fragment } from "react";

const CustomCheckBox = (props) => {

    const [checked, setChecked] = useState(props.checked);

    useEffect(() => {
        setChecked(props.checked);
    }, [props.checked]);

    const _handleClick = () => {
        props.onChange(!checked);
    }

    return (
        <Fragment>
            {
                props.type === 2 ?
                    props.data.map((item) => {
                        return (
                            <div className={`d-flex gap-8px align-items-center cursor-pointer w-max-content`}
                                onClick={() => props.onChange(item.id)}>
                                <Icon icon={
                                    item.id === props.selectedId ?
                                        'checked' :
                                        'unchecked'
                                }
                                    size={24} />
                                <span className={`color-dark-charcoal e-montserrat-medium e-font-14 e-line-height-24 letter-spacing-007em`}>
                                    {item.label}
                                </span>
                            </div>
                        )
                    })

                    :
                    <div className={`d-flex gap-8px align-items-center cursor-pointer w-max-content`}
                        onClick={() => _handleClick()}>
                        <Icon icon={
                            props.checked ?
                                'checked' :
                                'unchecked'
                        }
                            size={24} />
                        <span className={`color-dark-charcoal e-montserrat-medium e-font-14 e-line-height-24 letter-spacing-007em`}>
                            {props.label}
                        </span>
                    </div>}
        </Fragment>

    )
}

CustomCheckBox.defaultProps = {
    label: "",
    onChange: () => { }
}

export default CustomCheckBox;