/*
 *   File : nudge.js
 *   Author : https://evoqins.com
 *   Description : Nudges and Banner Container file.
 *   Version : 1.0.0
*/

import { Fragment, useState } from "react"
import { CustomTabBar } from "../../../Components/Tab"
import { CustomTitle } from "../../../Components/Title";
import { Banners, NudgeList } from "./index";


const TAB_DATA = [
    {
        label: "App Nudges",
        id: 1
    },
    {
        label: "Banners",
        id: 2
    }
]

const Nudge = () => {

    const [tabIndex, setTabIndex] = useState(1);

    function _handleTab(tab_index) {
        setTabIndex(tab_index);
    }
    return (
        <Fragment>
            <div className="e-bg-light-lavender pt-3 padding-52px-lr">
                <CustomTitle title="Nudges & Banners" type={1} />
            </div>

            <div className="e-body-wrapper">

                <div className="row">
                    <div className="col-12">
                        <div className="e-bg-white border-radius-32px pt-4 padding-40px-bottom">

                            <div className="px-4 mb-3">
                                <CustomTabBar data={TAB_DATA}
                                    selectedTab={tabIndex}
                                    onSelectTab={_handleTab} />

                            </div>
                            {
                                tabIndex === 1 ?
                                    <NudgeList />
                                    :
                                    <Banners />
                            }
                        </div>
                    </div>
                </div>


            </div>

        </Fragment>
    )
}

export default Nudge