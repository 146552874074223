/*
 *   File : percentage-input.js
 *   Author : https://evoqins.com
 *   Description :Percentage Input component
 *   Integrations : null
 *   Version : 1
*/
import React, { useCallback, useRef } from 'react';

import style from "../../Styles/Components/custom-textbox.module.scss"

const PercentageInput = React.memo((props) => {

    const inputRef = useRef(null);

    const _handleChange = useCallback((event) => {
        let input_value = event.target.value;
        if ((input_value === '0' || (input_value >= 0 && input_value <= 100))) {
            props.handleChange(input_value);
        }
        // eslint-disable-next-line
    }, [props.handleChange]);

    const _onKeyDown = (e) => {
        if (props.type === 'number') {
            const key = e.key;
            // Allow only numbers, dot, backspace, delete, and arrow keys
            if (!/^\d$|^\.|^Backspace$|^Delete$|^ArrowLeft$|^ArrowRight$/.test(key)) {
                e.preventDefault();
            }
            if (props.min === 0) {
                // Prevent entering a dot if it's already present or if the value is empty
                if ((key === '.' && (props.value === '' || props.value.includes('.'))) || (key === '0' && props.value.length >= 1)) {
                    e.preventDefault();
                }
            } else if (props.value.toString().includes(".") && props.value !== "" && e.keyCode !== 8) {
                const decimal_value = props.value.split(".")[1];
                if (decimal_value.length >= 2) {
                    e.preventDefault();
                }
            } else {
                if ((key === '.' && (props.value === '' || props.value.toString().includes('.'))) || (key === '0' && props.value === " ")) {

                    e.preventDefault();
                }
            }
        }
    };


    function _handleWheel(event) {
        document.activeElement.blur();
    }


    return (
        <div className={
            props.error && props.error.length !== 0 ?
                `${props.className} ${style.e_input_wrapper} ${style.e_error_input} position-relative w-100` :
                `${props.className} ${style.e_input_wrapper} position-relative w-100`}>
            {/* label */}
            <p className={`${props.labelClass} e-montserrat-medium color-dark-charcoal e-font-14 e-line-height-18 cursor-text margin-6px-bottom bg-transparent`}
                htmlFor={props.label}>
                {props.label}
                <span className='color-deep-carmine-pink'>
                    {props.postfix}
                </span>
            </p>
            {/* Input box */}
            <input ref={inputRef}
                disabled={props.isDisabled}
                value={props.value}
                placeholder={props.placeHolder}
                id={props.id}
                autoComplete={props.autoComplete}
                type={'number'}
                className={`${style.e_input} ${props.inputClass} color-dark-charcoal e-montserrat-medium e-font-16 e-line-height-18 w-100 no-outline`}
                onKeyDown={_onKeyDown}
                onChange={_handleChange}
                onWheel={_handleWheel}
                required />

            <span className="position-absolute right-14px top-40px e-montserrat-medium e-font-14 e-line-height-20">%</span>

            {/* Error text */}
            {
                props.error &&
                <div className='position-absolute d-flex align-items-center margin-6px-top w-max-content'>
                    {/* <Icon icon="info-circle"
                        size={16}
                        className="me-1" /> */}
                    <p className='color-deep-carmine-pink  e-montserrat-medium e-font-12  e-line-height-16 mb-0 '>
                        {props.error}
                    </p>
                </div>
            }
        </div >
    )
})

PercentageInput.defaultProps = {
    value: null,
    handleChange: () => { },
    className: '',
    error: null,
    autoComplete: "off",
    type: 'number',
};

export default PercentageInput;