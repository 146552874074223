/*
 *   File :  wysiwyg-editor.js
 *   Author : https://evoqins.com
 *   Description : HTML editor component. 
 *   Integrations : react-draft-wysiwyg
 *   Version : 1.0.0
*/

import React, { Fragment, memo, useState } from 'react';
import { ContentState, EditorState, convertFromHTML, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import style from "../../Styles/Components/file-editor.module.scss";

const CustomWysiwygEditor = (props) => {
    // Convert HTML string to DraftJS ContentState
    const [editorState, setEditorState] = useState(() => {
        console.log("outside", props.value, props.value.length);
        if (props.value.length !== 0) {
            console.log("inside", props.value);
            const blocksFromHTML = convertFromHTML(props.value);
            const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
            return EditorState.createWithContent(contentState);
        } else {
            return EditorState.createEmpty();
        }
    });


    const isEditorEmpty = (contentState) => {
        const plainText = contentState.getPlainText().trim();
        return !plainText.length;
    };

    const _onEditorStateChange = (newEditorState) => {
        setEditorState(newEditorState);
        const contentState = newEditorState.getCurrentContent();
        const htmlContent = draftToHtml(convertToRaw(contentState));
        const editorIsEmpty = isEditorEmpty(contentState);
        if (editorIsEmpty === true) {
            props.onEditorStateChange("");
        } else {
            props.onEditorStateChange(htmlContent);
        }
    };

    return (
        <Fragment>
            <p className={`${props.labelClass} e-montserrat-medium color-eerie-black e-font-14 e-line-height-22 cursor-text margin-6px-bottom bg-transparent`}
                htmlFor={props.label}>
                {props.label}
                <span className='color-deep-carmine-pink'>
                    {props.postfix}
                </span>
            </p>
            <div className='border-1px border-light-gray border-radius-8px padding-14px-lr padding-14px-tb position-relative' id={props.id}>
                <Editor
                    editorState={editorState}
                    editorClassName={style.e_editor}
                    toolbarClassName={style.e_editor_toolbar}
                    toolbar={{
                        options: ['inline', 'list', 'textAlign', 'link', 'blockType'],
                        inline: { inDropdown: false, options: ['bold', 'italic', 'underline'] },
                        list: { inDropdown: false },
                        textAlign: { inDropdown: false },
                        link: {
                            inDropdown: false,
                            popupClassName: style.link_dropDown
                        },
                        blockType: {
                            inDropdown: true,
                            options: ['H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Code'],
                            className: style.blockType
                        }
                    }}
                    onEditorStateChange={_onEditorStateChange}
                />
                {
                    props.error &&
                    <p className={`color-deep-carmine-pink  e-montserrat-medium e-font-12  e-line-height-16 my-0 position-absolute ${style.e_error}`}>
                        {props.error}
                    </p>
                }
            </div>
        </Fragment>
    );
};

export default memo(CustomWysiwygEditor);
