/*
 *   File : change-password.js
 *   Author URI : https://evoqins.com
 *   Description : Change password modal 
 *   Integrations : null
 *   Version : v1.1
 */


import { Fragment, memo, useEffect, useState } from "react";
import { toast } from "react-toastify";

// Custom components
import { Icon } from "../Icon";
import { PrimaryButton, SecondaryButton } from "../Buttons";
import { CustomPasswordInput } from "../FormElements";

// Custom styles
import style from "../../Styles/Components/modal.module.scss";

// services
import APIService from "../../Services/api-service";

const ChangePasswordModal = (props) => {


    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");

    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");

    const [currentPassword, setCurrentPassword] = useState("");
    const [currentPasswordError, setCurrentPasswordError] = useState("");

    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [apiLoader, setApiLoader] = useState(false);

    useEffect(() => {
        var my_modal = document.getElementById("change-password");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
        });
        //eslint-disable-next-line
    }, []);

    //current password handler
    function _handleCurrentPassword(input_value) {
        setCurrentPassword(input_value);
        setCurrentPasswordError("");
    }

    // password handler
    function _handlePassword(input_value) {
        setPassword(input_value);
        setPasswordError("");
    }

    // confirm password handler
    function _handleConfirmPassword(input_value) {
        setConfirmPassword(input_value);
        setConfirmPasswordError("");
    }


    // password toggle
    function _handleCurrentPasswordToggle() {
        setShowCurrentPassword(!showCurrentPassword)
    }

    // password toggle
    function _handlePasswordToggle() {
        setShowPassword(!showPassword)
    }

    // password toggle
    function _handleConfirmPasswordToggle() {
        setShowConfirmPassword(!showConfirmPassword)
    }

    function _submitPassword() {

        let valid = true;
        if (currentPassword === "") {
            setCurrentPasswordError("Current password is required");
            valid = false;
        }

        if (currentPassword !== "" && currentPassword.length < 8) {
            setCurrentPasswordError("Password must be at least 8 characters long");
            valid = false;
        }

        if (password === "") {
            setPasswordError("Password is required");
            valid = false;
        }

        if (password !== "" && password.length < 8) {
            setPasswordError("Password must be at least 8 characters long");
            valid = false;
        }


        if (confirmPassword === "") {
            setConfirmPasswordError("Confirm password is required");
            valid = false;
        }

        if (confirmPassword !== "" && confirmPassword.length < 8) {
            setConfirmPasswordError("Password must be at least 8 characters long");
            valid = false;
        }

        if (confirmPassword !== "" && password !== "" && password !== confirmPassword) {
            setConfirmPasswordError("Password not matching");
            valid = false;
        }

        if (valid === true) {
            _changePassword();
        }
    }

    // API - change password 
    const _changePassword = () => {
        setApiLoader(true);
        const url = "/auth/change-password";

        const request = {
            current_password: currentPassword,
            new_password: confirmPassword
        }

        APIService(true, url, request).then((response) => {
            toast.dismiss()
            if (response.status_code === 200) {
                document.getElementById("close").dispatchEvent(new Event("click"));

                toast.success("Password changed successfully", {
                    type: "success"
                })
            } else {
                toast.error(response.message, {
                    type: 'error'
                })
            }
            setApiLoader(false)
        })

    }
    return (
        <Fragment>
            <div className={`modal fade ${style.e_change_password}`}
                id="change-password"
                tabIndex="-1"
                aria-labelledby="change-password"
                aria-hidden="true"
                data-bs-backdrop="true" >
                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                    <div className={`modal-content padding-32px-all ${style.e_content} `}>
                        <div className="d-flex justify-content-between align-items-center">
                            <h5 className="e-font-16 e-line-height-24 e-montserrat-semi-bold color-dark-charcoal mb-0">
                                Change password
                            </h5>
                            <Icon icon="close"
                                size={24}
                                id="close"
                                data-bs-dismiss="modal"
                                className='cursor-pointer' />
                        </div>
                        <div className="pt-4">
                            <div className="e-bg-light-purple px-2 pb-2 pt-1 border-radius-12px d-flex gap-4px align-items-start  mb-4">
                                <span>
                                    <Icon icon="circle-information" size={16} />
                                </span>
                                <span className="e-font-12 e-line-height-18 e-montserrat-regular color-dark-gray pt-1">
                                    Your new password must be at least 8 characters long.
                                    It should include a mix of uppercase and lowercase letters, numbers, and special characters.
                                </span>
                            </div>

                            <CustomPasswordInput label="Current password"
                                postfix="*"
                                type={showCurrentPassword === true ? "text" : "password"}
                                value={currentPassword}
                                error={currentPasswordError}
                                placeHolder="Enter current password"
                                show={showCurrentPassword}
                                togglePassword={_handleCurrentPasswordToggle}
                                onSubmit={_submitPassword}
                                handleChange={_handleCurrentPassword} />
                            <CustomPasswordInput label="Password"
                                postfix="*"
                                type={showPassword === true ? "text" : "password"}
                                value={password}
                                error={passwordError}
                                placeHolder="Enter password"
                                show={showPassword}
                                className='mt-4'
                                togglePassword={_handlePasswordToggle}
                                onSubmit={_submitPassword}
                                handleChange={_handlePassword} />

                            <CustomPasswordInput label="Confirm password"
                                postfix="*"
                                type={showConfirmPassword === true ? "text" : "password"}
                                value={confirmPassword}
                                error={confirmPasswordError}
                                placeHolder="Confirm password"
                                className='mt-4'
                                show={showConfirmPassword}
                                onSubmit={_submitPassword}
                                togglePassword={_handleConfirmPasswordToggle}
                                handleChange={_handleConfirmPassword} />
                        </div>
                        <div className="d-flex justify-content-end gap-16px margin-32px-top">
                            <SecondaryButton label="Cancel"
                                cancel="modal"
                                className="padding-14px-tb padding-50px-lr" />
                            <PrimaryButton label={"Update"}
                                loader={apiLoader}
                                disabled={apiLoader}
                                className="padding-14px-tb padding-60px-lr"
                                handleClick={_submitPassword} />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )

}

export default memo(ChangePasswordModal)