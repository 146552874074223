/*
 *   File : title.js
 *   Author : https://evoqins.com
 *   Description : Screen title
 *   Version : 1.0.0
*/
// packages
import { Fragment, memo } from "react"


// ============= type 1 :: screen title 
//  ============ type 2 :: section title
const CustomTitle = (props) => {
    return (
        <Fragment>
            {
                props.type === 1 ?
                    <div className="padding-104px-bottom">
                        <h2 className="e-montserrat-semi-bold e-font-20 e-line-height-24 color-dark-charcoal mb-0">
                            {props.title}
                        </h2>
                    </div>
                    :
                    <h2 className="e-montserrat-semi-bold e-font-20 e-line-height-26 color-dark-charcoal letter-spacing-007em mb-0">
                        {props.title}
                    </h2>
            }
        </Fragment>
    )
}

export default memo(CustomTitle);
