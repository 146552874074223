/*
 *   File :  left-navigation.js
 *   Author URI : https://evoqins.com
 *   Description : Left side navigation in the platform
 *   Version : 1
 */

// packages
import React, { memo, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

// components
import { Icon } from "../Icon";

// Custom styles
import style from '../../Styles/Components/left-navigation.module.scss';


// Dashboard Options
const DASHBOARD_OPTIONS = [
    {
        name: "Dashboard",
        link: "/",
        image_inactive: "dashboard-inactive",
        image_active: require("../../Assets/Images/LeftNavigation/dashboard-active.svg").default,
    },
    {
        name: "Customers",
        link: "/customer",
        image_inactive: "customers-inactive",
        image_active: require("../../Assets/Images/LeftNavigation/customers-active.svg").default,
    },
    {
        name: "Orders",
        link: "/orders",
        image_inactive: "orders-inactive",
        image_active: require("../../Assets/Images/LeftNavigation/orders-active.svg").default,
    },
    {
        name: "Model Portfolios",
        link: "/manage-portfolio",
        image_inactive: "portfolio-inactive",
        image_active: require("../../Assets/Images/LeftNavigation/portfolio-active.svg").default,
    },

    // {
    //     name: "Custom Portfolios",
    //     link: "/custom-portfolio",
    //     image_inactive: "portfolio-inactive",
    //     image_active: require("../../Assets/Images/LeftNavigation/portfolio-active.svg").default,
    // },
    {
        name: "Manage Risk",
        link: "/manage-risk",
        image_inactive: "risk-inactive",
        image_active: require("../../Assets/Images/LeftNavigation/risk-active.svg").default,
    },
    {
        name: "Manage Resource",
        link: "/manage-resource",
        image_inactive: "resource-inactive",
        image_active: require("../../Assets/Images/LeftNavigation/resource-active.svg").default,
    },
    // {
    //     name: "Manage Pyramid",
    //     link: "/manage-pyramid",
    //     image_inactive: "pyramid-inactive",
    //     image_active: require("../../Assets/Images/LeftNavigation/pyramid-active.svg").default,
    // },
    {
        name: "Push Notification",
        link: "/push-notification",
        image_inactive: "notification-inactive",
        image_active: require("../../Assets/Images/LeftNavigation/notification-active.svg").default,
    },
    // {
    //     name: "Email Campaign",
    //     link: "/email-campaign",
    //     image_inactive: "campaign-inactive",
    //     image_active: require("../../Assets/Images/LeftNavigation/campaign-active.svg").default,
    // },
    {
        name: "App Nudges",
        link: "/app-nudges",
        image_inactive: "nudges-inactive",
        image_active: require("../../Assets/Images/LeftNavigation/nudges-active.svg").default,
    },
    {
        name: "App Settings",
        link: "/app-settings",
        image_inactive: "settings-inactive",
        image_active: require("../../Assets/Images/LeftNavigation/settings-active.svg").default,
    },
    {
        name: "Manage Custom MF",
        link: "/manage-mf",
        image_inactive: "mf-inactive",
        image_active: require("../../Assets/Images/LeftNavigation/notification-active.svg").default,
    },
    {
        name: "Manage Admins",
        link: "/manage-admin",
        image_inactive: "manage-admin-inactive",
        image_active: require("../../Assets/Images/LeftNavigation/manage-admin-active.svg").default,
    },
    {
        name: "Support Tickets",
        link: "/support",
        image_inactive: "ticket",
        image_active: require("../../Assets/Images/LeftNavigation/ticket-active.svg").default,
    },
];

// Support Dashboard Options
const SUPPORT_DASHBOARD_OPTIONS = [
    {
        name: "Support Tickets",
        link: "/support",
        image_inactive: "ticket",
        image_active: require("../../Assets/Images/LeftNavigation/ticket-active.svg").default,
    },
];

// RM Dashboard Options
const RM_DASHBOARD_OPTIONS = [
    {
        name: "Dashboard",
        link: "/",
        image_inactive: "dashboard-inactive",
    },
    {
        name: "Create Reference",
        link: "/reference",
        image_inactive: "reference-inactive",
    },
];
const LeftNavigationWidget = React.memo((props) => {
    const location = useLocation();
    const navigator = useNavigate();
    const NAVIGATION_TABS = props.adminType === 3 ? RM_DASHBOARD_OPTIONS : props.adminType === 2 ? SUPPORT_DASHBOARD_OPTIONS : DASHBOARD_OPTIONS;
    const SIDEBAR_TOGGLE = useSelector(state => state.Reducer.toggle);

    const [tooltipPosition, setTooltipPosition] = useState(null);
    const [entered, setEntered] = useState(false);

    const [iconName, setIconName] = useState("");


    useEffect(() => {
        function _handleScroll() {
            setTooltipPosition(null);
            setEntered(false)
            setIconName("");
        };
        let scroll_parent = document.getElementById('menu-scroll-container');
        scroll_parent.addEventListener('scroll', _handleScroll);

        return () => {
            scroll_parent.removeEventListener('scroll', _handleScroll);
        }
    }, []);

    useEffect(() => {
        const handleNavigation = () => {
            // This function will be called on navigation changes
            const modal_element = document.querySelector('.modal-backdrop');
            if (modal_element) {
                document.body.removeAttribute("class");
                document.body.removeAttribute("style");
                modal_element.remove()
            }
        };

        // Add event listener for the 'popstate' event
        window.addEventListener('popstate', handleNavigation);

        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener('popstate', handleNavigation);
        };

    }, []);


    useEffect(() => {
        // Update browser zoom level
        function windowSize() {
            setTooltipPosition(null);
            setEntered(false);

        }
        windowSize();
        window.addEventListener("resize", windowSize);
        return () => {
            window.removeEventListener("resize", windowSize);
        };
    }, []);

    useEffect(() => {
        const handleNavigation = () => {
            // This function will be called on navigation changes
            const modal_element = document.querySelector('.modal-backdrop');
            if (modal_element) {
                modal_element.remove();
                document.body.removeAttribute('style');
                document.body.removeAttribute("class");
            }
        };


        // Add event listener for the 'popstate' event
        window.addEventListener('popstate', handleNavigation);

        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener('popstate', handleNavigation);
        };

    }, []);

    // redirection
    function _handleNavigation(link, event, name, page) {
        if (page !== link) {
            navigator(link);
        }
        if (SIDEBAR_TOGGLE === false) {

            const boundingRect = event.target.getBoundingClientRect();
            // Delay showing the tooltip by 50 milliseconds
            tooltipTimeout = setTimeout(() => {
                setTooltipPosition(boundingRect);
                setEntered(true)
                setIconName(name)
            }, 100);

        }
    };

    function _navigateHome() {
        navigator('/');
    }

    let tooltipTimeout;

    function _handleMouseEnter(event, name) {
        if (SIDEBAR_TOGGLE === false) {
            if (entered === false) {
                const boundingRect = event.target.getBoundingClientRect();
                // Delay showing the tooltip by 50 milliseconds
                tooltipTimeout = setTimeout(() => {
                    setTooltipPosition(boundingRect);
                    setEntered(true)
                    setIconName(name)
                }, 100);
            }
        }
    };


    function _handleMouseLeave() {
        setEntered(false);
        setTooltipPosition(null);
        setIconName("");
        clearTimeout(tooltipTimeout)
    }

    const NavigationItems = () => {
        // Matching the detail page
        const route_nodes = location.pathname.split("/");

        // if array length more than 2 means detail page taking the 1st node from url 
        const index_page = (route_nodes.length >= 2) ? `/${route_nodes[1]}` : location.pathname;

        return (
            NAVIGATION_TABS.map((item, key) => {
                return (
                    <div key={key}

                        className={`position-relative padding-12px-tb px-3  border-radius-16px  
                        ${index_page === item.link ? `${style.e_active_navigation_items} e-bg-rebecca-purple` : `${style.e_navigation_items} cursor-pointer`}
                        ${SIDEBAR_TOGGLE === true && "w-100 d-flex align-items-center gap-8px"}`}
                        onClick={(event) => _handleNavigation(item.link, event, item.name, index_page)}
                        onMouseEnter={(event) => _handleMouseEnter(event, item.name)}
                        onMouseLeave={_handleMouseLeave}>

                        <Icon draggable={false}
                            icon={item.image_inactive}
                            size={18}
                            alt={item.name}
                            className={style.e_active_icon} />
                        {
                            SIDEBAR_TOGGLE === true ?
                                <span className={`e-font-16 e-line-height-20 e-montserrat-medium ${index_page === item.link ? "color-off-white" : "color-charleston-green"}`}>{item.name}</span>
                                :
                                null
                        }
                    </div>
                );
            })
        )
    }

    return (
        <div className={`position-fixed top-0 h-100 bg-white outline-none z-index-9 ${SIDEBAR_TOGGLE === true ? "w-268px" : "w-92px"}`} >
            <div className="padding-32px-tb  text-center">
                <img draggable={false}
                    src={require("../../Assets/Images/LeftNavigation/logo.png")}
                    alt="MINTIT"
                    width={92}
                    height={57}
                    className={SIDEBAR_TOGGLE === true ? "cursor-pointer w-92px" : "cursor-pointer w-80 h-auto object-fit-contain px-2"}
                    onClick={_navigateHome} />
            </div>

            <div className={` d-flex flex-column gap-8px ${style.e_menu_item_wrapper}
            ${SIDEBAR_TOGGLE === true ? "align-items-start padding-22px-left pe-3" : "align-items-center"}`} id="menu-scroll-container" >
                {/* navigation items */}
                <NavigationItems />
            </div>
            {
                tooltipPosition !== null &&
                <div>
                    <span className="position-absolute e-montserrat-medium e-font-14 e-line-height-18 left-88px e-bg-rebecca-purple p-2 border-radius-8px color-white w-max-content"
                        style={{ top: tooltipPosition.top + 6, }} >{iconName}</span>
                    <img src={require("../../Assets/Images/LeftNavigation/right.png")} alt=""
                        style={{ top: tooltipPosition.top + 16, }}
                        className="position-absolute left-82px w-6px h-12px" />
                </div>

            }
        </div>
    )
})

export default memo(LeftNavigationWidget);