/*
 *   File : profile.js
 *   Author : https://evoqins.com
 *   Description : Profile summary
 *   Version : 1.0.0
*/

import { Fragment } from "react"

const Profile = (props) => {

    const ProfileInfo = ({ title, description }) => {
        return (
            <div className="col-lg-4 col-sm-6 col-12">
                <h6 className="e-font-14 e-line-height-18 e-montserrat-semi-bold color-gray mb-2">
                    {title}
                </h6>
                <p className="e-font-14 e-line-height-18 e-montserrat-medium color-dark-charcoal mb-0">
                    {description === null ? "-" : description}
                </p>
            </div>
        )
    }

    return (
        <Fragment>
            <div className="bg-white border-radius-24px padding-32px-all">

                {/* ===================Personal details ================*/}
                <div className="border-bottom-point5px border-color-mint-gray pb-4">
                    <h4 className="e-font-16 e-line-height-20 e-montserrat-semi-bold color-gunmetal-gray mb-3">Profile information</h4>

                    <div className="row row-gap-24px">
                        <ProfileInfo title="Name"
                            description={props.data.profile.name} />
                        <ProfileInfo title="DOB"
                            description={props.data.profile.dob} />
                        <ProfileInfo title="PAN"
                            description={props.data.profile.pan} />
                        <div className="col-lg-4 col-sm-6 col-12">
                            <h6 className="e-font-14 e-line-height-18 e-montserrat-semi-bold color-gray mb-2">
                                Email
                            </h6>
                            <a href={`mailto:${props.data.profile.email}`} className="e-font-14 e-line-height-18 e-montserrat-medium color-dark-charcoal mb-0 text-decoration-none">
                                {props.data.profile.email === null ? "-" : props.data.profile.email}
                            </a>
                        </div>

                        <div className="col-lg-4 col-sm-6 col-12">
                            <h6 className="e-font-14 e-line-height-18 e-montserrat-semi-bold color-gray mb-2">
                                Phone no
                            </h6>
                            <a href={`tel:${props.data.profile.phone}`} className="e-font-14 e-line-height-18 e-montserrat-medium color-dark-charcoal mb-0 text-decoration-none">
                                {props.data.profile.phone === null ? "-" : props.data.profile.phone}
                            </a>
                        </div>

                        <ProfileInfo title="Marital status"
                            description={props.data.profile.marital_status} />
                        <ProfileInfo title="Gender"
                            description={props.data.profile.gender} />

                    </div>
                </div>

                {/* ===================Tax details ================*/}
                <div className="border-bottom-point5px border-color-mint-gray mt-4 pb-4">
                    <h4 className="e-font-16 e-line-height-20 e-montserrat-semi-bold color-gunmetal-gray mb-3">Nationality, tax and source</h4>

                    <div className="row row-gap-24px">
                        <ProfileInfo title="Citizenship"
                            description={props.data.nationality_and_taxes.citizenship} />
                        <ProfileInfo title="Source of income"
                            description={props.data.nationality_and_taxes.income_source} />
                        <ProfileInfo title="Annual income"
                            description={props.data.nationality_and_taxes.annual_income.toLocaleString('en-IN')} />
                        <ProfileInfo title="Occupation"
                            description={props.data.nationality_and_taxes.occupation} />
                    </div>
                </div>

                {/* ===================Communication details ================*/}
                {props.data.communication.permanent_address !== null && < div className="border-bottom-point5px border-color-mint-gray mt-4 pb-4">
                    <h4 className="e-font-16 e-line-height-20 e-montserrat-semi-bold color-gunmetal-gray mb-3">Communication details</h4>

                    <div className="row row-gap-24px">
                        {/* <ProfileInfo title="Country"
                            description={props.data.communication.country} /> */}

                        <div className="col-lg-12 col-12 ">
                            <h6 className="e-font-14 e-line-height-18 e-montserrat-semi-bold color-gray mb-2">
                                Permanent address
                            </h6>
                            <p className="e-font-14 e-line-height-18 e-montserrat-medium color-dark-charcoal mb-0">
                                {props.data.communication.permanent_address.address_line1} {props.data.communication.permanent_address.address_line2}, {props.data.communication.permanent_address.city} ,  {props.data.communication.permanent_address.state} -  {props.data.communication.permanent_address.pincode}
                            </p>
                        </div>

                        {Object.keys(props.data.communication.correspondence_address).length > 0 && <div className="col-lg-12 col-12 pt-4">
                            <h6 className="e-font-14 e-line-height-18 e-montserrat-semi-bold color-gray mb-2">
                                Permanent address
                            </h6>
                            <p className="e-font-14 e-line-height-18 e-montserrat-medium color-dark-charcoal mb-0">
                                {props.data.communication.correspondence_address.address_line1} , {props.data.communication.correspondence_address.address_line2}, {props.data.communication.correspondence_address.city},  {props.data.communication.correspondence_address.state} -  {props.data.communication.correspondence_address.pincode}
                            </p>
                        </div>}
                        {/* 
                        <div className="col-lg-4 col-sm-6 col-12 pb-4">
                            <h6 className="e-font-14 e-line-height-18 e-montserrat-semi-bold color-gray mb-2">
                                Address line2
                            </h6>
                            <p className="e-font-14 e-line-height-18 e-montserrat-medium color-dark-charcoal mb-0">
                                Basalleur
                            </p>
                        </div>

                        <ProfileInfo title="City"
                            description={props.data.communication.city} />
                        <ProfileInfo title="State"
                            description={props.data.communication.state} />
                        <ProfileInfo title="Pincode"
                            description={props.data.communication.pincode} /> */}

                    </div>
                </div>
                }

                {/* ===================Bank details ================*/}
                <div className="border-bottom-point5px border-color-mint-gray mt-4 pb-4">
                    <h4 className="e-font-16 e-line-height-20 e-montserrat-semi-bold color-gunmetal-gray mb-3">Bank details</h4>

                    <div className="row row-gap-24px">
                        <ProfileInfo title="Account number"
                            description={props.data.bank.account_number} />
                        <ProfileInfo title="Account holder name"
                            description={props.data.bank.name} />

                        <ProfileInfo title="Account type"
                            description={props.data.bank.account_type} />
                        <ProfileInfo title="Bank name"
                            description={props.data.bank.branch} />
                        <ProfileInfo title="IFSC code"
                            description={props.data.bank.ifsc} />
                        {/* <ProfileInfo title="Joint account"
                            description={props.data.bank.is_joint_account} /> */}
                    </div>
                </div>

                {/* ===================Nominee details ================*/}
                <div className="mt-4 pb-4 border-bottom-point5px border-color-mint-gray">
                    <h4 className="e-font-16 e-line-height-20 e-montserrat-semi-bold color-gunmetal-gray mb-3">Nominee details</h4>
                    {
                        props.data.nominee.is_nominee_available === null ?
                            <p className="e-font-14 e-line-height-18 e-montserrat-medium color-dark-charcoal mb-0">
                                Yet to update
                            </p>
                            :
                            props.data.nominee.is_nominee_available === false ?
                                <p className="e-font-14 e-line-height-18 e-montserrat-medium color-dark-charcoal mb-0">
                                    Opted out
                                </p>
                                :
                                <div className="d-flex flex-column gap-24px">
                                    {
                                        props.data.nominee.nominee.map((item, key) =>
                                            <div className="row row-gap-24px"
                                                key={key}>
                                                <ProfileInfo title="Nominee name"
                                                    description={item.name} />
                                                <ProfileInfo title="Allocation"
                                                    description={item.allocation} />
                                                <ProfileInfo title="Relation"
                                                    description={item.relation} />
                                                <ProfileInfo title="Date of birth"
                                                    description={item.dob} />

                                            </div>
                                        )
                                    }
                                </div>

                    }
                </div>

                {/* =================== Investment details ================*/}
                <div className="mt-4 pb-4">
                    <h4 className="e-font-16 e-line-height-20 e-montserrat-semi-bold color-gunmetal-gray mb-3">Investment details</h4>

                    <div className="row row-gap-24px">
                        <ProfileInfo title="MINTIT ID"
                            description={props.data.profile.customer_id} />
                        <ProfileInfo title="FP ID"
                            description={props.data.profile.fp_id} />

                    </div>
                </div>
            </div>
        </Fragment >
    )
}

export default Profile