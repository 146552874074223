/*
 *   File : calender.js
 *   Author : https://evoqins.com
 *   Description : Calender component
 *   Integrations : react-day-picker
 *   Version : 1.0.0
*/

import React, { Fragment, useEffect, useRef, useState } from 'react';
import { DayPicker } from 'react-day-picker';

// import components
import { Icon } from '../Icon';
import { useClickOutside } from '../../Helper/helper';

// const pastMonth = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());

const CustomCalendar = React.memo((props) => {
	// state when single date has to be selected
	const [selected, setSelected] = useState(props.start === "DD-MM-YYYY" ? null : new Date(parseDate(props.start)));
	// state when date is a range
	const [range, setRange] = useState(props.start === "DD-MM-YYYY" ?
		{ from: null, to: null }
		:
		{
			from: new Date(parseDate(props.start)),
			to: new Date(parseDate(props.end))
		});
	// drop down open
	const [show, setShow] = useState(false);
	const calendarRef = useRef(null);

	useEffect(() => {
		if (props.mode === "single") {
			setSelected(props.start === "DD-MM-YYYY" ? null : new Date(parseDate(props.start)));
		}
		// eslint-disable-next-line
	}, [props.start])

	useClickOutside(calendarRef, () => {
		setShow(false);
	});

	useEffect(() => {
		if (props.mode === "single") {
			if (selected && isNaN(selected.getTime())) {
				props.selectDate("DD-MM-YYYY");
				setSelected(null)
			}
		}
		// eslint-disable-next-line
	}, [selected, props.mode]);

	useEffect(() => {
		if (props.mode !== "single") {
			if (range && range.from && isNaN(range.from.getTime())) {
				props.selectDate({
					from: "DD-MM-YYYY",
					to: "DD-MM-YYYY"
				});
				setRange({
					from: null,
					to: null
				})
			}
		}
		// eslint-disable-next-line
	}, [range, props.mode]);

	useEffect(() => {
		if (props.start === "DD-MM-YYYY" && props.mode !== "single") {
			setRange({
				from: null,
				to: null
			})
		}
		// eslint-disable-next-line
	}, [props.start, props.mode]);


	useEffect(() => {
		props.menuOpen(show);
		//eslint-disable-next-line
	}, [show])

	useEffect(() => {
		if (props.mode === 'single' && selected) {
			setShow(false);
			props.selectDate(selected); // Passing the selected single date directly
		} else if (range && range.from && range.to) {
			setShow(false);
			props.selectDate(range);
		}
		//eslint-disable-next-line
	}, [selected, range]);

	useEffect(() => {
		if (props.bodyDisable) {
			document.body.style.overflow = show ? 'hidden' : 'auto';
		}

		return () => {
			// Cleanup to ensure overflow is reset when the component unmounts
			if (props.bodyDisable) {
				document.body.style.overflow = 'auto';
			}
		};
	}, [show, props.bodyDisable]);

	function handleCalendar() {
		setShow(true);
	}

	// default date function when date is not passed
	function parseDate(dateString) {
		if (dateString === "DD-MM-YYYY" || !dateString) {
			return false // Return current date for "DD-MM-YYYY" or empty date string
		}
		const parts = dateString.split('-');
		if (parts.length !== 3) {
			return new Date(NaN); // Return invalid date if format is incorrect
		}
		return new Date(parts[2], parts[1] - 1, parts[0]);
	}

	function _clearDate(event) {
		event.stopPropagation()

		if (props.mode !== "single") {
			setRange({ from: null, to: null });
			props.selectDate({
				from: "DD-MM-YYYY",
				to: "DD-MM-YYYY"
			});
		} else {
			setSelected(null);
			props.selectDate("DD-MM-YYYY")
		}
	}

	return (
		<Fragment>
			<label className="e-montserrat-medium e-font-14 e-line-height-18 color-dark-charcoal mb-1">
				{props.label}
				{props.postfix && <span className="color-deep-carmine-pink">*</span>}
			</label>
			<div className='position-relative'>
				{
					props.mode === "single" ?

						selected === null ?
							<p className={`${props.className}  color-dark-charcoal e-font-14 e-montserrat-regular d-flex align-items-center justify-content-between e-line-height-24 bg-white border-1px border-color-pale-gray p-2 border-radius-8px cursor-pointer mb-0`}
								onClick={handleCalendar}>
								DD-MM-YYYY
								<Icon icon="calendar" size={20} />
							</p>
							:
							<p className={`${props.className} color-charleston-green e-font-16 e-montserrat-medium d-flex align-items-center justify-content-between e-line-height-24 bg-white border-1px border-color-pale-gray p-2 border-radius-8px cursor-pointer mb-0`}
								onClick={handleCalendar}>
								{props.start}
								<Icon icon="close" size={20} onClick={_clearDate} />
							</p>
						:
						range.from === null ?
							<p className={`${props.className}  color-dark-charcoal e-font-14 e-montserrat-regular d-flex align-items-center justify-content-between e-line-height-24 bg-white border-1px border-color-pale-gray p-2 border-radius-8px cursor-pointer mb-0`}
								onClick={handleCalendar}>
								DD-MM-YYYY - DD-MM-YYY
								<Icon icon="calendar" size={20} />
							</p>
							:
							<p className={`${props.className} d-flex align-items-center justify-content-between e-line-height-24 bg-white border-1px border-color-pale-gray p-2 border-radius-8px cursor-pointer color-gray e-font-14 e-montserrat-regular mb-0`}
								onClick={handleCalendar}>
								{props.start} - {props.end}
								<Icon icon="close" size={20} onClick={_clearDate} />
							</p>
				}

				{show && (
					<Fragment>
						<div ref={calendarRef} className='position-fixed z-index-99  e-calender'>
							<DayPicker
								id="test"
								captionLayout="dropdown"
								disabled={props.disabledDays}
								mode={props.mode}
								defaultMonth={selected !== null && props.mode === 'single' ? props.defaultMonth : undefined}
								// defaultMonth={pastMonth}
								selected={props.mode === 'single' ? selected : range}
								onSelect={props.mode === 'single' ? setSelected : setRange}
							/>
						</div>
						<div className='position-fixed w-100 h-100 e-bg-black e-calender-backdrop z-index-50 top-0 start-0' onClick={() => setShow(false)}></div>
					</Fragment>
				)}

				{
					props.error &&
					<p className='position-absolute color-deep-carmine-pink  e-montserrat-medium e-font-12  e-line-height-16 mb-0  margin-6px-top'>
						{props.error}
					</p>
				}
			</div>
		</Fragment>
	);
});

CustomCalendar.defaultProps = {
	menuOpen: () => { },
	start: "DD-MM-YYYY",
	end: "DD-MM-YYYY",
	bodyDisable: true
};

export default CustomCalendar;
