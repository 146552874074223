
/*
 *   File : manage-admin-block.js
 *   Author URI : https://evoqins.com
 *   Description : Modal for blocking and unblocking Admin 
 *   Integrations : null
 *   Version : v1.1
 */

// Packages
import { Fragment, useEffect, useState } from "react";

// Custom components
import { PrimaryButton, SecondaryButton } from "../Buttons";
import { Icon } from "../Icon";

// Custom styles
import style from "../../Styles/Components/modal.module.scss";

// Services
import APIService from "../../Services/api-service";
import { toast } from "react-toastify";

const ManageAdminBlockModal = (props) => {

    const [success, setSuccess] = useState(false);
    const [apiLoader, setApiLoader] = useState(false);

    useEffect(() => {
        var my_modal = document.getElementById("manage-admin-block");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
            setSuccess(false);
        });
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        var my_modal = document.getElementById("manage-admin-block");
        my_modal.addEventListener("hidden.bs.modal", () => {
            if (success === true) {
                props.confirm();
            }
        });
        //eslint-disable-next-line
    }, [success]);

    function _handleBlock() {
        setSuccess(true);
    }

    // API - block admin
    const _blockAdmin = () => {
        setApiLoader(true)
        const url = "/sub-admin/block";

        const request = {
            admin_id: props.id
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                _handleBlock();
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: 'error'
                })
            }
            setApiLoader(false);
        })
    }

    return (
        <Fragment>
            <div className={`modal fade ${style.e_modal_wrapper}`}
                id="manage-admin-block"
                tabIndex="-1"
                aria-labelledby="manage-admin-block"
                aria-hidden="true"
                data-bs-backdrop="true" >
                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                    {
                        success ?
                            <div className={`modal-content padding-32px-all ${style.e_content} `}>
                                <div className="d-flex justify-content-between align-items-center">
                                    <h5 className="e-font-16 e-line-height-24 e-montserrat-semi-bold color-dark-charcoal mb-0">
                                        User {props.status}
                                    </h5>
                                    <Icon icon="close"
                                        size={24}
                                        id="close-modal"
                                        data-bs-dismiss="modal"
                                        className='cursor-pointer'
                                        onClick={props.confirm} />
                                </div>
                                <p className="e-montserrat-medium e-font-16 e-line-height-24 color-dark-charcoal mb-0 mt-4" dangerouslySetInnerHTML={{ __html: props.subTitle }}>
                                </p>

                            </div>
                            :
                            <div className={`modal-content padding-32px-all ${style.e_content} `}>
                                <div className="d-flex justify-content-between align-items-center">
                                    <h5 className="e-font-16 e-line-height-24 e-montserrat-semi-bold color-dark-charcoal mb-0">
                                        {props.title}
                                    </h5>
                                    <Icon icon="close"
                                        size={24}
                                        id="close-modal"
                                        data-bs-dismiss="modal"
                                        className='cursor-pointer' />
                                </div>
                                <p className="e-montserrat-medium e-font-16 e-line-height-24 color-dark-charcoal mb-0 mt-4" dangerouslySetInnerHTML={{ __html: props.content }}>
                                </p>
                                <div className="d-flex justify-content-end margin-40px-top gap-8px">
                                    <SecondaryButton label="Cancel" className="padding-14px-tb padding-50px-lr"
                                        cancel="modal" />
                                    <PrimaryButton label={props.block ? "Unblock" : "Block"} className="padding-14px-tb padding-50px-lr"
                                        loader={apiLoader}
                                        disabled={apiLoader}
                                        handleClick={_blockAdmin} />
                                </div>
                            </div>
                    }

                </div>
            </div>
        </Fragment>
    )
}

export default ManageAdminBlockModal