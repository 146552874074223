/*
 *   File : detail.js
 *   Author : https://evoqins.com
 *   Description : Admin Detail
 *   Version : 1.0.0
*/


// packages
import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal } from "bootstrap";

// Custom components
import { Breadcrumb, Toggle } from "../../../Components/Others";
import { ManageAdminBlockModal } from "../../../Components/Modal";

const AdminDetail = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [adminDetail, setAdminDetail] = useState({});

    const [confirmBlock, setConfirmBlock] = useState(false);

    const [blocked, setBlocked] = useState(false);

    useEffect(() => {
        if (location.state !== null) {
            setAdminDetail(location.state.detail);
            setBlocked(location.state.detail.is_blocked)
        }
    }, [location.state]);

    useEffect(() => {
        if (confirmBlock === true) {
            const modal = new Modal(document.getElementById("manage-admin-block"));
            modal.show();
        }
    }, [confirmBlock])

    function _handleNavigate() {
        navigate(-1)
    }

    function _handleBlock(status) {
        setConfirmBlock(status)
    }
    return (
        <Fragment>
            <div className="e-bg-light-lavender pt-3 padding-52px-lr">
                <div className="padding-110px-bottom">
                    <Breadcrumb handleNavigate={_handleNavigate}
                        mainTitle="Manage admins"
                        subTitle={adminDetail.username} />
                </div>
            </div>
            <div className="e-body-wrapper">
                <div className="bg-white border-radius-32px padding-36px-tb padding-32px-lr">
                    <div className="row">
                        <div className="col-9">
                            <div className="d-flex align-items-center">
                                <img src={require("../../../Assets/Images/Icons/profile.png")}
                                    alt="profile"
                                    draggable={false}
                                    width={120}
                                    height={120}
                                    className="min-w-120px" />
                                <div className="padding-52px-left">
                                    <h6 className="e-montserrat-semi-bold e-font-20 e-line-height-14 color-dark-charcoal mb-3 word-break">
                                        {adminDetail.username}
                                    </h6>
                                    <p className="e-montserrat-regular e-font-14 e-line-height-24 color-dark-charcoal mb-3">
                                        Email: <span className="e-montserrat-semi-bold">{adminDetail.email}</span>
                                    </p>
                                    <p className="e-montserrat-regular e-font-14 e-line-height-24 color-dark-charcoal mb-3">
                                        Contact no: <span className="e-montserrat-semi-bold">{adminDetail.mobile ? adminDetail.mobile : "-"}</span>
                                    </p>
                                    <p className="e-montserrat-medium e-font-14 e-line-height-24 color-dark-charcoal mb-0">
                                        User type: <span className="e-montserrat-semi-bold">{adminDetail.admin_type_text}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="d-flex justify-content-end align-items-center">
                                <span className="e-font-16 e-line-height-20 letter-spacing-010px color-dark-charcoal pe-2 e-montserrat-semi-bold">
                                    {blocked ?
                                        "Unblock user"
                                        :
                                        "Block user"}
                                </span>
                                <Toggle
                                    check={blocked}
                                    onChange={() => {
                                        _handleBlock(true);
                                    }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                confirmBlock === true && <ManageAdminBlockModal title={blocked ? "Unblock Admin" : "Block Admin"}
                    content={`Are you sure, you want to ${blocked ? "unblock" : "block"} this user?`}
                    block={blocked}
                    id={adminDetail.admin_id}
                    confirm={() => navigate("/manage-admin")}
                    closeModal={() => _handleBlock(false)}
                    subTitle={`The user <span style="color:#662d91;font-family:Montserrat-SemiBold">${adminDetail.username}</span> has be successfully ${blocked ? "Unblocked" : "Blocked"}. They will ${blocked === false ? "no longer" : ""} be able to access platform.`} />
            }
        </Fragment>
    )
}

export default AdminDetail