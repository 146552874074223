/*
 *   File : summary.js
 *   Author : https://evoqins.com
 *   Description : Dashboard Summary Card component
 *   Integrations : js-cookie
 *   Version : 1.0.0
*/

// Packages
import { Fragment, memo } from "react";
import { Tooltip } from "react-tooltip";


const InfoCard = (props) => {
    return (
        <Fragment>
            {
                props.type === 1 ?
                    <div className={`bg-white border-radius-16px p-4 h-100 ${props.className}`}>

                        <h6 className="color-dark-charcoal e-font-14 e-line-height-24 e-montserrat-medium mb-0">
                            {props.title}
                        </h6>
                        <h5 className="mt-4 mb-0 color-dark-charcoal e-font-20 e-line-height-24 e-montserrat-semi-bold d-flex align-items-center">
                            {props.data}
                            {
                                props.count &&
                                <span data-tooltip-id={props.title}
                                    className="color-rebecca-purple e-font-14 e-line-height-24 e-montserrat-semi-bold padding-2px-left">({props.count})</span>
                            }
                            {
                                props.count &&
                                <Tooltip id={props.title}
                                    className='e-summary-hover'>
                                    <span className='color-white e-montserrat-regular e-font-12 e-line-height-18'>{(props.countText)}</span>
                                </Tooltip>
                            }
                        </h5>
                    </div>
                    :
                    <div className="e-bg-pale-lavender border-radius-16px p-3 h-100">

                        <h6 className="color-dark-gray e-font-12 e-line-height-24 e-montserrat-medium mb-0">
                            {props.title}
                        </h6>
                        <h5 className={`mt-3 mb-0 color-dark-charcoal e-font-18 e-line-height-24 e-montserrat-semi-bold ${props.color}`}>
                            {props.data}
                        </h5>
                    </div>
            }
        </Fragment>
    )
}

export default memo(InfoCard);