
/*
 *   File : delete-confirmation.js
 *   Author URI : https://evoqins.com
 *   Description : The modal component used for showing confirmation screen.
 *   Integrations : null
 *   Version : v1.1
 */

import { Fragment, useEffect } from "react";


// Custom styles
import style from "../../Styles/Components/delete-confirmation.module.scss";
import Icon from "../Icon/icon";
import { PrimaryButton, SecondaryButton } from "../Buttons";


const DeleteConfirmationModal = (props) => {

    useEffect(() => {
        var my_modal = document.getElementById("delete-modal");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
        });
        //eslint-disable-next-line
    }, [props.close]);


    const _handleDelete = async () => {
        try {
            const result = await props.delete();
            if (result === true) {
                document.getElementById("close-modal").dispatchEvent(new Event("click"));
                props.updateList()
            }
        } catch (err) {

        }
    }
    return (
        <Fragment>
            <div className={`modal fade ${style.e_modal}`}
                id="delete-modal"
                tabIndex="-1"
                aria-labelledby="delete-modal"
                aria-hidden="true"
                data-bs-backdrop="true" >
                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                    <div className={`modal-content padding-32px-all ${style.e_content} `}>
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <h5 className="e-font-16 e-line-height-24 e-montserrat-semi-bold color-dark-charcoal mb-0">
                                {props.title}
                            </h5>
                            <Icon icon="close"
                                size={18}
                                id="close-modal"
                                data-bs-dismiss="modal"
                                className='cursor-pointer' />
                        </div>
                        <p className="e-montserrat-medium e-font-16 e-line-height-24 color-dark-charcoal margin-40px-bottom">{props.description}</p>
                        <div className="d-flex gap-8px justify-content-end">
                            <SecondaryButton label="Cancel"
                                cancel="modal"
                                className="padding-14px-tb padding-50px-lr" />
                            <PrimaryButton label={props.label ? props.label : "Delete"}
                                className="padding-14px-tb padding-50px-lr"
                                handleClick={_handleDelete}
                                loader={props.loader}
                                disabled={props.loader} />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default DeleteConfirmationModal