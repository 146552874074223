/*
 *   File : list.js
 *   Author : https://evoqins.com
 *   Description : Notification list
 *   Version : 1.0.0
*/

// Packages
import { Fragment, useEffect, useState } from "react";
import { Modal } from "bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// Custom components
import { CustomTitle } from "../../../Components/Title";
import { CustomTabBar } from "../../../Components/Tab";
import { DataTableContainer } from "../../../Components/DataTable";
import { TextButton } from "../../../Components/Buttons";
import { PrimaryButton } from "../../../Components/Buttons";
import { ConfirmDeleteModal, CreateNotificationModal, NotificationDetailModal } from "../../../Components/Modal";
import { SearchInput } from "../../../Components/FormElements";
import { EmptyScreen } from "../../../Components/Others";

// Custom style
import Colors from '../../../Styles/color.module.scss';

// Helper
import { _forceNavigate } from "../../../Helper/helper";
import APIService from "../../../Services/api-service";

const NOTIFICATION_TABS = [
    {
        label: "Delivered",
        id: 1
    },
    {
        label: "Scheduled",
        id: 2
    }
]

// Table custom style
const TABLE_STYLE = {
    table: {
        style: {
            borderRadius: "0px",
        },
    },

    headRow: {
        style: {
            borderBottomColor: 'transparent',
            paddingLeft: "32px",
            color: Colors.dark_gray,
            fontFamily: "Montserrat-Bold",
            fontSize: "14px",
            lineHeight: "24px",
            paddingTop: "0px",
            paddingBottom: '0px',

        }
    },
    rows: {
        style: {
            paddingLeft: "32px",
            borderBottom: 'none !important',
            '&:nth-child(odd)': {
                backgroundColor: '#0D0D0D05',
            },
        },
    },
    cells: {
        style: {
            paddingTop: '16px',
            paddingBottom: '16px',
            color: Colors.dark_charcoal,
            fontFamily: "Montserrat-Medium",
            fontSize: "14px",
            lineHeight: "24px",
            paddingLeft: "0px",
            userSelect: "none",
        }
    },
    headCells: {
        style: {
            paddingLeft: "0px"
        }
    },
    pagination: {
        style: {
            paddingRight: "50px",
            paddingTop: "10px",
            borderTop: "none"
        }
    }
};

const NotificationListing = () => {

    const location = useLocation();
    const navigate = useNavigate();

    const [detail, setDetail] = useState(null);
    const [tabIndex, setTabIndex] = useState(NOTIFICATION_TABS[0].id);
    const [search, setSearch] = useState("");
    const [deleteNotificationId, setDeleteNotificationId] = useState(null);
    const [addNotification, setAddNotification] = useState(false);
    const [notificationDetail, setNotificationDetail] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [deliveredNotificationList, setDeliveredNotificationList] = useState({});
    const [scheduledNotificationList, setScheduledNotificationList] = useState({});
    const [tableLoader, setTableLoader] = useState(true);

    _forceNavigate(() => {
        navigate("/")
    });

    useEffect(() => {
        if (detail !== null) {
            const modal = new Modal(document.getElementById("notification-detail"));
            modal.show()
        }
    }, [detail]);

    useEffect(() => {
        if (deleteNotificationId !== null) {
            const modal = new Modal(document.getElementById("confirm-delete"));
            modal.show()
        }
    }, [deleteNotificationId]);

    useEffect(() => {
        if (addNotification === true) {
            const modal = new Modal(document.getElementById("create-notification"));
            modal.show()
        }
    }, [addNotification]);

    useEffect(() => {
        if (location.state !== null) {
            setTabIndex(location.state.tab_index);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        _listNotification();
        // eslint-disable-next-line
    }, [tabIndex, pageNumber]);

    function _handleAddNotification(status, detail) {
        setAddNotification(status);
        setNotificationDetail(detail);
    }

    function _handleTabIndex(tab_index) {
        setTabIndex(tab_index);
        setPageNumber(1);
        setTableLoader(true);
        navigate('/push-notification', { state: { tab_index: tab_index } }, { replace: true })
    }

    function _handleShowDetail(row) {
        setDetail(row);
    }

    function _handleSearch(search_value) {
        setSearch(search_value);
        if (search_value === "") {
            _listNotification({ clearSearch: "" })
        }
    }

    function _handleCancelNotification(id) {
        setDeleteNotificationId(id);
    }

    const NOTIFICATION_COLUMNS = [
        {
            name: "Notification title",
            width: "200px",
            cell: row => row.title
        },
        {
            name: "Target users",
            width: "160px",
            cell: row => row.target_user_text.length === 0 ? "-" : row.target_user_text
        },
        {
            name: "Type",
            width: "220px",
            cell: row => row.notification_type_text.length === 0 ? "-" : row.notification_type_text
        },
        {
            name: "Date & time",
            width: "180px",
            cell: row => row.date_time.length === 0 ? "-" : row.date_time
        },

        {
            name: "",
            width: "70px",
            cell: row => <TextButton
                className="e-font-14 e-line-height-24"
                name="View" hide_arrow={true}
                onPress={() => _handleShowDetail(row)}
            />
        }
    ]

    const SCHEDULED_COLUMNS = [
        {
            name: "Notification title",
            width: "300px",
            cell: row => row.title
        },
        {
            name: "Target users",
            width: "160px",
            cell: row => row.target_user_text.length === 0 ? "-" : row.target_user_text
        },
        {
            name: "Type",
            width: "220px",
            cell: row => row.notification_type_text.length === 0 ? "-" : row.notification_type_text
        },
        {
            name: "Date & time",
            width: "180px",
            cell: row => row.date_time.length === 0 ? "-" : row.date_time
        },

        {
            name: "",
            width: "70px",
            cell: row => <TextButton
                className="e-font-14 e-line-height-24"
                name="View" hide_arrow={true}
                onPress={() => _handleShowDetail(row)}
            />
        },
        {
            name: "",
            width: "70px",
            cell: row => <TextButton
                className="e-font-14 e-line-height-24"
                name="Edit" hide_arrow={true}
                onPress={() => _handleAddNotification(true, row)} />
        },
        {
            name: "",
            width: "70px",
            cell: row => <TextButton
                className="e-font-14 e-line-height-24"
                name="Cancel" hide_arrow={true}
                onPress={() => _handleCancelNotification(row.notification_id)} />
        }
    ]

    const _handlePageChange = (page) => {
        setPageNumber(page);
        setTableLoader(true);
    };

    // API - List notifications 
    const _listNotification = async ({ clearSearch = null } = {}) => {
        _handleCancelNotification(null);
        const url = "/notification/list";
        const request = {
            filter_type: tabIndex,
            query: clearSearch !== null ? clearSearch : search,
            page_num: pageNumber,
            page_size: 12
        };

        APIService(true, url, request).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                if (clearSearch !== null) {
                    setSearch(clearSearch);
                }
                if (tabIndex === 1) {
                    setDeliveredNotificationList(response.data);
                } else {
                    setScheduledNotificationList(response.data);
                }
            } else {
                setDeliveredNotificationList({});
                setScheduledNotificationList({});
                toast.error(response.message, {
                    type: 'error'
                });
            }
            setTableLoader(false);
        })
    };


    // API - Delete notifications 
    const _deleteNotification = async () => {
        let status = false;
        const url = "/notification/delete";
        const request = {
            notification_id: deleteNotificationId,
        };

        try {
            const response = await APIService(true, url, request);
            toast.dismiss();
            if (response.status_code === 200) {
                toast.success("Notification deleted", {
                    type: 'success'
                });
                status = true;
            } else {
                toast.error(response.message, {
                    type: 'error'
                });
                status = false;
            }
        } catch (error) {
            toast.error("An error occurred", {
                type: 'error'
            });
            status = false;
        }

        return status;
    };


    return (
        <Fragment>
            <div className="e-bg-light-lavender pt-3 padding-52px-lr">
                <CustomTitle title="Manage Push Notifications" type={1} />
            </div>
            <div className="e-body-wrapper">
                <div className="row">
                    <div className="col-12">
                        <div className="bg-white border-radius-32px padding-40px-tb ">
                            <div className="d-flex justify-content-between align-items-center padding-32px-lr">
                                <CustomTabBar data={NOTIFICATION_TABS}
                                    selectedTab={tabIndex}
                                    onSelectTab={_handleTabIndex} />
                                <PrimaryButton label="Send Push Notification"
                                    className="padding-12px-lr padding-10px-tb"
                                    handleClick={() => _handleAddNotification(true, null)} />
                            </div>
                            <div className="padding-32px-lr margin-32px-top">
                                <div className="row d-flex align-items-center ">
                                    <div className="col-xxl-9 col-xl-8 col-sm-6">
                                        <h4 className="mb-0 e-font-16 e-line-height-24  color-dark-charcoal e-montserrat-semi-bold">
                                            {tabIndex === 1 ? "Delivered notifications" : "Scheduled notifications"}
                                        </h4>
                                    </div>
                                    <div className="col-xxl-3 col-xl-4 col-sm-6">
                                        <SearchInput placeholder="Search with title"
                                            value={search}
                                            clear={() => {
                                                _listNotification({ clearSearch: "" })
                                            }}
                                            handleSearch={(value) => {
                                                setTableLoader(true)
                                                _listNotification({ clearSearch: value })
                                            }}
                                            valueChange={_handleSearch} />
                                    </div>
                                </div>
                            </div>
                            {
                                tableLoader === true ?
                                    <div className="h-80vh d-flex align-items-center justify-content-center">
                                        <img src={require("../../../Assets/Images/loader/page-loader.gif")}
                                            alt={"loader"}
                                            width={60}
                                            height={60}
                                        />
                                    </div>
                                    :
                                    tabIndex === 1 ?
                                        deliveredNotificationList.data.length === 0 ?
                                            <EmptyScreen className="my-5"
                                                title="No notifiva found"
                                                image={require("../../../Assets/Images/loader/empty-funds.png")}
                                                description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" />
                                            :
                                            <div className="pt-4 position-relative">
                                                <DataTableContainer
                                                    data={deliveredNotificationList.data}
                                                    columns={NOTIFICATION_COLUMNS}
                                                    customStyles={TABLE_STYLE}
                                                    paginationComponentOptions={{ noRowsPerPage: true }}
                                                    pagination={true}
                                                    paginationServer={true}
                                                    perPage={12}
                                                    onChangePage={_handlePageChange}
                                                    paginationTotalRows={deliveredNotificationList.total_count}
                                                    paginationDefaultPage={deliveredNotificationList.current_page} />
                                                <span className="e-montserrat-regular e-font-14 e-line-height-18 padding-24px-lr position-absolute color-jett-black bottom-10px">Showing {deliveredNotificationList.record} of {deliveredNotificationList.total_count} entries</span>
                                            </div>
                                        :
                                        Object.keys(scheduledNotificationList).length === 0 ?
                                            <EmptyScreen className="my-5"
                                                title="No funds found"
                                                image={require("../../../Assets/Images/loader/empty-funds.png")}
                                                description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" />
                                            :
                                            <div className="pt-4 position-relative">
                                                <DataTableContainer
                                                    data={scheduledNotificationList.data}
                                                    columns={SCHEDULED_COLUMNS}
                                                    customStyles={TABLE_STYLE}
                                                    paginationComponentOptions={{ noRowsPerPage: true }}
                                                    pagination={true}
                                                    paginationServer={true}
                                                    perPage={12}
                                                    onChangePage={_handlePageChange}
                                                    paginationTotalRows={scheduledNotificationList.total_count}
                                                    paginationDefaultPage={scheduledNotificationList.current_page} />
                                                <span className="e-montserrat-regular e-font-14 e-line-height-18 padding-24px-lr position-absolute color-jett-black bottom-10px">Showing {scheduledNotificationList.record} of {scheduledNotificationList.total_count} entries</span>
                                            </div>
                            }


                        </div>
                    </div>
                </div>
            </div>
            {
                detail !== null && <NotificationDetailModal data={detail}
                    closeModal={() => _handleShowDetail(null)} />
            }

            {
                deleteNotificationId !== null && <ConfirmDeleteModal
                    title="Cancel push notification"
                    content="Are you sure you want to cancel this push notification? This action cannot be undone."
                    delete={_deleteNotification}
                    updateList={_listNotification}
                    closeModal={() => _handleCancelNotification(null)} />
            }

            {
                addNotification === true &&
                <CreateNotificationModal
                    notification={notificationDetail}
                    confirm={_listNotification}
                    closeModal={() => _handleAddNotification(false, null)} />
            }

        </Fragment>
    )
}
export default NotificationListing