/*
 *   File : App.js
 *   Author : https://evoqins.com
 *   Description :
 *   Integrations : js-cookie,react-toastify
 *   Version : 1.0.0
*/

import React, { Fragment, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet, useLocation, Navigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Toast } from './Components/Message';


// Custom components
import { Footer } from './Components/Footer';
import { CustomHeader, LeftNavigationWidget } from './Components/Navigation';
import { Dashboard } from './Containers/SuperAdmin/Dashboard';
import { CustomerDetail, CustomerListing } from './Containers/SuperAdmin/Customer';
import { ListResources, ManageResource, ResourceCategory } from './Containers/SuperAdmin/Resources';
import { OrderDetail, OrdersListing } from './Containers/SuperAdmin/Orders';
import { PyramidListing } from './Containers/SuperAdmin/Pyramid';
import { ChangeLog, ManageQuestion, RiskProfileList } from './Containers/SuperAdmin/RiskProfiler';
import { AllNotifications, NotificationListing } from './Containers/SuperAdmin/Notifications';
import { CreateCampaign, List } from './Containers/SuperAdmin/Campaign';
import { FundListing } from './Containers/SuperAdmin/Funds';
import { ManageNudge, Nudge } from './Containers/SuperAdmin/Nudge';
import { ManagePortfolios } from './Containers/SuperAdmin/Portfolio';
import { Settings } from './Containers/SuperAdmin/Settings';
import { AdminDetail, AdminListing } from './Containers/SuperAdmin/Admins';
import { Disclaimer, FAQ, PrivacyPolicy, Terms, UserSettings, ValuePair } from './Containers/SuperAdmin/Settings/Helpers';
import { ListTickets } from './Containers/SuperAdmin/Tickets';
import { ListSupportTickets, TicketDetails } from './Containers/Support/Tickets';

import { _getNotificationCount } from './Helper/api';
import { ManageCustomPortfolio } from './Containers/SuperAdmin/Portfolio/CustomPortfolio';
import { ManageModelPortfolio } from './Containers/SuperAdmin/Portfolio/ModelPortfolio';

import { ForgotPassword, Login } from './Containers/Auth';
import { SupportNotifications } from './Containers/Support/Notifications';
import Profile from './Containers/Profile/profile';
import { RMDashboard } from './Containers/RM/Dashboard';
import { CreateReferral } from './Containers/RM/Referral';

function ScrollToTop() {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	}, [pathname]);

	return null;
}


function App() {
	const SIDEBAR_TOGGLE = useSelector(state => state.Reducer.toggle);
	const IS_LOGGED_IN = useSelector(state => state.Reducer.IS_LOGGED_IN);
	// eslint-disable-next-line
	const [adminType, setAdminType] = useState(1);

	//eslint-disable-next-line
	const [browserZoom, setBrowserZoom] = useState(100);

	useEffect(() => {
		if (IS_LOGGED_IN === true) {
			_getNotificationCount()
		}
	}, [IS_LOGGED_IN])




	const CustomerRouter = () => {
		return (
			<Outlet />
		)
	}

	const ManageResourceRouter = () => {
		return (
			<Outlet />
		)
	}

	const OrderRouter = () => {
		return (
			<Outlet />
		)
	}

	const PyramidRouter = () => {
		return (
			<Outlet />
		)
	}

	const NotificationRouter = () => {
		return (
			<Outlet />
		)
	}

	const FundRouter = () => {
		return (
			<Outlet />
		)
	}

	const PortfolioRouter = () => {
		return (
			<Outlet />
		)
	}

	const RiskProfileRouter = () => {
		return (
			<Outlet />
		)
	}

	const EmailCampaignRouter = () => {
		return (
			<Outlet />
		)
	}

	const AdminRouter = () => {
		return (
			<Outlet />
		)
	}

	const AppNudgeRouter = () => {
		return (
			<Outlet />
		)
	}

	const SettingsRouter = () => {
		return (
			<Outlet />
		)
	}

	return (
		<Router>
			<ScrollToTop />
			{
				IS_LOGGED_IN === true ?
					<Fragment>
						<LeftNavigationWidget adminType={adminType} />
						<div className={`${browserZoom === 110 ? 'e-app-zoom-level-9' : browserZoom === 125 ? 'e-app-zoom-level-8' : browserZoom >= 150 ? 'e-app-zoom-level-7' : null}`}>
							<div className={`position-absolute d-flex flex-column h-100 
               					 ${SIDEBAR_TOGGLE === true ? "margin-268px-left" : "margin-92px-left"} transition`}
								style={{ width: SIDEBAR_TOGGLE === true ? "calc(100% - 268px)" : "calc(100% - 92px)" }}>
								<CustomHeader />
								{
									adminType === 3 ?
										<Routes>
											{/* dashboard */}
											<Route path="/" element={<RMDashboard />}></Route>
											<Route path="/reference" element={<CreateReferral />}></Route>
											<Route path="/profile" element={<Profile />} />
											<Route path="*" element={<RMDashboard />} />
										</Routes>
										:
										adminType === 2 ?
											<Routes>
												{/* dashboard */}
												<Route path="/">
													<Route index element={<Navigate to="/support" />}></Route>
													<Route path="/support" element={<ListSupportTickets />}></Route>
													<Route path="detail" element={<TicketDetails />}></Route>
												</Route>
												<Route path="/all-notifications" element={<SupportNotifications />}></Route>
												<Route path="/profile" element={<Profile />} />
												<Route path="*" element={<ListSupportTickets />} />

											</Routes>
											:
											<Routes>
												{/* dashboard */}
												<Route path="/" element={<Dashboard />}></Route>

												{/* customers */}
												<Route path="/customer" element={<CustomerRouter />}>
													<Route index element={<CustomerListing />} />
													<Route path="detail" element={<CustomerDetail />} />
												</Route>

												{/* orders */}
												<Route path="/orders" element={<OrderRouter />}>
													<Route index element={<OrdersListing />} />
													<Route path="detail" element={<OrderDetail />} />
												</Route>

												{/* pyramid */}
												<Route path="/manage-pyramid" element={<PyramidRouter />}>
													<Route index element={<PyramidListing />} />
												</Route>

												{/* risk profile  */}
												<Route path="/manage-risk" element={<RiskProfileRouter />}>
													<Route index element={<RiskProfileList />} />
													<Route path="manage-question" element={<ManageQuestion />} />
													<Route path="edit-log" element={<ChangeLog />} />
												</Route>

												{/* push notification */}
												<Route path="/push-notification" element={<NotificationRouter />}>
													<Route index element={<NotificationListing />} />
												</Route>

												{/* fund list */}
												<Route path="/manage-mf" element={<FundRouter />}>
													<Route index element={<FundListing />} />
												</Route>

												{/* Portfolio list */}
												<Route path="/manage-portfolio" element={<PortfolioRouter />}>
													<Route index element={<ManagePortfolios />} />
													<Route path="manage-model" element={<ManageModelPortfolio />} />
													<Route path="manage-custom" element={<ManageCustomPortfolio />} />
												</Route>

												{/* Portfolio list */}


												{/* Manage Resources */}
												<Route path="/manage-resource" element={<ManageResourceRouter />}>
													<Route index element={<ListResources />} />
													<Route path="resource-category" element={<ResourceCategory />} />
													<Route path="add-resource" element={<ManageResource />} />
												</Route>

												{/* Email Campaign */}
												<Route path="/email-campaign" element={<EmailCampaignRouter />}>
													<Route index element={<List />} />
													<Route path="create" element={<CreateCampaign />} />
												</Route>

												{/* App nudges */}
												<Route path="/app-nudges" element={<AppNudgeRouter />}>
													<Route index element={<Nudge />} />
													<Route path="manage-nudge" element={<ManageNudge />} />
												</Route>

												{/* Admin */}
												<Route path="/manage-admin" element={<AdminRouter />}>
													<Route index element={<AdminListing />} />
													<Route path="detail" element={<AdminDetail />} />
												</Route>

												{/* App settings */}
												<Route path="/app-settings" element={<SettingsRouter />}>
													<Route index element={<Settings />} />
													<Route path="faq" element={<FAQ />} />
													<Route path="terms-conditions" element={<Terms />} />
													<Route path="privacy-policy" element={<PrivacyPolicy />} />
													<Route path="disclaimer" element={<Disclaimer />} />
													<Route path="value-pair-setting" element={<ValuePair />} />
													<Route path="user-category-setting" element={<UserSettings />} />
												</Route>

												{/* Support */}
												<Route path="/support" element={<ListTickets />}></Route>

												<Route path="/all-notifications" element={<AllNotifications />}></Route>
												<Route path="/profile" element={<Profile />} />
												<Route path="*" element={<Dashboard />} />
											</Routes>

								}


								{/* Footer */}
								<div className='mt-auto'>
									<Footer />
								</div>
							</div>
						</div>
					</Fragment>
					:
					<Fragment>
						<Routes>
							{/* dashboard */}
							<Route path="/" element={<Login />}></Route>
							<Route path="/forgot-password" element={<ForgotPassword />}></Route>
							<Route path="*" element={<Login />} />
						</Routes>

						{/* Footer */}
						<div className='mt-auto'>
							<Footer />
						</div>
					</Fragment>
			}
			<Toast />

		</Router >
	);
}

export default App;
