/*
 *   File : listing.js
 *   Author : https://evoqins.com
 *   Description : Fund list
 *   Version : 1.0.0
*/

// Packages
import { Fragment, useEffect, useState } from "react";
import { Modal } from "bootstrap";
import { toast } from "react-toastify";


// Custom components
import { CustomTitle } from "../../../Components/Title";
import { EmptyScreen, Toggle } from "../../../Components/Others";
import { PrimaryButton, TextButton } from "../../../Components/Buttons";
import { DataTableContainer } from "../../../Components/DataTable";
import { AddFundsModal, ConfirmDeleteModal } from "../../../Components/Modal";

// Custom style
import Colors from '../../../Styles/color.module.scss';

// Services
import APIService from "../../../Services/api-service";

// Table custom style
const TABLE_STYLE = {
    table: {
        style: {
            borderRadius: "0px",
        },
    },

    headRow: {
        style: {
            borderBottomColor: 'transparent',
            paddingLeft: "32px",
            color: Colors.dark_gray,
            fontFamily: "Montserrat-Bold",
            fontSize: "14px",
            lineHeight: "24px",
            paddingTop: "0px",
            paddingBottom: '0px',

        }
    },
    rows: {
        style: {
            paddingLeft: "32px",
            borderBottom: 'none !important',
            '&:nth-child(odd)': {
                backgroundColor: '#0D0D0D05',
            },
        },
    },
    cells: {
        style: {
            paddingTop: '16px',
            paddingBottom: '16px',
            color: Colors.dark_charcoal,
            fontFamily: "Montserrat-Medium",
            fontSize: "14px",
            lineHeight: "24px",
            paddingLeft: "0px",
            userSelect: "none",
        }
    },
    headCells: {
        style: {
            paddingLeft: "0px"
        }
    },
    pagination: {
        style: {
            paddingRight: "50px",
            paddingTop: "10px",
            borderTop: "none"
        }
    }
};

const FundListing = () => {

    const [fundId, setFundId] = useState(null);
    const [addFund, setAddFund] = useState(false);
    const [enabled, setEnabled] = useState(true);
    const [pageLoader, setPageLoader] = useState(true);
    const [apiLoader, setApiLoader] = useState(false);
    const [fundList, setFundList] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [fundData, setFundData] = useState({});

    useEffect(() => {
        _getRecommendedList();
        //eslint-disable-next-line
    }, [pageNum]);

    useEffect(() => {
        if (fundId !== null) {
            const modal = new Modal(document.getElementById("confirm-delete"));
            modal.show()
        }
    }, [fundId]);

    useEffect(() => {
        if (addFund === true) {
            const modal = new Modal(document.getElementById("add-funds"));
            modal.show()
        }
    }, [addFund]);

    function _handleDeleteFund(id) {
        setFundId(id);
    }

    function _handleAddFund(status) {
        setAddFund(status);
    }

    function _handleToggle() {
        setEnabled(!enabled)
        _updateStatus();
    }

    // function _handleDelete() {
    //     const fund_list = [...fundList]
    //     const index = fund_list.findIndex(item => item.mf_id === fundId);
    //     fund_list.splice(index, 1)
    //     setFundList(fund_list);
    //     _handleDeleteFund(null);
    // }

    function _handlePageChange(page) {
        setPageNum(page);
        setPageLoader(true);
        window.scrollTo(0, 0);
    };

    const FUND_COLUMNS = [
        {
            name: "Fund name",
            cell: row => <div className="d-flex align-items-center">
                <img src={row.image}
                    alt={row.fund_name}
                    width={38}
                    height={38}
                    draggable={false}
                    className="border-radius-8px border-1px border-light-gray" />
                <span className="ps-2">{row.fund_name}</span>
            </div>,
            width: "50%"
        },
        {
            name: "",
            cell: row => <TextButton name="Delete"
                hide_arrow={true}
                className="e-font-14 e-line-height-24"
                onPress={() => _handleDeleteFund(row.mf_id)} />
        },

    ]

    // API - get fund list
    const _getRecommendedList = () => {
        const url = "/recomended-funds/list";

        const request = {
            page_num: pageNum,
            page_size: 10
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setFundList(response.data.data);
                setFundData(response.data);
                setEnabled(response.data.status);
            } else {
                setFundList([]);
            }
            setPageLoader(false);
        });
    }

    //API- delete fund
    const _deleteFund = async () => {
        let status = false;
        setApiLoader(true);
        const url = "/recomended-funds/remove";
        const request = {
            mf_id: fundId
        }
        await APIService(true, url, request).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                status = true;
                _getRecommendedList();
                toast.success("Fund deleted successfully", {
                    type: 'success'
                });
            } else {
                toast.error(response.message, {
                    type: 'error'
                });
                status = false;
            }
            setApiLoader(false)
        });
        return status
    }

    // API - update status 
    const _updateStatus = () => {
        const url = "/recomended-funds/is-recomeded-fund";
        APIService(true, url).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                toast.success(enabled ? "Selected funds removed from top mutual funds" : "Selected funds added as top mutual funds", {
                    type: 'success'
                });
            } else {
                toast.error(response.message, {
                    type: 'error'
                });
            }
        })
    }
    return (
        <Fragment>
            <div className="e-bg-light-lavender pt-3 padding-52px-lr">
                <CustomTitle title="Manage Custom MF Recommendation" type={1} />
            </div>
            <div className="e-body-wrapper">
                <div className="row">
                    <div className="col-12">
                        <div className="bg-white border-radius-32px padding-40px-tb ">
                            <div className="e-box-shadow-white padding-32px-lr py-4 margin-32px-lr border-radius-16px">
                                <h4 className="mb-3 e-montserrat-semi-bold e-font-16 e-line-height-20 color-dark-charcoal">Explore fund page setting</h4>

                                <div className="d-flex align-items-center justify-content-start">
                                    <span className="e-montserrat-medium e-font-16 e-line-height-20 color-dark-charcoal letter-spacing-010px me-2">Show selected fund as top mutual funds in the list</span>
                                    <Toggle check={enabled}
                                        onChange={_handleToggle} />
                                </div>

                            </div>
                            <div className="margin-40px-top">
                                <div className="padding-32px-lr d-flex justify-content-between">
                                    <h4 className="mb-0 e-font-16 e-line-height-20 color-dark-charcoal e-montserrat-semi-bold">Mutual fund list</h4>
                                    <PrimaryButton label="Add MF"
                                        className="padding-60px-lr padding-10px-tb"
                                        handleClick={() => _handleAddFund(true)} />
                                </div>
                                {
                                    pageLoader === true ?
                                        <div className="h-80vh d-flex align-items-center justify-content-center">
                                            <img src={require("../../../Assets/Images/loader/page-loader.gif")}
                                                alt={"loader"}
                                                width={60}
                                                height={60}
                                            />
                                        </div>
                                        :
                                        fundList.length === 0 ?
                                            <EmptyScreen className="my-5"
                                                title="No Funds found"
                                                image={require("../../../Assets/Images/loader/empty-funds.png")}
                                                description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" />
                                            :
                                            <div className="position-relative pt-4">
                                                <DataTableContainer data={fundList}
                                                    columns={FUND_COLUMNS}
                                                    perPage={10}
                                                    pagination={true}
                                                    paginationServer={true}
                                                    paginationTotalRows={fundData.total_count}
                                                    paginationDefaultPage={pageNum}
                                                    paginationComponentOptions={{ noRowsPerPage: true }}
                                                    customStyles={TABLE_STYLE}
                                                    onChangePage={_handlePageChange} />
                                                <span className="e-montserrat-regular e-font-14 e-line-height-18 padding-32px-lr position-absolute color-jett-black bottom-10px">Showing {fundData.record} of {fundData.total_count} entries</span>
                                            </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                fundId !== null &&
                <ConfirmDeleteModal title="Delete MF"
                    content="Are you sure you want to delete this mutual fund? This action cannot be undone."
                    delete={_deleteFund}
                    loader={apiLoader}
                    closeModal={() => _handleDeleteFund(null)} />
            }

            {
                addFund === true && <AddFundsModal
                    closeModal={() => _handleAddFund(false)}
                    updateList={_getRecommendedList} />
            }
        </Fragment>
    )
}

export default FundListing;