/*
 *   File : bar.js
 *   Author : https://evoqins.com
 *   Description : Normal bar component
 *   Integrations : react-chartjs-2,chart.js
 *   Version : 1.0.0
*/

import React, { memo } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const CustomBarChart = (props) => {
    const OPTIONS = {
        indexAxis: 'x',
        responsive: true,
        plugins: {
            title: {
                display: false,
            },
            legend: {
                display: false
            }
        },

        scales: {
            // yAxes: [{
            //     stacked: true,
            //     categoryPercentage: 0.4
            // }],
            x: {
                grid: {
                    display: false,
                },
                border: {
                    display: false
                }
            },
            y: {
                grid: {
                    display: true,
                },
                border: {
                    display: false
                },
                ticks: {
                    display: true,
                },

            },
        },
    };

    const labels = props.labels;

    const _getGradient = (ctx) => {
        const gradient = ctx.createLinearGradient(0, 0, 0, 300);
        gradient.addColorStop(0, '#A64BEB');
        gradient.addColorStop(1, '#5E2A85');
        return gradient;
    };

    const DATA = {
        labels,
        datasets: [
            {
                // categoryPercentage: 0.8,
                label: 'Registerations',
                data: props.data,
                backgroundColor: function (context) {
                    const chart = context.chart;
                    const { ctx, chartArea } = chart;

                    if (!chartArea) {
                        // This case happens on initial chart load
                        return;
                    }
                    return _getGradient(ctx, chartArea);
                },
                barThickness: 35,
            },
        ],
    };

    return (
        <div className='h-100 w-100'>
            <Bar options={OPTIONS} data={DATA} />
        </div>
    )
}

export default memo(CustomBarChart);