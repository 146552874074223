/*
 *   File : list.js
 *   Author : https://evoqins.com
 *   Description : Admin list
 *   Version : 1.0.0
*/

// Packages
import { Fragment, useEffect, useState } from "react";
import { Modal } from "bootstrap";
import { useNavigate } from "react-router-dom";

// Custom components
import { CustomTitle } from "../../../Components/Title";
import { DataTableContainer } from "../../../Components/DataTable";
import { PrimaryButton } from "../../../Components/Buttons";
import { ManageAdminModal } from "../../../Components/Modal";
import { Badge } from "../../../Components/Badge";
import { CustomSelect, SearchInput } from "../../../Components/FormElements";
import { EmptyScreen } from "../../../Components/Others";

// Custom style
import Colors from '../../../Styles/color.module.scss';

// Services
import APIService from "../../../Services/api-service";
import Config from "../../../Helper/config";

// Table custom style
const TABLE_STYLE = {
    table: {
        style: {
            borderRadius: "0px",
        },
    },

    headRow: {
        style: {
            borderBottomColor: 'transparent',
            paddingLeft: "32px",
            color: Colors.dark_gray,
            fontFamily: "Montserrat-Bold",
            fontSize: "14px",
            lineHeight: "24px",
            paddingTop: "0px",
            paddingBottom: '0px',

        }
    },
    rows: {
        style: {
            paddingLeft: "32px",
            borderBottom: 'none !important',
            '&:nth-child(odd)': {
                backgroundColor: '#0D0D0D05',
            },
        },
    },
    cells: {
        style: {
            paddingTop: '16px',
            paddingBottom: '16px',
            color: Colors.dark_charcoal,
            fontFamily: "Montserrat-Medium",
            fontSize: "14px",
            lineHeight: "24px",
            paddingLeft: "0px",
            cursor: "pointer",
            userSelect: "none",
        }
    },
    headCells: {
        style: {
            paddingLeft: "0px"
        }
    },
    pagination: {
        style: {
            paddingRight: "50px",
            paddingTop: "10px",
            borderTop: "none"
        }
    }
};

const AdminListing = () => {
    const navigate = useNavigate();
    const [showAdminModal, setShowAdminModal] = useState(false);
    const [adminTypes, setAdminTypes] = useState([]);
    const [filterAdminTypes, setFilterAdminTypes] = useState([]);

    const [pageLoader, setPageLoader] = useState(true);
    const [adminList, setAdminList] = useState([]);
    const [adminData, setAdminData] = useState({});

    const [currentPage, setCurrentPage] = useState(1);

    const [search, setSearch] = useState("");
    const [adminType, setAdminType] = useState(null);

    useEffect(() => {
        _getFilterData();
    }, []);

    useEffect(() => {
        if (adminType !== null) {
            _listAdmins(search);
        }
        //eslint-disable-next-line
    }, [currentPage, adminType])

    useEffect(() => {
        if (showAdminModal === true) {
            const modal = new Modal(document.getElementById("manage-admin"));
            modal.show()
        }
    }, [showAdminModal]);

    function _handleAdminModal(status, detail) {
        setShowAdminModal(status);
    }

    function _handleNavigate(row) {
        navigate("detail", {
            state: {
                detail: row
            }
        })
    }

    function _handleAdminType(select_value) {
        setAdminType(select_value);
    }

    function _handleSearch(search_value) {
        setSearch(search_value);
        if (search_value === "") {
            setPageLoader(true)
            _listAdmins("");
        }
    }

    function _handlePageChange(page) {
        setCurrentPage(page);
        setPageLoader(true);
        window.scrollTo(0, 0);
    };

    const ADMIN_COLUMNS = [
        {
            name: "Name",
            width: "160px",
            cell: row => row.username
        },
        {
            name: "User type",
            width: "200px",
            cell: row => `${row.admin_type_text}`
        },
        {
            name: "Created date",
            width: "130px",
            cell: row => `${row.created}`
        },
        {
            name: "Status",
            width: "160px",
            cell: row => <Badge status={row.block_status}
                color={row.is_blocked === false ? Config.BADGE_COLORS.green : Config.BADGE_COLORS.red} />
        },

    ]

    // API- admin filter
    const _getFilterData = () => {
        const url = "/sub-admin/filter-data";

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setAdminTypes(response.data);
                const admin_types = []
                admin_types.push({
                    label: "All",
                    value: null
                })
                response.data.map((item) => {
                    return (
                        admin_types.push(item)
                    )
                })
                setFilterAdminTypes(admin_types);
                setAdminType(admin_types[0]);
            }
        })
    }

    // API -list admins
    const _listAdmins = (query = "") => {
        const url = "/sub-admin/list";

        const request = {
            "page_num": 1,
            "page_size": 12,
            "filter_type": adminType.value,
            "query": query
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setAdminList(response.data.data);
                setAdminData(response.data);
            } else {
                setAdminList([]);
            }

            setPageLoader(false);
        })
    }
    return (
        <Fragment>
            <div className="e-bg-light-lavender pt-3 padding-52px-lr">
                <CustomTitle title="Manage Admins" type={1} />
            </div>
            <div className="e-body-wrapper">
                <div className="row">
                    <div className="col-12">
                        <div className="bg-white border-radius-32px padding-40px-tb ">
                            <div className="d-flex justify-content-between align-items-center padding-32px-lr">
                                <h4 className="e-font-16 e-line-height-20 e-montserrat-semi-bold color-dark-charcoal">
                                    Admin list
                                </h4>
                                <PrimaryButton label="Add new admin"
                                    className="padding-38px-lr padding-10px-tb"
                                    handleClick={() => _handleAdminModal(true, null)} />
                            </div>
                            <div className="row  padding-32px-lr justify-content-between">

                                <div className="col-lg-3">
                                    <label className="e-montserrat-medium e-font-14 e-line-height-18 color-dark-charcoal mb-1">Admin type</label>

                                    <CustomSelect label="Type"
                                        type={1}
                                        options={filterAdminTypes}
                                        value={adminType}
                                        placeHolder="Admin type"
                                        selectChange={_handleAdminType} />
                                </div>
                                <div className="col-lg-4 pt-4">
                                    <SearchInput value={search}
                                        placeholder="Search with username"
                                        clear={() => {
                                            _listAdmins("");
                                            setSearch("");
                                        }}
                                        valueChange={_handleSearch}
                                        handleSearch={(value) => {
                                            setPageLoader(true);
                                            setCurrentPage(1);
                                            _listAdmins(value);
                                        }} />
                                </div>
                            </div>
                            {
                                pageLoader === true ?
                                    <div className="h-80vh d-flex align-items-center justify-content-center">
                                        <img src={require("../../../Assets/Images/loader/page-loader.gif")}
                                            alt={"loader"}
                                            width={60}
                                            height={60}
                                        />
                                    </div>
                                    :
                                    adminList.length === 0 ?
                                        <EmptyScreen className="my-5"
                                            title="No admin found"
                                            image={require("../../../Assets/Images/loader/empty-funds.png")}
                                            description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" />
                                        :
                                        <div className="pt-4 position-relative">
                                            <DataTableContainer
                                                data={adminList}
                                                columns={ADMIN_COLUMNS}
                                                customStyles={TABLE_STYLE}
                                                pagination={true}
                                                paginationServer={true}
                                                perPage={12}
                                                defaultSortAsc={false}
                                                onChangePage={_handlePageChange}
                                                paginationTotalRows={adminData.total_count}
                                                paginationDefaultPage={adminData.current_page}
                                                paginationComponentOptions={{ noRowsPerPage: true }}
                                                rowClick={_handleNavigate} />
                                            <span className="e-montserrat-regular e-font-14 e-line-height-18 padding-32px-lr position-absolute color-jett-black bottom-10px">Showing {adminData.record} of {adminData.total_count} entries</span>
                                        </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            {/* add or update pyramid entry */}
            {
                showAdminModal &&
                <ManageAdminModal admin_types={adminTypes}
                    closeModal={() => _handleAdminModal(false, null)}
                    updateList={_listAdmins} />
            }

        </Fragment>
    )
}
export default AdminListing