/*
 *   File : list-tickets.js
 *   Author URI : www.evoqins.com
 *   Description : Container screen to list all pending and processed tickets created through user  application.
 *   Integrations : null
 *   Version : v1.0
 *   Created : 
 */

// Packages
import { Fragment, useEffect, useState } from "react";
import { Modal } from "bootstrap";
import { toast } from "react-toastify";

// Components
import { CustomTitle } from "../../../Components/Title";
import { DataTableContainer } from "../../../Components/DataTable";
import { PrimaryButton, SecondaryButton, TextButton } from "../../../Components/Buttons";
import { AssignTicket, ConfirmDeleteModal, ViewTicketModal } from "../../../Components/Modal";
import { Badge } from "../../../Components/Badge";
import { CustomSelect, SearchInput } from "../../../Components/FormElements";
import { EmptyScreen } from "../../../Components/Others";
import { CustomTabBar } from "../../../Components/Tab";

// Custom style
import Colors from '../../../Styles/color.module.scss';

// ../Services
import APIService from "../../../Services/api-service";
import Config from "../../../Helper/config";
import { Icon } from "../../../Components/Icon";
import { useNavigate } from "react-router-dom";

// Table custom style
const TABLE_STYLE = {
    table: {
        style: {
            borderRadius: "0px",
        },
    },

    headRow: {
        style: {
            borderBottomColor: 'transparent',
            paddingLeft: "32px",
            color: Colors.dark_gray,
            fontFamily: "Montserrat-Bold",
            fontSize: "14px",
            lineHeight: "24px",
            paddingTop: "0px",
            paddingBottom: '0px',

        }
    },
    rows: {
        style: {
            paddingLeft: "32px",
            borderBottom: 'none !important',
            '&:nth-child(odd)': {
                backgroundColor: '#0D0D0D05',
            },
        },
    },
    cells: {
        style: {
            paddingTop: '16px',
            paddingBottom: '16px',
            color: Colors.dark_charcoal,
            fontFamily: "Montserrat-Medium",
            fontSize: "14px",
            lineHeight: "24px",
            paddingLeft: "0px",
            userSelect: "none",
        }
    },
    headCells: {
        style: {
            paddingLeft: "0px"
        }
    },
    pagination: {
        style: {
            paddingRight: "50px",
            paddingTop: "50px",
            borderTop: "none"
        }
    }
};

const TAB_OPTIONS = [
    {
        label: "All tickets",
        id: 1
    },
    {
        label: "Open tickets",
        id: 2
    }
]

const PRIORITY_OPTIONS = [
    {
        label: "All",
        value: null
    },
    {
        label: "High",
        value: 1
    },
    {
        label: "Medium",
        value: 2
    },
    {
        label: "Low",
        value: 3
    }
]

const TICKET_STATUS_OPTIONS = [
    {
        label: "All",
        value: null
    },
    {
        label: "Resolved",
        value: 1
    },
    {
        label: "In-progress",
        value: 2
    },
    {
        label: "Assigned",
        value: 3
    }
]
const ListSupportTickets = () => {

    const navigate = useNavigate();
    const [tabIndex, setTabIndex] = useState(TAB_OPTIONS[0].id);
    const [assignTicket, setAssignTicket] = useState(false);
    const [closeTicket, setCloseTicket] = useState(null);
    const [ticketDetail, setTicketDetail] = useState(null);
    const [ticketId, setTicketId] = useState(null);
    const [ticketList, setTicketList] = useState([]);
    const [ticketTypes, setTicketTypes] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [ticketData, setTicketData] = useState({});
    const [search, setSearch] = useState("");
    const [pageLoader, setPageLoader] = useState(true);
    const [apiLoader, setApiLoader] = useState(false);
    const [filterEnabled, setFilterEnabled] = useState(false);
    const [priority, setPriority] = useState(PRIORITY_OPTIONS[0]);
    const [ticketStatus, setTicketStatus] = useState(TICKET_STATUS_OPTIONS[0]);

    useEffect(() => {
        if (ticketDetail !== null && assignTicket === false) {
            const modal = new Modal(document.getElementById("view-ticket"));
            modal.show()
        }
    }, [ticketDetail, assignTicket])

    useEffect(() => {
        if (assignTicket === true) {
            const modal = new Modal(document.getElementById("assign-ticket"));
            modal.show();
        }
    }, [assignTicket]);

    useEffect(() => {
        if (closeTicket !== null) {
            const modal = new Modal(document.getElementById("confirm-delete"));
            modal.show();
        }
    }, [closeTicket]);

    useEffect(() => {
        _getTicketFilter();
    }, []);

    useEffect(() => {
        _listTickets();
        //eslint-disable-next-line
    }, [currentPage, tabIndex]);

    function _handleTab(tab_index) {
        setTabIndex(tab_index);
        setCurrentPage(1);
        setPageLoader(true);
    }

    function _viewTicket(row) {
        setTicketDetail(row)
    }

    function _handleFilter() {
        setFilterEnabled(false)
    }
    function _handleClear() {
        setFilterEnabled(false);
        setPriority(PRIORITY_OPTIONS[0]);
        setTicketStatus(TICKET_STATUS_OPTIONS[0])
    }

    function _assignTicket(status, row, id) {
        setAssignTicket(status);
        setTicketDetail(row);
        setTicketId(id);
    }

    function _handlePriority(select_value) {
        setPriority(select_value);
    }

    function _handleTicketStatus(select_value) {
        setTicketStatus(select_value);
    }

    function _handleClose(id) {
        setCloseTicket(id);
    }

    function _handleToggleFilter() {
        setFilterEnabled(!filterEnabled)
    }

    function _handleSearch(search_value) {
        setSearch(search_value);
        if (search_value === "") {
            _listTickets("");
        }
    }

    function _handlePageChange(page) {
        setCurrentPage(page);
        setPageLoader(true);
        window.scrollTo(0, 0);
    };

    const TICKET_COLUMNS = [
        {
            name: "Ticket ID",
            width: "140px",
            cell: row => row.ticket_no
        },
        {
            name: "Subject",
            width: "240px",
            cell: row => `${row.subject}`
        },
        {
            name: "Created date",
            width: "160px",
            cell: row => `${row.created}`
        },
        {
            name: "Category",
            width: "140px",
            cell: row => `${row.category}`
        },
        {
            name: "Priority level",
            width: "140px",
            cell: row => `${row.priority_text}`
        },
        {
            name: "Status",
            width: "140px",
            cell: row => <Badge status={row.status}
                color={row.status === "Resolved" || row.status === "Closed" ? Config.BADGE_COLORS.green : row.status === "Assigned" ? Config.BADGE_COLORS.blue : Config.BADGE_COLORS.orange} />
        },
        {
            name: '',
            sortable: false,
            width: "150px",
            cell: row => <div className="d-flex gap-16px">

                <TextButton
                    className="e-font-14 e-line-height-24"
                    name="View" hide_arrow={true}
                    onPress={() => navigate("detail", {
                        state: {
                            id: row.ticket_id
                        }
                    })} />
                {
                    row.status !== "Resolved" && row.status !== "Closed" &&
                    <TextButton
                        className="e-font-14 e-line-height-24"
                        name="Edit" hide_arrow={true}
                        onPress={() => _assignTicket(true, row, row.ticket_id)} />
                }


            </div>
        }
    ]

    const OPEN_TICKET_COLUMNS = [
        {
            name: "Ticket ID",
            width: "140px",
            cell: row => row.ticket_no
        },
        {
            name: "Subject",
            width: "240px",
            cell: row => `${row.subject}`
        },
        {
            name: "Created date",
            width: "160px",
            cell: row => `${row.created}`
        },

        {
            name: "Status",
            width: "140px",
            cell: row => <Badge status={row.status}
                color={Config.BADGE_COLORS.red} />
        },
        {
            name: '',
            sortable: false,
            cell: row => <div className="d-flex gap-16px">

                <TextButton
                    className="e-font-14 e-line-height-24"
                    name="View" hide_arrow={true}
                    onPress={() => {
                        navigate("detail", {
                            state: {
                                id: row.ticket_id
                            }
                        })
                    }} />

                <TextButton
                    className="e-font-14 e-line-height-24"
                    name="Assign" hide_arrow={true}
                    onPress={() => _assignTicket(true, null, row.ticket_id)} />
                <TextButton
                    className="e-font-14 e-line-height-24"
                    name="Close" hide_arrow={true}
                    onPress={() => _handleClose(row.ticket_id)} />


            </div>
        }
    ]

    // API - ticket filter data
    const _getTicketFilter = () => {
        const url = "/support/filter-data";

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setTicketTypes(response.data);
            }
        })
    }

    // API - ticket list
    const _listTickets = (query) => {
        const url = "/support/list";

        const request = {
            type: tabIndex,
            page_num: currentPage,
            page_size: 12,
            query: query
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setTicketList(response.data.data);
                setTicketData(response.data);
            } else {
                setTicketList([]);
            }
            setPageLoader(false);
        })
    }

    // API - close ticket
    const _closeTicket = async () => {
        let status = false
        setApiLoader(true);
        const url = "/support/close-ticket"
        const request = {
            ticket_id: closeTicket
        }
        await APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss()
                toast.success("Ticket closed", {
                    type: 'success'
                })
                status = true;

            } else {
                status = false;
            }
            setApiLoader(false);
        })
        return status
    }

    return (
        <Fragment>
            <div className="e-bg-light-lavender pt-3 padding-52px-lr">
                <CustomTitle title="Support Tickets" type={1} />
            </div>
            <div className="e-body-wrapper">
                <div className="row">
                    <div className="col-12">
                        <div className="bg-white border-radius-32px padding-40px-bottom padding-26px-top ">
                            <div className="d-flex justify-content-between align-items-center padding-32px-lr">
                                <CustomTabBar selectedTab={tabIndex}
                                    data={TAB_OPTIONS}
                                    onSelectTab={_handleTab} />
                                <div className="col-lg-4">
                                    <SearchInput value={search}
                                        placeholder="Search with Ticket no"
                                        clear={() => {
                                            _listTickets("");
                                            setSearch("");
                                        }}
                                        valueChange={_handleSearch}
                                        handleSearch={(value) => {
                                            setPageLoader(true);
                                            setCurrentPage(1);
                                            _listTickets(value);
                                        }} />
                                </div>
                                <Icon icon={filterEnabled ? "filter-active" : "filter-inactive"}
                                    size={68}
                                    className="ms-2 cursor-pointer"
                                    onClick={_handleToggleFilter} />
                            </div>
                            {
                                filterEnabled === true &&
                                <div className="e-bg-light-gray padding-12px-tb padding-32px-lr margin-10px-bottom">
                                    <div className="row">
                                        <div className="col-2 ps-0">
                                            <label className="e-montserrat-medium e-font-14 e-line-height-18 color-dark-charcoal mb-1">Priority</label>
                                            <CustomSelect value={priority}
                                                options={PRIORITY_OPTIONS}
                                                selectChange={_handlePriority} />
                                        </div>

                                        {/* risk profile filter */}
                                        <div className="col-2">
                                            <label className="e-montserrat-medium e-font-14 e-line-height-18 color-dark-charcoal mb-1">Ticket status</label>
                                            <CustomSelect value={ticketStatus}
                                                options={TICKET_STATUS_OPTIONS}
                                                selectChange={_handleTicketStatus} />
                                        </div>
                                    </div>
                                    <div className="margin-32px-top d-flex gap-24px">
                                        <PrimaryButton label="Filter now"
                                            className="padding-14px-tb padding-38px-lr"
                                            handleClick={_handleFilter} />


                                        <SecondaryButton label="Clear"
                                            className="padding-14px-tb padding-38px-lr"
                                            handleClick={_handleClear} />

                                    </div>
                                </div>
                            }
                            {
                                pageLoader === true ?
                                    <div className="h-80vh d-flex align-items-center justify-content-center">
                                        <img src={require("../../../Assets/Images/loader/page-loader.gif")}
                                            alt={"loader"}
                                            width={60}
                                            height={60}
                                        />
                                    </div>
                                    :
                                    ticketList.length === 0 ?
                                        <EmptyScreen className="my-5"
                                            title="No tickets found"
                                            image={require("../../../Assets/Images/loader/empty-funds.png")}
                                            description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" />
                                        :
                                        <div className="pt-4 position-relative">
                                            <DataTableContainer
                                                data={ticketList}
                                                columns={tabIndex === 1 ? TICKET_COLUMNS : OPEN_TICKET_COLUMNS}
                                                customStyles={TABLE_STYLE}
                                                pagination={true}
                                                paginationServer={true}
                                                perPage={12}
                                                defaultSortAsc={false}
                                                onChangePage={_handlePageChange}
                                                paginationTotalRows={ticketData.total_count}
                                                paginationDefaultPage={ticketData.current_page}
                                                paginationComponentOptions={{ noRowsPerPage: true }} />
                                            <span className="e-montserrat-regular e-font-14 e-line-height-18 padding-32px-lr position-absolute color-jett-black bottom-10px">Showing {ticketData.record} of {ticketData.total_count} entries</span>

                                        </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            {
                ticketDetail !== null &&
                <ViewTicketModal detail={ticketDetail} status={assignTicket} closeModal={() => _viewTicket(null)} />
            }

            {
                assignTicket === true &&
                <AssignTicket detail={ticketDetail}
                    ticketFilter={ticketTypes}
                    id={ticketId}
                    closeModal={() => _assignTicket(false, null, null)}
                    updateList={_listTickets} />
            }
            {
                closeTicket !== null && <ConfirmDeleteModal title="Close ticket"
                    content="Are you sure you want to close this ticket?"
                    closeModal={() => _handleClose(null)}
                    delete={_closeTicket}
                    loader={apiLoader}
                    label="Close"
                    updateList={() => {
                        _listTickets();
                        _handleClose(null)
                    }} />
            }
        </Fragment>
    )
}

export default ListSupportTickets