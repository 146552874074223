/*
 *   File : list.js
 *   Author : https://evoqins.com
 *   Description : Risk profile list
 *   Version : 1.0.0
*/

// Packages
import { Fragment, useEffect, useState } from "react";
import { Modal } from "bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// Custom components
import { CustomTitle } from "../../../Components/Title";
import { DataTableContainer } from "../../../Components/DataTable";
import { PrimaryButton, TextButton } from "../../../Components/Buttons";
import { ConfirmDeleteQuestionModal } from "../../../Components/Modal";
import { EmptyScreen } from "../../../Components/Others";

// Custom style
import Colors from '../../../Styles/color.module.scss';
import APIService from "../../../Services/api-service";


// Table custom style
const TABLE_STYLE = {
    table: {
        style: {
            borderRadius: "0px",
        },
    },

    headRow: {
        style: {
            borderBottomColor: 'transparent',
            paddingLeft: "32px",
            color: Colors.dark_gray,
            fontFamily: "Montserrat-Bold",
            fontSize: "14px",
            lineHeight: "24px",
            paddingTop: "0px",
            paddingBottom: '0px',

        }
    },
    rows: {
        style: {
            paddingLeft: "32px",
            borderBottom: 'none !important',
            '&:nth-child(odd)': {
                backgroundColor: '#0D0D0D05',
            },
        },
    },
    cells: {
        style: {
            paddingTop: '16px',
            paddingBottom: '16px',
            color: Colors.dark_charcoal,
            fontFamily: "Montserrat-Medium",
            fontSize: "14px",
            lineHeight: "24px",
            paddingLeft: "0px",
            userSelect: "none",
        }
    },
    headCells: {
        style: {
            paddingLeft: "0px"
        }
    },
    pagination: {
        style: {
            paddingRight: "50px",
            paddingTop: "10px",
            borderTop: "none"
        }
    }
};

const RiskProfileList = () => {
    const navigate = useNavigate();

    const [deleteQuestion, setDeleteQuestion] = useState(null);
    const [questionList, setQuestionList] = useState([]);
    const [screenLoader, setScreenLoader] = useState(true);
    const [deleteLoader, setDeleteLoader] = useState(false);


    useEffect(() => {
        _getRiskQuestionaireList();
    }, []);

    useEffect(() => {
        if (deleteQuestion !== null) {
            const modal = new Modal(document.getElementById("confirm-delete-question"));
            modal.show()
        }
    }, [deleteQuestion]);

    function _handleDeleteQuestion(detail) {
        setDeleteQuestion(detail)
    }

    // function _handleEditLog() {
    //     navigate("edit-log");
    // }

    function _handleNavigate() {
        navigate("manage-question",);
    }

    function _handleEdit(question) {
        navigate("manage-question", {
            state: {
                question: question
            }
        });
    }

    const QUESTIONNAIRE_COLUMNS = [
        {
            name: "Question",
            cell: row => row.question,
            width: "45%"
        },
        {
            name: "Weightage",
            width: "150px",
            cell: row => `${row.weight_text}`
        },
        {
            name: "Type",
            width: "150px",
            cell: row => row.question_type_text
        },
        {
            name: "",
            width: "60px",
            cell: row => <TextButton
                className="e-font-14 e-line-height-24" name="Edit" hide_arrow={true}
                onPress={() => _handleEdit(row)} />
        },
        {
            name: "",
            width: "70px",
            cell: row => <TextButton
                className="e-font-14 e-line-height-24"
                name="Delete" hide_arrow={true}
                onPress={() => _handleDeleteQuestion(row)} />
        }
    ]

    // API - get risk list data
    const _getRiskQuestionaireList = () => {
        const url = "/manage_risk/list";

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setQuestionList(response.data);
            }
            else {
                setQuestionList([]);
            }
            setScreenLoader(false);
        });
    }

    // API - delete question
    const _deleteQuestion = async () => {
        setDeleteLoader(true);
        let status = false;
        const url = "/manage_risk/delete";
        const request = {
            question_id: deleteQuestion.question_id
        }
        await APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                status = true
                setDeleteQuestion(null);
                toast.dismiss();
                toast.success("Risk question deleted", {
                    type: "success",
                });
            }
            else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
                status = false
            }
            setDeleteLoader(false);
        })
        return status
    }

    return (
        <Fragment>
            <div className="e-bg-light-lavender pt-3 padding-52px-lr">
                <CustomTitle title="Manage Risk Profiling" type={1} />
            </div>
            <div className="e-body-wrapper">
                <div className="row">
                    <div className="col-12">
                        <div className="bg-white border-radius-32px padding-40px-tb ">
                            <div className=" padding-32px-lr">
                                <div className="row d-flex align-items-center">
                                    <div className="col-lg-4 ">
                                        <h4 className="e-font-16 e-line-height-20 e-montserrat-semi-bold color-dark-charcoal">
                                            Added questions
                                        </h4>
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="d-flex justify-content-end align-items-center">
                                            <div className="margin-66px-right e-montserrat-semi-bold e-font-12 e-line-height-24 color-dark-gray d-flex align-items-center">
                                                <img src={require("../../../Assets/Images/Icons/info.svg").default}
                                                    width={16}
                                                    height={16}
                                                    alt="info"
                                                />Total weight =
                                                <span className="e-montserrat-bold e-font-12 e-line-height-24 color-dark-gray">100%</span>
                                            </div>
                                            {/* <LinkButton text="View edit log" className="margin-32px-right"
                                                handleClick={_handleEditLog} /> */}
                                            <PrimaryButton label="Create new question"
                                                className="padding-10px-tb padding-12px-lr"
                                                handleClick={_handleNavigate} />
                                        </div>

                                    </div>
                                </div>


                            </div>
                            {
                                screenLoader === true ?
                                    <div className="h-80vh d-flex align-items-center justify-content-center">
                                        <img src={require("../../../Assets/Images/loader/page-loader.gif")}
                                            alt={"loader"}
                                            width={60}
                                            height={60}
                                        />
                                    </div>
                                    :
                                    questionList.length === 0 ?
                                        <EmptyScreen className="my-5"
                                            title="No questions found"
                                            image={require("../../../Assets/Images/loader/empty-funds.png")}
                                            description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" />
                                        :
                                        <div className="pt-3">
                                            <DataTableContainer
                                                data={questionList}
                                                columns={QUESTIONNAIRE_COLUMNS}
                                                customStyles={TABLE_STYLE} />
                                        </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {
                deleteQuestion !== null && <ConfirmDeleteQuestionModal detail={deleteQuestion}
                    delete={_deleteQuestion}
                    confirm={_getRiskQuestionaireList}
                    loader={deleteLoader}
                    closeModal={() => _handleDeleteQuestion(null)} />
            }
        </Fragment>
    )
}
export default RiskProfileList;