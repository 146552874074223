
/*
 *   File : tab.js
 *   Author URI : https://evoqins.com
 *   Description : Custom tab component
 *   Integrations : null
 *   Version : 1
 */

// Packages
import React, { memo, useEffect, useState } from "react";

// Styles
import style from "../../Styles/Components/tab.module.scss";

const CustomTabBar = React.memo((props) => {

    const [tabIndex, setTabIndex] = useState(props.selectedTab);

    useEffect(() => {
        if (props.selectedTab) {
            setTabIndex(props.selectedTab)
        }
    }, [props.selectedTab])

    //Onclick event for tab change
    const _handleChangeTab = (index) => {
        setTabIndex(index);
        props.onSelectTab(index);
    }

    return (
        <div className={`d-flex gap-30px w-100 overflow-x-auto ${props.wrapperClass} ${style.e_scroll}`}>
            {
                props.data.map((item, key) => {
                    return (
                        <span
                            className={`text-center e-font-14 cursor-pointer e-montserrat-semi-bold e-line-height-20 text-nowrap
                             ${(item.id) === tabIndex ?
                                    `${style.e_active_tab} color-rebecca-purple`
                                    :
                                    `${style.e_tab} color-dark-charcoal`}
                                     ${props.className}`}
                            key={key}
                            onClick={() => _handleChangeTab(item.id, item)}>
                            {item.label}
                        </span>


                    )
                })
            }

        </div>
    )
})

export default memo(CustomTabBar)

CustomTabBar.defaultProps = {
    className: '',
    selectedTab: 1,
    onSelectTab: () => { }
}  
