/*
 *   File : text-button.js
 *   Author : https://evoqins.com
 *   Description : text button component
 *   Integrations : NA
 *   Version : 1.0.0
*/
// Packages
import React, { memo } from 'react';

// Custom components
import { Icon } from '../Icon';

// Custom styles
import style from "../../Styles/Components/link.module.scss"

const TextButton = (props) => {
    return (
        <span className={`${style.e_text_button} ${props.className} color-rebecca-purple e-montserrat-semi-bold e-font-12  e-line-height-20 text-decoration-hover-underline cursor-pointer`}
            onClick={props.onPress}>
            {props.name}
            {props.hide_arrow === true ?
                null
                : <Icon icon="arrow-right"
                    size="16px"
                    className={`${style.e_arrow} ms-1`} />}
        </span>
    )
}

TextButton.defaultProps = {
    onPress: () => { }
}

export default memo(TextButton);