/*
 *   File : create-notification.js
 *   Author URI : https://evoqins.com
 *   Description : The modal component used for creating push notification.
 *   Integrations : null
 *   Version : v1.1
 */


// Packages
import { Fragment, memo, useEffect, useRef, useState } from "react";

// Custom components
import { Icon } from "../Icon";
import { CustomFileInput, CustomSelect, CustomTextArea, CustomTextInput1, CustomTimePicker } from "../FormElements";
import { CustomCalendar } from "../Others";
import { PrimaryButton, SecondaryButton } from "../Buttons";

// Custom styles
import style from "../../Styles/Components/modal.module.scss";
import { _getFilePath } from "../../Helper/api";
import { _uploadDocumentToS3 } from "../../Services/aws-service";
import { toast } from "react-toastify";
import APIService from "../../Services/api-service";
import { _formatDate, _formatTime } from "../../Helper/helper";
import { format, parse } from "date-fns";

const CreateNotificationModal = (props) => {

    const disabledDays = { before: new Date() };

    const modelRef = useRef(null);

    const titleRef = useRef(false);
    const bannerImageRef = useRef(false);
    const contentRef = useRef(false);
    const notificationTypeRef = useRef(false);
    const targetUserRef = useRef(false);
    const dateRef = useRef(false);
    const timeRef = useRef(false);

    const [notification, setNotification] = useState("");
    const [notificationError, setNotificationError] = useState("");
    const [bannerLoader, setBannerLoader] = useState(false);
    const [bannerImage, setBannerImage] = useState("");
    const [bannerImageError, setBannerImageError] = useState("");

    const [notificationContent, setNotificationContent] = useState("");
    const [notificationContentError, setNotificationContentError] = useState("");

    const [scheduledDate, setScheduledDate] = useState("DD-MM-YYYY");
    const [scheduledDateError, setScheduledDateError] = useState("");

    const [selectedTime, setSelectedTime] = useState(null);
    const [selectedTimeError, setSelectedTimeError] = useState("");

    const [notificationType, setNotificationType] = useState(null);
    const [notificationTypeError, setNotificationTypeError] = useState("");

    const [targetUser, setTargetUser] = useState(null);
    const [targetUserError, setTargetUserError] = useState("");
    const [isScheduled, setIsScheduled] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [notificationTypeOptions, setNotificationTypeOptions] = useState([]);
    const [targetUserOptions, setTargetUserOptions] = useState([]);
    const [screenLoader, setScreenLoader] = useState(true);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        var my_modal = document.getElementById("create-notification");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
            _clearFields();
        });
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        _getFilterData();
    }, []);


    useEffect(() => {
        if (props.notification !== null) {
            setNotification(props.notification.title);
            setNotificationContent(props.notification.content);
            setNotificationType({ label: props.notification.notification_type_text, value: props.notification.notification_type });
            setTargetUser({ label: props.notification.target_user_text, value: props.notification.target_user });
            setBannerImage(props.notification.image);
            setIsScheduled(props.notification.is_scheduled);
            if (props.notification.is_scheduled === true) {
                // const date = props.notification.date_time.split(",")[0]
                // const time = props.notification.date_time.split(",")[1]
                setScheduledDate(props.notification.scheduled_date);
                setSelectedTime(_formatTime(props.notification.scheduled_time))
            }
        }
    }, [props.notification]);

    useEffect(() => {
        if (isScheduled === true) {
            if (modelRef.current) {
                modelRef.current.scrollTop = modelRef.current.scrollHeight;
            }
        }
    }, [isScheduled]);

    function _handleTitle(input_value) {
        setNotification(input_value);
        setNotificationError("");
    }

    async function _handleFileUpload(file, type) {
        setBannerLoader(true);
        const request = {
            file: file.name,
            is_public: false
        }

        await _getFilePath(request).then((response) => {
            toast.dismiss();
            setBannerLoader(false);
            _uploadDocumentToS3(response.data, file).then((result) => {
                setBannerImage(response.data.view_info);
                setBannerImageError("");

            }).catch((error) => {
                toast.error(error, {
                    type: "error"
                });
            });
        });
    }

    function _handleNotificationContent(value) {
        setNotificationContent(value);
        setNotificationContentError("");
    }

    function _handleToggle(status) {
        setOpen(status)
    }


    function _handleDate(selected_date) {

        const formatted_start_date = _formatDate(selected_date);
        setScheduledDate(formatted_start_date);
        setScheduledDateError("");
    }

    function _handleTimeChange(newTime) {
        setSelectedTime(newTime);
        setSelectedTimeError("");
        // You can perform any other actions with the selected time here
    };

    function _handleNotificationType(select_value) {
        setNotificationType(select_value);
        setNotificationTypeError("");
    }

    function _handleTargetUser(select_value) {
        setTargetUser(select_value);
        setTargetUserError("");
    }

    function _validate() {
        let valid = true;

        if (isScheduled === true) {
            if (selectedTime === null) {
                timeRef.current.scrollIntoView({ block: 'start' });
                setSelectedTimeError("Time is required");
                valid = false;
            }
            if (scheduledDate === "dd/mm/yyyy") {
                dateRef.current.scrollIntoView({ block: 'start' });
                setScheduledDateError("Date is required");
                valid = false;
            }
        }
        if (targetUser === null) {
            targetUserRef.current.scrollIntoView({ block: 'start' });
            setTargetUserError("Target user is required");
            valid = false;
        }
        if (notificationType === null) {
            notificationTypeRef.current.scrollIntoView({ block: 'start' });
            setNotificationTypeError("Notification type is required");
            valid = false;
        }
        if (notificationContent === "") {
            contentRef.current.scrollIntoView({ block: 'start' });
            setNotificationContentError("Notification content is required");
            valid = false;
        }

        if (bannerImage === "" || bannerImage === null) {
            bannerImageRef.current.scrollIntoView({ block: 'start' });
            setBannerImageError("Image is required");
            valid = false;
        }
        if (notification === "") {
            titleRef.current.scrollIntoView({ block: 'start' });
            setNotificationError("Title is required");
            valid = false;
        }
        if (valid === true) {
            _sendNotification();
            setButtonLoader(true);
        }
    }


    function _clearFields() {
        setNotification("");
        setNotificationType(null);
        setNotificationContent("");
        setBannerImage("");
        setNotificationContentError("");
        setNotificationTypeError("");
        setBannerImageError("");
        setScheduledDate("dd/mm/yyyy");
        setSelectedTime(null);
        setScheduledDateError("");
        setSelectedTimeError("");
        setTargetUser(null);
        setTargetUserError("");
    }

    const _handleSizeError = () => {
        setBannerImageError("File size exceeds 2mb");
    }

    const _handleScheduled = () => {
        setIsScheduled(!isScheduled)
    }

    // API - send notification
    const _sendNotification = () => {
        let time = ""
        if (isScheduled) {
            const date = parse(selectedTime, 'hh:mm a', new Date());
            time = format(date, 'HH:mm:ss');
        }
        let url = "/notification/create";
        if (props.notification !== null) {
            url = "/notification/update"
        }
        const request = {
            notification_id: props.notification !== null ? props.notification.notification_id : null,
            title: notification,
            content: notificationContent,
            image: bannerImage,
            notification_type: notificationType.value,
            target_user: targetUser.value,
            scheduled_date: isScheduled === true ? scheduledDate : null,
            scheduled_time: isScheduled === true ? time : null
        }
        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                _clearFields();
                document.getElementById("close-modal").dispatchEvent(new Event("click"));
                props.confirm();
                toast.dismiss();
                toast.success(isScheduled === true ? "Notification scheduled" : "Notification sent", {
                    type: "success",
                });
            }
            else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
            setButtonLoader(false);
        })
    }

    // API - filter data
    const _getFilterData = () => {
        const url = "/notification/filter-data";

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setNotificationTypeOptions(response.data.notification_types);
                setTargetUserOptions(response.data.user_types);
            }
            else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
                document.getElementById("close-modal").dispatchEvent(new Event("click"));
            }
            setScreenLoader(false);

        });
    }

    return (
        <Fragment>
            <div className={`modal fade ${style.e_notification_modal}`}
                id="create-notification"
                tabIndex="-1"
                aria-labelledby="create-notification"
                aria-hidden="true"
                data-bs-backdrop="true" >
                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                    <div className={`modal-content padding-24px-lr padding-32px-tb ${style.e_content} `}>
                        <div className="d-flex justify-content-between align-items-center mb-4 px-2">
                            <h5 className="e-font-16 e-line-height-24 e-montserrat-semi-bold color-dark-charcoal mb-0">
                                {props.notification !== null ? "Update push notification" : "Send push notification"}
                            </h5>
                            <Icon icon="close"
                                size={18}
                                id="close-modal"
                                data-bs-dismiss="modal"
                                className='cursor-pointer' />
                        </div>
                        {
                            screenLoader === true ?
                                <div className="h-80vh d-flex align-items-center justify-content-center">
                                    <img src={require("../../Assets/Images/loader/page-loader.gif")}
                                        alt={"loader"}
                                        width={60}
                                        height={60}
                                    />
                                </div>
                                :
                                <div className={`${style.e_modal_fields} px-2`} ref={modelRef}>
                                    <div className="row padding-32px-top">
                                        <div className="col-12" ref={titleRef}>
                                            <CustomTextInput1 label="Notification title (max characters up to 30)"
                                                placeHolder="Notification title"
                                                postfix="*"
                                                type="text"
                                                open={open}
                                                isDisabled={open}
                                                popup={true}
                                                error={notificationError}
                                                value={notification}
                                                handleChange={_handleTitle} />
                                        </div>
                                        <div className="col-12 mt-4" ref={bannerImageRef}>
                                            <CustomFileInput shape="rectangle"
                                                label="Upload banner image"
                                                postfix={true}
                                                loader={bannerLoader}
                                                file={bannerImage}
                                                disabled={open}
                                                accept={["JPG", "PNG", "JPEG"]}
                                                error={bannerImageError}
                                                sizeError={_handleSizeError}
                                                onSelectFile={_handleFileUpload}
                                                clearFile={() => setBannerImage("")} />
                                        </div>
                                        <div className="col-12 mt-4" ref={contentRef}>
                                            <CustomTextArea label="Notification content"
                                                placeHolder="Notification content"
                                                id="Remarks"
                                                postfix="*"
                                                value={notificationContent}
                                                error={notificationContentError}
                                                disabled={open}
                                                handleChange={_handleNotificationContent} />
                                        </div>
                                    </div>

                                    <div className="row mt-4">
                                        <div className="col-6" ref={notificationTypeRef}>
                                            <CustomSelect type={3}
                                                menuPlacement={"top"}
                                                postFix={true}
                                                placeholder="Notification type"
                                                label="Notification type"
                                                error={notificationTypeError}
                                                value={notificationType}
                                                options={notificationTypeOptions}
                                                selectChange={_handleNotificationType} />
                                        </div>
                                        <div className="col-6" ref={targetUserRef}>
                                            <CustomSelect type={3}
                                                menuPlacement={"top"}
                                                postFix={true}
                                                placeholder="Target users"
                                                label="Target users"
                                                error={targetUserError}
                                                value={targetUser}
                                                options={targetUserOptions}
                                                selectChange={_handleTargetUser} />
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center gap-8px mt-4 w-max-content"
                                        onClick={_handleScheduled}>
                                        <span>
                                            <Icon icon={isScheduled ? 'checked' : 'unchecked'}
                                                size={24}
                                                className="cursor-pointer"
                                            />
                                        </span>
                                        <span className="color-black e-poppins-regular e-font-14 line-height-24px cursor-pointer">Scheduled</span>
                                    </div>
                                    {
                                        isScheduled === true &&
                                        <div className="row d-flex align-items-center mt-4">
                                            <div className="col-6" ref={dateRef}>
                                                <CustomCalendar error={scheduledDateError}
                                                    disabledDays={disabledDays}
                                                    bodyDisable={false}
                                                    label="Schedule date"
                                                    postfix={true}
                                                    start={scheduledDate}
                                                    mode="single"
                                                    className={style.e_date_input}
                                                    selectDate={_handleDate} />
                                            </div>
                                            <div className="col-6" ref={timeRef}>
                                                <CustomTimePicker label="Schedule time"
                                                    error={selectedTimeError}
                                                    postFix={true}
                                                    time={selectedTime}
                                                    popup={true}
                                                    onTimeChange={_handleTimeChange}
                                                    open={_handleToggle} />
                                            </div>
                                        </div>
                                    }
                                </div>
                        }

                        <div className="d-flex gap-8px justify-content-end padding-40px-top px-2">
                            <SecondaryButton label="Cancel"
                                cancel="modal"
                                className="padding-14px-tb padding-50px-lr" />
                            <PrimaryButton label={isScheduled === true ? "Schedule" : "Send"}
                                disabled={buttonLoader}
                                loader={buttonLoader}
                                className="padding-14px-tb padding-50px-lr"
                                handleClick={_validate} />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default memo(CreateNotificationModal);