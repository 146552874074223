/*
 *   File : breadcrumb.js
 *   Author : https://evoqins.com
 *   Description : Header component
 *   Integrations : js-cookie
 *   Version : 1.0.0
*/

import { Fragment, memo } from "react"

const BreadCrumb = (props) => {
    return (
        <Fragment>
            <div className="d-flex">
                <span className="color-dark-charcoal e-font-14 e-line-height-18 e-montserrat-medium cursor-pointer"
                    onClick={props.handleNavigate}>{props.mainTitle} / </span>
                <span className=" ps-1 color-dark-gray e-font-14 e-line-height-18 e-montserrat-medium"> {props.subTitle}</span>
                {
                    props.screenTitle &&
                    <span className=" ps-1 color-dark-gray e-font-14 e-line-height-18 e-montserrat-medium"> / {props.screenTitle}</span>
                }
            </div>
        </Fragment>
    )
}

BreadCrumb.defaultProps = {
    handleNavigate: () => { }
}

export default memo(BreadCrumb);