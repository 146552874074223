/*
 *   File : categories.js
 *   Author : https://evoqins.com
 *   Description : UI screen to list resource categories.
 *   Version : 1.0.0
*/

// Packages
import { Fragment, useEffect, useState } from "react";
import { Modal } from "bootstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

// Components
import { PrimaryButton, TextButton } from "../../../Components/Buttons";
import { DataTableContainer } from "../../../Components/DataTable";
import { DeleteConfirmationModal, ManageResourceModal } from "../../../Components/Modal";
import { Breadcrumb, EmptyScreen } from "../../../Components/Others";

// Services
import APIService from "../../../Services/api-service";
import Colors from "../../../Styles/color.module.scss";

// Table custom style
const TABLE_STYLE = {
    table: {
        style: {
            borderRadius: "0px",
        },
    },

    headRow: {
        style: {
            borderBottomColor: 'transparent',
            paddingLeft: "32px",
            color: Colors.dark_gray,
            fontFamily: "Montserrat-Bold",
            fontSize: "14px",
            lineHeight: "24px",
            paddingTop: "0px",
            paddingBottom: '0px',

        }
    },
    rows: {
        style: {
            paddingLeft: "32px",
            borderBottom: 'none !important',
            '&:nth-child(odd)': {
                backgroundColor: '#0D0D0D05',
            },

        },
    },
    cells: {
        style: {
            paddingTop: '16px',
            paddingBottom: '16px',
            color: Colors.dark_charcoal,
            fontFamily: "Montserrat-Medium",
            fontSize: "14px",
            lineHeight: "24px",
            paddingLeft: "0px",
            cursor: "pointer",
            userSelect: "none",
        }
    },
    headCells: {
        style: {
            paddingLeft: "0px"
        }
    },
    pagination: {
        style: {
            paddingRight: "50px",
            paddingTop: "10px",
            borderTop: "none"
        }
    }
};

const ResourceCategory = () => {

    // RESOURCE columns
    const RESOURCE_COLUMN = [
        {
            name: "Category",
            selector: row => row.name,
            width: "150px"
        },

        {
            name: "Created on",
            selector: row => row.created,
            width: "150px"
        },
        {
            name: '',
            sortable: false,
            width: "150px",
            cell: row => <div className="d-flex gap-16px">
                <TextButton
                    className="e-font-14 e-line-height-24" name="Edit" hide_arrow={true}
                    onPress={() => _handleOpenEditModal(row)} />
                <TextButton
                    className="e-font-14 e-line-height-24" name="Delete" hide_arrow={true}
                    onPress={() => setOpenDeleteModal(row)} />
            </div>
        }]

    const navigate = useNavigate();
    const [openCategoryModal, setOpenCategoryModal] = useState(false);
    const [editCategory, setEditCategory] = useState(null);
    const [categoryList, setCategoryList] = useState([]);
    const [openDeleteModal, setOpenDeleteModal] = useState(null);
    const [screenLoader, setScreenLoader] = useState(true);
    const [modalLoader, setModalLoader] = useState(false);

    useEffect(() => {
        _getCategoryList();
    }, []);

    useEffect(() => {
        if (openCategoryModal !== false) {
            const modal = new Modal(document.getElementById("edit-resource-modal"), {});
            modal.show();
        }
    }, [openCategoryModal]);

    useEffect(() => {
        if (openDeleteModal !== null) {
            const modal = new Modal(document.getElementById("delete-modal"), {});
            modal.show();
        }
    }, [openDeleteModal]);

    const _handleNavigate = () => {
        navigate(-1);
    }

    const _handleOpenEditModal = (row) => {
        setEditCategory(row)
        setOpenCategoryModal(true);
    }

    const _handleClose = () => {
        setEditCategory(null);
        setOpenCategoryModal(false);
        setOpenDeleteModal(null);
    }

    const _handleCreate = () => {
        setOpenCategoryModal(true);
    }

    // API - get category list
    const _getCategoryList = () => {
        const url = "/resource/list-resource-category";
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setCategoryList(response.data);
            }
            setScreenLoader(false);
        })
    }

    // API - confirm category
    const _handleConfirm = async (value) => {
        setModalLoader(true);
        let status = false;
        const url = "/resource/upsert-resource-category";
        const request = {
            category_id: editCategory !== null ? editCategory.category_id : null,
            name: value
        }

        try {
            const response = await APIService(true, url, request);
            toast.dismiss();
            if (response.status_code === 200) {
                toast.success("Successfully added", {
                    type: 'success'
                });
                status = true;
            } else {
                toast.error(response.message, {
                    type: 'error'
                });
                status = false;
            }
        } catch (error) {
            toast.error("An error occurred", {
                type: 'error'
            });
            status = false;
        }
        setModalLoader(false);

        return status;
    };

    // API - confirm delete
    const _handleDelete = async () => {
        setModalLoader(true);
        let status = false;
        const url = "/resource/delete-resource-category";
        const request = {
            category_id: openDeleteModal.category_id
        }

        try {
            const response = await APIService(true, url, request);
            toast.dismiss();
            if (response.status_code === 200) {
                toast.success("Successfully deleted", {
                    type: 'success'
                });
                status = true;
            } else {
                toast.error(response.message, {
                    type: 'error'
                });
                status = false;
            }
        } catch (error) {
            toast.error("An error occurred", {
                type: 'error'
            });
            status = false;
        }
        setModalLoader(false);

        return status;
    };

    return (
        <Fragment>
            <div className="e-bg-light-lavender pt-3 padding-52px-lr">
                <div className="padding-110px-bottom">
                    <Breadcrumb handleNavigate={_handleNavigate}
                        mainTitle="Manage resources"
                        subTitle="Resource category" />
                </div>
            </div>
            <div className="e-body-wrapper">
                <div className="row ">
                    <div className="col-12">
                        <div className="e-bg-white border-radius-32px padding-40px-tb">
                            <div className="d-flex align-items-center justify-content-between padding-32px-lr">
                                <p className="e-montserrat-semi-bold e-font-16 e-line-height-20 color-dark-charcoal mb-0">Resource category list</p>
                                <PrimaryButton label="Create category"
                                    className="padding-10px-tb padding-30px-lr"
                                    handleClick={_handleCreate} />
                            </div>
                            {
                                screenLoader === true ?
                                    <div className="h-80vh d-flex align-items-center justify-content-center">
                                        <img src={require("../../../Assets/Images/loader/page-loader.gif")}
                                            alt={"loader"}
                                            width={60}
                                            height={60}
                                        />
                                    </div>
                                    :
                                    categoryList.length === 0 ?
                                        <div className={`mt-4 `}>
                                            <EmptyScreen className="my-5"
                                                title="No category found"
                                                image={require("../../../Assets/Images/loader/empty-funds.png")}
                                                description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" />
                                        </div>
                                        :
                                        <DataTableContainer
                                            columns={RESOURCE_COLUMN}
                                            data={categoryList}
                                            selectableRows={false}
                                            customStyles={TABLE_STYLE} />
                            }


                        </div>
                    </div>
                </div>
            </div>

            {
                openCategoryModal === true &&
                <ManageResourceModal value={editCategory}
                    close={_handleClose}
                    onConfirm={_handleConfirm}
                    updateList={_getCategoryList}
                    loader={modalLoader} />
            }

            {
                openDeleteModal !== null ?
                    <DeleteConfirmationModal title="Delete resource category"
                        description={`Are you sure you want to delete the resource category ${openDeleteModal.name}`}
                        delete={_handleDelete}
                        loader={modalLoader}
                        updateList={_getCategoryList}
                        close={_handleClose} />
                    :
                    null
            }
        </Fragment>
    )
}

export default ResourceCategory