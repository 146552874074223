/*
 *   File : search-input.js
 *   Author URI : www.evoqins.com
 *   Description : Search input
 *   Integrations : null
 *   Version : v1.0
 *   Created : 
 */

/* packages */
import React, { Fragment, memo, useEffect, useState } from "react";
import { useRef } from "react";

// Custom components
import { Icon } from "../Icon";

// Custom styles 
import styles from "../../Styles/Components/search.module.scss";
import { useClickOutside } from "../../Helper/helper";

const SearchSelect = (props) => {
    const dropdownRef = useRef(false);
    const [value, setValue] = useState("");
    const [showDropdown, setShowDropdown] = useState(false);

    useClickOutside(dropdownRef, () => {
        setShowDropdown(false);
    })
    useEffect(() => {
        setValue(props.value);
    }, [props.value])

    // onchange value
    const _handleChange = (event) => {
        const input_value = event.target.value;
        // Check if acceptNumber prop is true and the input is not a number
        if (props.acceptNumber && isNaN(input_value)) {
            return; // Do nothing if not a number
        }
        setValue(input_value);
        props.valueChange(input_value);
    }

    //on press of enter
    const _handleSearch = (event) => {
        const value = event.target.value;
        setShowDropdown(true);
        if (event.keyCode === 13) {
            props.handleSearch(value);
        }
    }

    function _handleShowDropdown() {
        props.onFocus();
        setShowDropdown(true)
    }

    function _handleSelectFund(item) {
        props.selectFund(item);
        setShowDropdown(false);
    }

    return (
        <Fragment>

            <div ref={dropdownRef} className={`${styles.e_search_select}  w-100 position-relative ${props.divClass}`}>
                {
                    value.length !== 0 ?
                        <>
                            <Icon icon="close"
                                size={16}
                                className="position-absolute top-0 bottom-0 right-16px my-auto cursor-pointer"
                                onClick={() => props.valueChange("")} />
                        </>
                        :
                        null
                }

                <Icon icon="search"
                    size={16}
                    className="position-absolute top-0 bottom-0 left-16px cursor-pointer my-auto" />

                <input type="text"
                    id={props.id}
                    spellCheck="false"
                    placeholder={props.placeholder}
                    value={value}
                    autoComplete="off"
                    onFocus={() => _handleShowDropdown()}
                    className={`${props.className}  border-light-gray color-dark-charcoal e-line-height-18`}
                    onChange={_handleChange}
                    onKeyDown={(e) => _handleSearch(e)} />

                {
                    props.error &&
                    <div className='position-absolute d-flex align-items-center margin-6px-top w-max-content'>
                        <p className='color-deep-carmine-pink  e-montserrat-medium e-font-12  e-line-height-16 mb-0 '>
                            {props.error}
                        </p>
                    </div>
                }
                {
                    showDropdown &&
                    <div className="position-absolute top-52px py-2 e-box-shadow-white bg-white border-radius-8px bg-white w-100 z-index-10 h-250px overflow-y-scroll show-scrollbar" >
                        {
                            props.data.length > 0 ? props.data.map((item) => {
                                return (
                                    <p className={`${styles.e_fund} ${props.selectedFund === item.id ? "e-bg-rebecca-purple color-white" : "bg-white color-dark-charcoal"} e-montserrat-medium e-font-14 e-line-height-18 letter-spacing-007em mb-0 py-2 px-3`}
                                        onClick={() => _handleSelectFund(item)}>
                                        {item.name}
                                    </p>
                                )
                            })
                                :
                                <p className="e-montserrat-medium e-font-14 e-line-height-18 color-dark-charcoal mb-0 p-2">No funds found</p>
                        }
                    </div>
                }

            </div>

        </Fragment>
    );
};

SearchSelect.defaultProps = {
    valueChange: () => { },
    id: 1,
    value: "",
    acceptNumber: false,
    placeholder: "Search",
    className: '',
    onClick: () => { },
    onFocus: () => { },
    handleSearch: () => { }
}

export default memo(SearchSelect);