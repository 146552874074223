import { put, takeEvery } from "redux-saga/effects";


function* updateSidebarToggle(action) {
    try {
        yield put({
            type: 'TOGGLE_SIDEBAR', payload: action.payload, // Access payload from the action argument
        });
    }
    catch (error) {
        console.log(error);
    }
}

function* updateLoginStatus(action) {
    try {
        yield put({
            type: 'LOGIN_STATUS', payload: action.payload, // Access payload from the action argument
        });
    }
    catch (error) {
        console.log(error);
    }
}

function* updateNotificationCount(action) {
    try {
        yield put({
            type: 'NOTIFICATION_COUNT', payload: action.payload, // Access payload from the action argument
        });
    }
    catch (error) {
        console.log(error);
    }
}
export function* _updateLoginStatus() {
    yield takeEvery('LOGIN_STATUS', updateLoginStatus);
}

export function* _updateSidebarToggle() {
    yield takeEvery('TOGGLE_SIDEBAR', updateSidebarToggle);
}

export function* _updateNotificationCount() {
    yield takeEvery('NOTIFICATION_COUNT', updateNotificationCount);
}