/*
 *   File : list.js
 *   Author : https://evoqins.com
 *   Description : Pyramid list
 *   Version : 1.0.0
*/

// Packages
import { Fragment, useEffect, useState } from "react";
import { Modal } from "bootstrap";

// Custom components
import { CustomTitle } from "../../../Components/Title";
import { CustomTabBar } from "../../../Components/Tab";
import { DataTableContainer } from "../../../Components/DataTable";
import { TextButton } from "../../../Components/Buttons";
import { PrimaryButton } from "../../../Components/Buttons";
import { ConfirmDeleteModal, ManagePyramidModal } from "../../../Components/Modal";

// Custom style
import Colors from '../../../Styles/color.module.scss';


const PYRAMID_DATA = [
    {
        id: 1,
        from_age: "22",
        to_age: "27",
        speculation: "10",
        growth: "25",
        savings: "35",
        protection: "30"
    },
    {
        id: 2,
        from_age: "22",
        to_age: "27",
        speculation: "10",
        growth: "25",
        savings: "35",
        protection: "30"
    },
    {
        id: 3,
        from_age: "22",
        to_age: "27",
        speculation: "10",
        growth: "25",
        savings: "35",
        protection: "30"
    },
    {
        id: 4,
        from_age: "22",
        to_age: "27",
        speculation: "10",
        growth: "25",
        savings: "35",
        protection: "30"
    },
    {
        id: 5,
        from_age: "22",
        to_age: "27",
        speculation: "10",
        growth: "25",
        savings: "35",
        protection: "30"
    }
]

// Table custom style
const TABLE_STYLE = {
    table: {
        style: {
            borderRadius: "0px",
        },
    },

    headRow: {
        style: {
            borderBottomColor: 'transparent',
            paddingLeft: "32px",
            color: Colors.dark_gray,
            fontFamily: "Montserrat-Bold",
            fontSize: "14px",
            lineHeight: "24px",
            paddingTop: "0px",
            paddingBottom: '0px',

        }
    },
    rows: {
        style: {
            paddingLeft: "32px",
            borderBottom: 'none !important',
            '&:nth-child(odd)': {
                backgroundColor: '#0D0D0D05',
            },
        },
    },
    cells: {
        style: {
            paddingTop: '16px',
            paddingBottom: '16px',
            color: Colors.dark_charcoal,
            fontFamily: "Montserrat-Medium",
            fontSize: "14px",
            lineHeight: "24px",
            paddingLeft: "0px",
            userSelect: "none",
        }
    },
    headCells: {
        style: {
            paddingLeft: "0px"
        }
    },
    pagination: {
        style: {
            paddingRight: "50px",
            paddingTop: "10px",
            borderTop: "none"
        }
    }
};

const PyramidListing = () => {

    const [showBreakdownModal, setShowBreakdownModal] = useState(false);
    const [breakdownDetail, setBreakdownDetail] = useState(null);
    const [confirmDelete, setConfirmDelete] = useState(null);

    useEffect(() => {
        if (confirmDelete !== null) {
            const modal = new Modal(document.getElementById("confirm-delete"));
            modal.show()
        }
    }, [confirmDelete]);


    useEffect(() => {
        if (showBreakdownModal === true) {
            const modal = new Modal(document.getElementById("manage-pyramid"));
            modal.show()
        }
    }, [showBreakdownModal]);

    function _handleBreakdownModal(status, detail) {
        setShowBreakdownModal(status);
        setBreakdownDetail(detail)
    }

    function _handleConfirmDelete(id) {
        setConfirmDelete(id);
    }

    const PYRAMID_COLUMNS = [
        {
            name: "Age group",
            width: "160px",
            cell: row => `${row.from_age} to ${row.to_age}`
        },
        {
            name: "Speculation",
            width: "160px",
            cell: row => `${row.speculation}%`
        },
        {
            name: "Growth",
            width: "160px",
            cell: row => `${row.growth}%`
        },
        {
            name: "Savings",
            width: "160px",
            cell: row => `${row.savings}%`
        },
        {
            name: "Protection",
            width: "160px",
            cell: row => `${row.protection}%`
        },
        {
            name: "",
            width: "60px",
            cell: row => <TextButton
                className="e-font-14 e-line-height-24" name="Edit" hide_arrow={true}
                onPress={() => _handleBreakdownModal(true, row)} />
        },
        {
            name: "",
            width: "70px",
            cell: row => <TextButton
                className="e-font-14 e-line-height-24"
                name="Delete" hide_arrow={true}
                onPress={() => _handleConfirmDelete(row.id)} />
        }
    ]
    return (
        <Fragment>
            <div className="e-bg-light-lavender pt-3 padding-52px-lr">
                <CustomTitle title="Manage Pyramids" type={1} />
            </div>
            <div className="e-body-wrapper">
                <div className="row">
                    <div className="col-12">
                        <div className="bg-white border-radius-32px padding-40px-tb ">
                            <div className="d-flex justify-content-between align-items-center padding-32px-lr">
                                <CustomTabBar data={[{ label: "Model financial breakdown", id: 1 }]}
                                    selectedTab={1} />
                                <PrimaryButton label="Add new breakdown"
                                    className="padding-12px-lr padding-10px-tb"
                                    handleClick={() => _handleBreakdownModal(true, null)} />
                            </div>
                            {/* <div className="col-lg-4">
                                <PercentageInput
                                    label="Speculation"
                                    postfix="*" />
                            </div> */}
                            <div className="pt-4">
                                <DataTableContainer
                                    data={PYRAMID_DATA}
                                    columns={PYRAMID_COLUMNS}
                                    customStyles={TABLE_STYLE} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* add or update pyramid entry */}
            {
                showBreakdownModal &&
                <ManagePyramidModal detail={breakdownDetail}
                    closeModal={() => _handleBreakdownModal(false, null)} />
            }

            {/* delete pyramid */}
            {
                confirmDelete !== null &&
                <ConfirmDeleteModal title={"Delete model financial breakdown"}
                    content={"Are you sure you want to delete this model financial breakdown? This action cannot be undone."}
                    closeModal={() => _handleConfirmDelete(null)} />
            }
        </Fragment>
    )
}
export default PyramidListing