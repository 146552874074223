/*
 *   File : list.js
 *   Author : https://evoqins.com
 *   Description : Portfolio list
 *   Version : 1.0.0
*/

// Packages
import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal } from "bootstrap";

// Components
import { CustomTabBar } from "../../../../Components/Tab";
import { PrimaryButton, TextButton } from "../../../../Components/Buttons";
import { EmptyScreen } from "../../../../Components/Others";
import { PortfolioDetailModal } from "../../../../Components/Modal";

// Service
import APIService from "../../../../Services/api-service";


const BASKET_OPTIONS = [
    {
        label: "Active Portfolio",
        id: 1,
        short_name: "active"
    },
    {
        label: "Passive Portfolio",
        id: 2,
        short_name: "passive"
    }
]



const CustomPortfolioListing = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [pageLoader, setPageLoader] = useState(true);
    const [portfolioList, setPortfolioList] = useState({});

    const [basketType, setBasketType] = useState(BASKET_OPTIONS[0].id);
    const [basketName, setBasketName] = useState(BASKET_OPTIONS[0].short_name);

    const [portfolioDetail, setPortfolioDetail] = useState(null);

    useEffect(() => {

        if (location.state !== null) {
            // if (location.state.tab_index) {
            //     _handleTab(location.state.tab_index);
            // }
            const index = location.state.basket
            setBasketType(index)
            const tab_index = BASKET_OPTIONS.findIndex((item) => item.id === index);
            if (tab_index !== -1) {
                setBasketName(BASKET_OPTIONS[tab_index]['short_name']);
            }
        }
        // eslint-disable-next-line
    }, [location.state]);

    useEffect(() => {
        _getPortfolio();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (portfolioDetail !== null) {
            const modal = new Modal(document.getElementById("portfolio-detail"));
            modal.show()
        }
    }, [portfolioDetail]);

    // function _handleTab(tab_index) {
    //     setTabIndex(tab_index);
    //     const index = TAB_OPTIONS.findIndex((item) => item.id === tab_index);
    //     setRiskTitle(TAB_OPTIONS[index]['label']);
    //     _getPortfolio(tab_index);

    //     navigate('/manage-portfolio', { state: { tab_index: tab_index, basket: basketType, index: 2 } }, { replace: true })
    // }

    function _handleBasket(index) {
        setBasketType(index)
        const tab_index = BASKET_OPTIONS.findIndex((item) => item.id === index);
        if (tab_index !== -1) {
            setBasketName(BASKET_OPTIONS[tab_index]['short_name']);
        }
        navigate('/manage-portfolio', { state: { basket: index, index: 2 } }, { replace: true })
    }

    function _handlePortfolio(data) {
        navigate("manage-custom", {
            state: {
                portfolio: data,
                basket: basketType
            }
        });
    }
    function _handleViewPortfolio(detail) {
        setPortfolioDetail(detail);
    }


    function _handleEdit(data) {
        navigate("manage-custom", {
            state: {
                portfolio: data,
                basket: basketType
            }
        })
    }

    function _totalAllocation() {
        let totalAllocation = 0;

        if (portfolioList[`${basketName}`] !== null && Object.keys(portfolioList).length > 0) {
            const activeFunds = portfolioList[`${basketName}`];
            for (let assetClass in activeFunds) {
                //eslint-disable-next-line
                activeFunds[assetClass].forEach(fund => {
                    if (fund.primary_fund === true) {
                        totalAllocation += fund.allocation;
                    }
                });
            }
        }

        return totalAllocation
    }

    // API - list portfolio
    const _getPortfolio = (type) => {
        setPageLoader(true);
        const url = "/custom-model-portfolio/list";

        APIService(true, url).then((response) => {
            if (response.status_code === 200) {
                setPortfolioList(response.data);
            } else {
                setPortfolioList({});
            }
            setPageLoader(false);
        })
    }

    return (
        <Fragment>
            {
                pageLoader === true ?
                    <div className="h-80vh d-flex align-items-center justify-content-center">
                        <img src={require("../../../../Assets/Images/loader/page-loader.gif")}
                            alt={"loader"}
                            width={60}
                            height={60}
                        />
                    </div>
                    :
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-between align-items-center">
                                <CustomTabBar data={BASKET_OPTIONS}
                                    selectedTab={basketType}
                                    onSelectTab={_handleBasket} />
                                <PrimaryButton
                                    label={portfolioList[`${basketName}`] !== null ? "Update custom portolfio " : "Create custom portfolio"}
                                    className="padding-10px-tb px-3"
                                    handleClick={() => portfolioList[`${basketName}`] !== null ? _handleEdit(portfolioList) : _handlePortfolio(basketType === 2 ? portfolioList : null)} />
                            </div>
                        </div>



                        {
                            portfolioList[`${basketName}`] !== null ?
                                <div className="row padding-32px-top">

                                    <div className="col-lg-6 pb-3">
                                        <div className="border-1px border-lilac-frost e-bg-white-lilac padding-32px-tb px-3 border-radius-32px position-relative h-100">
                                            <div className="d-flex justify-content-between align-items-center px-3 pb-2">
                                                <h6 className="color-dark-charcoal e-font-14 e-montserrat-bold e-line-height-24 mb-0">
                                                    Portfolio allocations : <span className="color-dark-charcoal e-montserrat-semi-bold e-line-height-24">({_totalAllocation()}%)</span>
                                                </h6>
                                            </div>

                                            <div className="e-bg-pastel-lavender p-3 border-radius-12px e-box-shadow-black36">
                                                <div className="row pb-2">
                                                    <div className="col-8">
                                                        <h6 className="e-font-14 e-line-height-24 color-dark-gray e-montserrat-bold mb-0">Fund name</h6>
                                                    </div>
                                                    <div className="col-4 text-end">
                                                        <h6 className="e-font-14 e-line-height-24 color-dark-gray e-montserrat-bold mb-0">Allocation</h6>
                                                    </div>
                                                </div>
                                                {
                                                    Object.values(portfolioList[`${basketName}`]).map((portfolio) => {
                                                        return (
                                                            // eslint-disable-next-line
                                                            portfolio.map((item) => {
                                                                if (item.primary_fund === true) {
                                                                    return (
                                                                        <Fragment>
                                                                            <div className="row pb-3">
                                                                                <div className="col-8">
                                                                                    <div className="d-flex gap-8px align-items-center">
                                                                                        <img src={item.image} alt="" width={32}
                                                                                            className="e-box-shadow-black36 border-radius-8px" />
                                                                                        <span className="e-font-12 e-line-height-18 color-dark-charcoal e-montserrat-medium">{item.fund_name}</span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-4 text-end">
                                                                                    <span className="e-font-14 e-line-height-20 color-dark-charcoal e-montserrat-semi-bold">{item.allocation}%</span>
                                                                                </div>

                                                                            </div>
                                                                        </Fragment>
                                                                    )
                                                                }
                                                            })
                                                        )
                                                    })
                                                }

                                                <Fragment>
                                                    <div className="w-100 border-bottom-1px border-color-mint-gray"></div>
                                                    <div className="d-flex justify-content-between align-items-center pt-3">

                                                        <TextButton name="View more"
                                                            hide_arrow={true}
                                                            onPress={() => _handleViewPortfolio(portfolioList[`${basketName}`])} />
                                                    </div>
                                                </Fragment>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <EmptyScreen className="my-5"
                                    title="No funds added"
                                    width={150}
                                    image={require("../../../../Assets/Images/loader/empty-funds.png")}
                                    description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" />
                        }
                    </div>
            }

            {
                portfolioDetail !== null &&
                <PortfolioDetailModal funds={portfolioDetail}
                    closeModal={() => _handleViewPortfolio(null)} />
            }
        </Fragment >
    )
}

export default CustomPortfolioListing