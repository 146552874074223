/*
 *   File :  time-picker.js
 *   Author : https://evoqins.com
 *   Description : PIcker component to select time from the available suggestions.
 *   Integrations : react-select
 *   Version : 1.0.0
*/

// Packages
import React, { useEffect, useState, Fragment, memo, useRef } from 'react';
import TimePicker from 'rc-time-picker';
import moment from 'moment'; // Import moment.js for time handling
import 'rc-time-picker/assets/index.css';

// Components
import { PrimaryButton, SecondaryButton } from '../Buttons';
import { Icon } from '../Icon';

// styles
import style from "../../Styles/Components/time-picker.module.scss"


const CustomTimePicker = (props) => {
    const timeRef = useRef()
    const [selectedTime, setSelectedTime] = useState(props.time ? moment(props.time, 'h:mm a') : null); // Use current time if time prop is not provided



    useEffect(() => {
        setSelectedTime(props.time ? moment(props.time, 'h:mm a') : null)
    }, [props.time])

    const handleTimeChange = (newTime) => {
        setSelectedTime(newTime);
        props.onTimeChange && props.onTimeChange(newTime && newTime.format('h:mm a')); // Convert to 12-hour format and pass back to parent if onTimeChange exists
    };

    const _handleBlur = () => {
        if (timeRef.current && timeRef.current) {
            setTimeout(() => {
                timeRef.current.blur()
                props.open(false)
            }, 0);
        }
    };

    const _handleSubmit = (panel) => {
        _handleBlur()
        // props.onTimeChange && props.onTimeChange(selectedTime && selectedTime.format('h:mm a'));
        panel.close(); // Close the TimePicker panel
    };


    const _handleClick = () => {
        if (timeRef.current) {
            setTimeout(() => {
                timeRef.current.focus();
                props.open(true)
            }, 0)
        }
    };

    return (
        <Fragment>
            <label className="e-montserrat-medium e-font-14 e-line-height-18 color-dark-charcoal mb-1">{props.label}
                {props.postFix && <span className="color-deep-carmine-pink">*</span>}
            </label>

            <div className='position-relative' onClick={_handleClick}>
                <TimePicker
                    showSecond={false}
                    defaultValue={selectedTime}
                    value={selectedTime}
                    onChange={handleTimeChange}
                    placeholder="Select time"
                    format="h:mm a" // Display in 12-hour format
                    use12Hours // Use 12-hour format
                    className={style.e_timer}
                    ref={timeRef}
                    onFocus={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        timeRef.current.focus()
                    }}
                    onMouseDown={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                    onBlur={(e) => {
                        e.stopPropagation();
                        _handleBlur()
                    }}
                    popupClassName="e-popup-time-picker mt-2"
                    clearIcon={
                        <Icon icon="close"
                            size={24}
                            className={`position-absolute cursor-pointer ${style.e_icon}`}
                        />
                    }
                    inputIcon={selectedTime !== null ? null :
                        <img src={require("../../Assets/Images/create-campaign/timer.png")}
                            alt="Timer"
                            width={24}
                            height={24}
                            className={`position-absolute cursor-pointer ${style.e_icon}`} />
                    }
                    addon={(panel) => (
                        // Receive panel instance as a parameter
                        <>
                            <SecondaryButton
                                label="Cancel"
                                className="py-2 px-3 me-2 e-border-radius-8 position-absolute bottom-6px right-92px"
                                handleClick={() => {
                                    setSelectedTime(null);
                                    props.onTimeChange && props.onTimeChange(null);
                                    _handleSubmit(panel)
                                }} // Pass panel instance to _handleSubmit
                            />
                            <PrimaryButton
                                label="Confirm"
                                disabled={selectedTime === null}
                                className="py-2 px-3 e-border-radius-8 position-absolute bottom-6px right-6px"
                                handleClick={() => _handleSubmit(panel)} // Pass panel instance to _handleSubmit
                            />
                            {
                                selectedTime !== null && <Icon icon="close"
                                    size={24}
                                    className={`position-absolute cursor-pointer top-12px right-12px`}
                                    onClick={() => {
                                        setSelectedTime(null);
                                        _handleBlur()
                                        props.onTimeChange && props.onTimeChange(null);
                                    }} />
                            }
                        </>

                    )}
                />

                {props.error &&
                    <p className={`color-deep-carmine-pink  e-montserrat-medium e-font-12  e-line-height-16 my-0 position-absolute ${style.e_error}`}>
                        {props.error}
                    </p>
                }
            </div>

        </Fragment>
    );
};

export default memo(CustomTimePicker);
