/*
 *   File : badge.js
 *   Author : https://evoqins.com
 *   Description :Badge component
 *   Integrations : js-cookie
 *   Version : 1.0.0
*/

import { memo } from "react"

const Badge = (props) => {
    // let color = 'green';
    // if (props.status === 'Sign up') {
    //     color = '#2530CB';
    // } else if (props.status === 'Ready to invest') {
    //     color = '#F99F19';
    // } else if (props.status === 'Invested') {
    //     color = '#069A6E';
    // } else {
    //     color = '#9A0664';
    // }
    return (
        <span className={`e-montserrat-medium e-font-12 e-line-height-14 border-radius-100px py-1 px-2 color-white`}
            style={{ background: props.color }}
            onClick={props.onClick}>{props.status}</span>
    )

}

Badge.defaultProps = {
    onClick: () => { }
}
export default memo(Badge)