
/*
 *   File : campaign-detail.js
 *   Author URI : https://evoqins.com
 *   Description : The modal component used for showing Campaign details.
 *   Integrations : null
 *   Version : v1.1
 */

import { Fragment, memo, useEffect } from "react";


// Custom styles
import style from "../../Styles/Components/campaign-detail.module.scss";
import Icon from "../Icon/icon";


const CampaignDetailModal = (props) => {


    useEffect(() => {
        var my_modal = document.getElementById("campaign-details-modal");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
        });
        //eslint-disable-next-line
    }, [props.close]);


    const CampaignDetail = (props) => {
        return (
            <div>
                <p className="e-montserrat-medium e-font-12 e-line-height-16 color-dark-gray margin-2px-bottom">{props.title}</p>
                <p className="e-montserrat-semi-bold e-font-12 e-line-height-20 color-dark-gray mb-0">{props.rate}%</p>
            </div>

        )
    }
    return (
        <Fragment>
            <div className={`modal fade ${style.e_modal}`}
                id="campaign-details-modal"
                tabIndex="-1"
                aria-labelledby="campaign-details-modal"
                aria-hidden="true"
                data-bs-backdrop="true" >
                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                    <div className={`modal-content padding-32px-all ${style.e_content} `}>
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <h5 className="e-font-16 e-line-height-24 e-montserrat-semi-bold color-dark-charcoal mb-0">
                                Campaign details
                            </h5>
                            <Icon icon="close"
                                size={18}
                                id="close-modal"
                                data-bs-dismiss="modal"
                                className='cursor-pointer' />
                        </div>
                        <p className="e-montserrat-regular e-font-12 e-line-height-16 color-dark-gray">Campaign name: <span className="e-montserrat-medium">{props.data.name}</span></p>
                        <div className="d-flex gap-24px e-bg-lavender-white p-3 border-radius-16px">
                            <CampaignDetail title="Open rate" rate={props.data.open_rate} />
                            <CampaignDetail title="Click-through rate " rate={props.data.click_through_rate} />
                            <CampaignDetail title="Bounce rate" rate={props.data.bounce_rate} />
                            <CampaignDetail title="Unsubscribe rate" rate={props.data.unsubscribe_rate} />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default memo(CampaignDetailModal)