
/*
 *   File : manage-resource.js
 *   Author URI : https://evoqins.com
 *   Description : Modal component to create UI to add or update resources.
 *   Integrations : null
 *   Version : v1.1
 */

import { Fragment, useEffect, useState } from "react";


// Custom styles
import style from "../../Styles/Components/manage-resource.module.scss";
import Icon from "../Icon/icon";
import { PrimaryButton, SecondaryButton } from "../Buttons";
import { CustomTextInput1 } from "../FormElements";

const ManageResourceModal = (props) => {

    const [categoryName, setCategoryName] = useState("");
    const [categoryNameError, setCategoryNameError] = useState("");

    useEffect(() => {
        if (props.value) {
            setCategoryName(props.value.name);
        }
    }, [props.value]);

    useEffect(() => {
        var my_modal = document.getElementById("edit-resource-modal");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
        });
        //eslint-disable-next-line
    }, [props.close]);

    const _validate = () => {
        if (categoryName.length === 0) {
            setCategoryNameError("Category required");
        }
        else {
            _handleConfirm();
        }
    }
    const _handleConfirm = async () => {

        try {
            const result = await props.onConfirm(categoryName);
            console.log(result)
            if (result === true) {
                document.getElementById("close-modal").dispatchEvent(new Event("click"));
                props.updateList()
            }
        } catch (err) {

        }


    }

    const _handleCategory = (value) => {
        setCategoryName(value);
        setCategoryNameError("");
    }

    return (
        <Fragment>
            <div className={`modal fade ${style.e_modal}`}
                id="edit-resource-modal"
                tabIndex="-1"
                aria-labelledby="edit-resource-modal"
                aria-hidden="true"
                data-bs-backdrop="true" >
                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                    <div className={`modal-content padding-32px-all ${style.e_content} `}>
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <h5 className="e-font-16 e-line-height-24 e-montserrat-semi-bold color-dark-charcoal mb-0">
                                Edit category
                            </h5>
                            <Icon icon="close"
                                size={18}
                                id="close-modal"
                                data-bs-dismiss="modal"
                                className='cursor-pointer' />
                        </div>
                        <CustomTextInput1 label="Category name"
                            type='text'
                            id="title"
                            className='margin-40px-bottom'
                            postfix="*"
                            placeHolder="Enter category"
                            error={categoryNameError}
                            value={categoryName}
                            handleChange={_handleCategory} />
                        <div className="d-flex gap-8px justify-content-end">
                            <SecondaryButton label="Cancel"
                                cancel="modal"
                                className="padding-14px-tb padding-50px-lr" />
                            <PrimaryButton label={props.value ? "Update" : "Create"}
                                className="padding-14px-tb padding-50px-lr"
                                loader={props.loader}
                                disabled={props.loader}
                                handleClick={_validate} />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ManageResourceModal