/*
 *   File : area.js
 *   Author URI : https://evoqins.com
 *   Description : Area chart to show portfolio returns
 *   Integrations : chart.js
 *   Version : v1.1
 */
import { memo, useEffect } from "react";
import Chart from "chart.js/auto";

import Colors from '../../Styles/color.module.scss';

const AreaChart = (props) => {

    function _formatNumber(number) {
        if (number >= 10000000) {
            return (number / 10000000).toFixed(1).replace(/\.0$/, '') + 'Cr';
        } else if (number >= 100000) {
            return (number / 100000).toFixed(1).replace(/\.0$/, '') + 'L';
        } else if (number >= 1000) {
            return (number / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
        } else {
            return number.toString();
        }
    }

    useEffect(() => {

        // Adding data and setting position of the tool tip
        const externalTooltipHandler = (context) => {
            // Tooltip Element
            const { chart, tooltip } = context;
            const tooltipEl = getOrCreateTooltip(chart);
            let hoverLine = chart.canvas.parentNode.querySelector('div.line-hover');
            let dataPointer = chart.canvas.parentNode.querySelector('div.data-pointer');
            if (!hoverLine) {
                hoverLine = document.createElement('div');
                hoverLine.className = 'line-hover'; // Add a class for styling
                chart.canvas.parentNode.appendChild(hoverLine);
            }

            if (!dataPointer) {
                dataPointer = document.createElement('div');
                dataPointer.className = 'data-pointer'; // Add a class for styling
                chart.canvas.parentNode.appendChild(dataPointer);
            }

            // Hide if no tooltip
            if (tooltip.opacity === 0) {
                // tooltipEl.style.opacity = 0;
                // hoverLine.style.opacity = 0;
                // dataPointer.style.opacity = 0;
                return;
            }

            if (tooltip.body) {
                const title = document.createElement('div'); //tooltip title
                const body = document.createElement('div'); // tooltip body
                // taking data from chart tooltip object and create text node
                const toolTipTitle = document.createTextNode(tooltip.title[0]);
                const toolTipBody = document.createTextNode(`NAV: ₹${tooltip.body[0].lines[0]}`);
                // append the title to title div and style
                title.appendChild(toolTipTitle);
                title.style.color = Colors.gray;
                title.style.fontSize = '12px';
                title.style.fontFamily = 'Montserrat-Regular';
                // append the body content to the div and style
                body.appendChild(toolTipBody);
                body.style.color = Colors.black;
                body.style.fontSize = '14px';
                body.style.fontFamily = 'Montserrat-Medium';
                tooltipEl.innerHTML = ''; // Clear previous content
                // appending tooltip to the tooltip element
                tooltipEl.appendChild(title);
                tooltipEl.appendChild(body);
            }

            const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1;
            tooltipEl.style.left = positionX + tooltip.caretX + 'px';
            tooltipEl.style.top = positionY + tooltip.caretY + 'px';

            // Apply styles to the tooltip
            tooltipEl.style.background = Colors.white;
            tooltipEl.style.borderRadius = '6px';
            tooltipEl.style.boxShadow = '4px 4px 12px 0px rgba(175, 175, 175, 0.58)';
            tooltipEl.style.pointerEvents = 'none';
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.top = '-4px';
            tooltipEl.style.transform = 'translate(-50%, -50%)';
            tooltipEl.style.transition = 'all .1s ease';
            tooltipEl.style.padding = '5px 10px';
            tooltipEl.style.width = 'max-content';
            tooltipEl.style.zIndex = '2';

            // hoverLine
            hoverLine.style.opacity = 1;
            hoverLine.style.position = 'absolute';
            hoverLine.style.left = positionX + tooltip.caretX + 'px';
            hoverLine.style.bottom = '35px';
            hoverLine.style.width = '2px';
            hoverLine.style.height = '85%';
            hoverLine.style.backgroundColor = Colors.rebecca_purple;
            hoverLine.style.zIndex = 1;

            // Data pointer
            dataPointer.style.opacity = 1;
            dataPointer.style.position = 'absolute';
            dataPointer.style.left = tooltip.caretX - 8 + 'px';
            dataPointer.style.top = tooltip.caretY - 10 + 'px';
            dataPointer.style.backgroundColor = Colors.rebecca_purple;
            dataPointer.style.border = '3px solid #fff';
            dataPointer.style.width = '18px';
            dataPointer.style.height = '18px';
            dataPointer.style.borderRadius = '100%';
            dataPointer.style.zIndex = 2;
        };


        // Chart configuration
        var newChart = document.getElementById('areaChart').getContext('2d');
        newChart.canvas.style.zIndex = 1;
        newChart = new Chart(newChart, {
            type: 'line',
            data: { //chart area configuration
                labels: props.labels,
                datasets: [{
                    data: props.data,
                    order: 1,
                    backgroundColor: function (context) {
                        const chart = context.chart;
                        const { ctx, chartArea } = chart;

                        return _getGradient(ctx, chartArea);
                    },
                    borderColor: [
                        Colors.rebecca_purple
                    ],
                    borderWidth: 1,
                    tension: 0,
                    fill: 'start',
                    pointRadius: 0,
                    pointHoverRadius: 0,
                    pointBorderWidth: 0,
                    pointHoverBorderWidth: 0,
                    pointHoverBorderColor: Colors.white,
                    pointBorderColor: Colors.white,
                    pointBackgroundColor: Colors.rebecca_purple,
                    padding: 3,
                }
                ]
            },
            // plugins: [hoverLine], //custom plugins (hover line)
            options: { //chart  options 
                hover: {
                    mode: 'index',
                    intersect: false,
                },
                interaction: {
                    mode: 'dataset',
                },
                onHover: (e, activeElements, chart) => {
                    const {
                        bottom,
                        top,
                        right,
                        left
                    } = chart.chartArea;
                    let tooltipEl = chart.canvas.parentNode.querySelector('div.tooltip');
                    if (e.x >= left && e.x <= right && e.y <= bottom && e.y >= top) {
                        if (tooltipEl) {
                            tooltipEl.style.display = 'block';
                        }
                    } else {
                        if (tooltipEl) {
                            tooltipEl.style.display = 'none';
                        }
                    }

                },
                scales: { // grid lines
                    x: {
                        beginAtZero: true,
                        offset: false,
                        border: {
                            display: false,
                        },

                        grid: {
                            drawTicks: true,
                            drawBorder: false,
                            display: false,
                            // tickBorderDashOffset: 5,
                            offset: false,
                        },
                    },
                    y: {
                        beginAtZero: true,
                        offset: false,
                        min: 0,
                        // type: 'category',
                        // labels: ['100', '200', '300', '400', '500'],
                        ticks: {
                            maxTicksLimit: 5,
                            color: Colors.light_black,
                            padding: 10,
                            callback: function (value, index, ticks) {
                                return '₹ ' + _formatNumber(value);
                            },
                            font: {
                                size: 14,
                                weight: 400,
                                family: 'Montserrat-Regular'
                            }

                        },
                        border: {
                            display: false,
                            dash: [8, 4],
                        },
                        grid: {
                            drawTicks: false,
                            drawBorder: false,
                            // tickBorderDashOffset: 5,
                            // offset: true, 
                        },
                    },
                },
                plugins: {
                    legend: { // remove legend
                        display: false,
                    },
                    corsair: { //  hover line
                        // color: Colors.deep_blue,
                    },
                    tooltip: { //tooltip
                        mode: 'index',
                        intersect: false,
                        enabled: false,
                        position: 'nearest',
                        external: externalTooltipHandler
                    },

                }
            }

        });
        return () => {
            newChart.destroy()
        }
        // eslint-disable-next-line
    }, [props.data]);

    const _getGradient = (ctx, chartArea) => {
        if (chartArea) {
            let width, height, gradient;
            const chartWidth = chartArea.right - chartArea.left;
            const chartHeight = chartArea.bottom - chartArea.top;
            if (gradient === null || width !== chartWidth || height !== chartHeight) {
                width = chartWidth;
                height = chartHeight;
                gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
                gradient.addColorStop(0, '#F4ECFF');
                gradient.addColorStop(1, '#D8A8FE');
            }
            return gradient;
        }
    };

    // Creating custom tool tip
    const getOrCreateTooltip = (chart) => {
        let tooltipEl = chart.canvas.parentNode.querySelector('div.tooltip');

        if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.className = 'tooltip'; // Add a class for styling
            chart.canvas.parentNode.appendChild(tooltipEl);
        }

        return tooltipEl;
    };


    return (
        <div className="e-chart-container position-relative"
            id='areaChartContainer'>
            <canvas id="areaChart"
                width="400"
                className="position-relative "
                height="200"
            ></canvas>

        </div>
    )
}

export default memo(AreaChart);


