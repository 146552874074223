/*
 *   File : change-log.js
 *   Author : https://evoqins.com
 *   Description : Log list
 *   Version : 1.0.0
*/
// packages
import { Fragment } from "react"
import { useNavigate } from "react-router-dom";

// Custom components
import { Breadcrumb } from "../../../Components/Others";
import { DataTableContainer } from "../../../Components/DataTable";

// Custom style
import Colors from '../../../Styles/color.module.scss';

const QUESTIONNAIRE_DATA = [
    {
        question: "What describes your attitude towards investment?",
        date_time: "20-2-2024",
        edited_by: "Admin",
        details: "Changed options",
        before_edit: "Not stable",
        after_edit: "Somewhat stable"
    },
    {
        question: "What is your current age?",
        date_time: "20-2-2024",
        edited_by: "Admin",
        details: "Changed options",
        before_edit: "-",
        after_edit: "-"
    },
    {
        question: "How do you find your current and future income to be?",
        date_time: "16-2-2024",
        edited_by: "Admin",
        details: "Changed options",
        before_edit: "Not stable",
        after_edit: "Somewhat stable"
    },
    {
        question: "What describes your attitude towards investment?",
        date_time: "18-1-2024",
        edited_by: "Admin",
        details: "Changed options",
        before_edit: "Not stable",
        after_edit: "Somewhat stable"
    },
    {
        question: "What is your average monthly expense?",
        date_time: "16-2-2024",
        edited_by: "Admin",
        details: "Changed options",
        before_edit: "-",
        after_edit: "-"
    }
]

// Table custom style
const TABLE_STYLE = {
    table: {
        style: {
            borderRadius: "0px",
        },
    },

    headRow: {
        style: {
            borderBottomColor: 'transparent',
            paddingLeft: "32px",
            color: Colors.dark_gray,
            fontFamily: "Montserrat-Bold",
            fontSize: "14px",
            lineHeight: "24px",
            paddingTop: "0px",
            paddingBottom: '0px',

        }
    },
    rows: {
        style: {
            paddingLeft: "32px",
            borderBottom: 'none !important',
            '&:nth-child(odd)': {
                backgroundColor: '#0D0D0D05',
            },
        },
    },
    cells: {
        style: {
            paddingTop: '16px',
            paddingBottom: '16px',
            color: Colors.dark_charcoal,
            fontFamily: "Montserrat-Medium",
            fontSize: "14px",
            lineHeight: "24px",
            paddingLeft: "0px",
            userSelect: "none",
        }
    },
    headCells: {
        style: {
            paddingLeft: "0px"
        }
    },
    pagination: {
        style: {
            paddingRight: "50px",
            paddingTop: "10px",
            borderTop: "none"
        }
    }
};

const ChangeLog = () => {

    const navigate = useNavigate();

    function _handleNavigate() {
        navigate("/manage-risk")
    }

    const QUESTIONNAIRE_COLUMNS = [
        {
            name: "Date & Time",
            cell: row => row.date_time,
            width: "140px"
        },

        {
            name: "Edited by",
            cell: row => row.edited_by,
            width: "120px"
        },
        {
            name: "Question",
            cell: row => row.question,
            width: "30%"
        },
        {
            name: "Before edit",
            width: "140px",
            cell: row => `${row.before_edit}`
        },
        {
            name: "After edit",
            width: "160px",
            cell: row => row.after_edit
        },
        {
            name: "Details",
            width: "140px",
            cell: row => row.details
        },

    ]

    return (
        <Fragment>
            <div className="e-bg-light-lavender pt-3 padding-52px-lr">
                <div className="padding-110px-bottom">
                    <Breadcrumb handleNavigate={_handleNavigate}
                        mainTitle="Manage risk profiling"
                        subTitle="Edit log"
                    />
                </div>
            </div>
            <div className="e-body-wrapper">
                <div className="row">
                    <div className="col-12">
                        <div className="bg-white border-radius-32px padding-40px-tb ">
                            <div className=" padding-32px-lr">
                                <h4 className="e-font-16 e-line-height-20 e-montserrat-semi-bold color-dark-charcoal">
                                    Questionnaire Edit Log
                                </h4>
                            </div>

                            <div className="padding-32px-top">
                                <DataTableContainer
                                    data={QUESTIONNAIRE_DATA}
                                    columns={QUESTIONNAIRE_COLUMNS}
                                    customStyles={TABLE_STYLE} />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ChangeLog