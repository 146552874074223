
/*
 *   File : user-detail.js
 *   Author URI : https://evoqins.com
 *   Description : User detail summary
 *   Integrations : null
 *   Version : 1
 */

// Packages
import React, { memo } from "react";
import { Badge } from "../Badge";
import Config from "../../Helper/config";

// Custom components


const UserDetail = React.memo((props) => {
    return (
        <div className="row d-flex align-items-center">
            <div className="col-lg-2 col-md-12 col-12">
                <div className="d-flex align-items-center flex-column justify-content-start">
                    <div className="e-bg-lavender-mist border-radius-100 min-w-120px min-h-120px d-flex justify-content-center color-rebecca-purple align-items-center e-montserrat-semi-bold e-font-24 e-line-height-32">
                        {props.data.profile.short_name}
                    </div>
                    <div className="d-block ">

                        <p className="mt-2 e-montserrat-semi-bold e-font-20 e-line-height-26 color-dark-charcoal mb-0 word-break">
                            {props.data.profile.name}
                        </p>
                        <span className="mt-2 e-montserrat-medium e-font-14 e-line-height-24 color-dark-charcoal mb-0">
                            {props.data.profile.kyc_status_text}
                        </span>
                    </div>
                </div>
            </div>

            <div className="col-lg-10 col-md-12 col-12 mt-lg-0 mt-3">
                <div className="e-bg-pale-lilac p-3 d-flex flex-wrap align-items-end row-gap-16px">

                    <div className="mb-xl-0 mb-4 padding-64px-right">
                        {
                            props.data.profile.status_text !== undefined && <div className="col-12 pb-2">
                                <Badge status={props.data.profile.status_text}
                                    color={props.data.profile.status_text === "Sign up" ? Config.BADGE_COLORS.blue :
                                        props.data.profile.status_text === 'Ready to invest' ? Config.BADGE_COLORS.orange :
                                            props.data.profile.status_text === "Invested" ? Config.BADGE_COLORS.green :
                                                Config.BADGE_COLORS.magenta}
                                />
                            </div>
                        }
                        <h6 className="color-dark-gray e-montserrat-medium e-font-14 e-line-height-24 mb-xxl-3 mb-xl-1">
                            Amount invested
                        </h6>
                        <p className="color-dark-charcoal pt-xl-0 pt-0 e-font-14 e-line-height-24 e-montserrat-semi-bold mb-0">
                            {props.data.investment_summary.invested_amount_text}
                        </p>
                    </div>
                    <div className="mb-xl-0 mb-4 padding-64px-right">
                        <h6 className="color-dark-gray e-montserrat-medium e-font-14 e-line-height-22 mb-xxl-3 mb-xl-1">
                            Current value
                        </h6>
                        <p className="color-dark-charcoal pt-xl-0 pt-0 e-font-14 e-line-height-24 e-montserrat-semi-bold mb-0">
                            {props.data.investment_summary.current_amount_text}
                        </p>
                    </div>
                    <div className="mb-xl-0 mb-4 padding-64px-right">
                        <h6 className="color-dark-gray e-montserrat-medium e-font-14 e-line-height-22 mb-xxl-3 mb-xl-1">
                            P&L
                        </h6>
                        <p className={`e-montserrat-semi-bold e-font-14 e-line-height-24 mb-0 pt-xl-0 pt-0  ${props.data.investment_summary.profit_loss < 0 ? "color-red" : "color-emerald-green"}`}>
                            {props.data.investment_summary.profit_loss_text}
                        </p>
                    </div>
                    <div className="mb-xl-0 mb-4 padding-64px-right">
                        <h6 className="color-dark-gray e-montserrat-medium e-font-14 e-line-height-22 mb-xxl-3 mb-xl-1">
                            XIRR
                        </h6>
                        <p className={`pt-xl-0 pt-0 e-font-14 e-line-height-24 e-montserrat-semi-bold mb-0
                            ${props.data.investment_summary.xirr < 0 ? "color-red" : "color-emerald-green"}`}>
                            {props.data.investment_summary.xirr_text}
                        </p>
                    </div>
                    <div className="mb-xl-0 mb-4">
                        <h6 className="color-dark-gray e-montserrat-medium e-font-14 e-line-height-22 mb-xxl-3 mb-xl-1">
                            1 day return
                        </h6>
                        <p className={`pt-xl-0 pt-0 e-font-14 e-line-height-24 e-montserrat-semi-bold mb-0 
                            ${props.data.investment_summary.one_day_returns < 0 ? "color-red" : "color-emerald-green"}`}>
                            {props.data.investment_summary.one_day_returns_text}
                            <span className={props.data.investment_summary.one_day_returns_percentage < 0 ? "color-red" : "color-emerald-green"}> ({props.data.investment_summary.one_day_returns_percentage_text})</span>
                        </p>
                    </div>

                </div>
            </div>

        </div>
    )
})

export default memo(UserDetail);