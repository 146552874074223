/*
 *   File : dashboard.js
 *   Author : https://evoqins.com
 *   Description : Dashboard Container gives information about summary, aum, user, transaction of App  *   Version : 1.0.0
*/

// packages
import { Fragment, useEffect, useState } from "react";

// Custom components
import { CustomTitle } from "../../../Components/Title";
import { SummaryCard } from "../../../Components/Cards";
import { CustomSelect, SearchInput } from "../../../Components/FormElements";
import { PrimaryButton, SecondaryButton, } from "../../../Components/Buttons";
import { DataTableContainer } from "../../../Components/DataTable";
import { EmptyScreen } from "../../../Components/Others";
import { Icon } from "../../../Components/Icon";
import { Badge } from "../../../Components/Badge";

// Custom styles
import Colors from '../../../Styles/color.module.scss';

import APIService from "../../../Services/api-service";
import Config from "../../../Helper/config";

// Table custom style
const TABLE_STYLE = {
    table: {
        style: {
            borderRadius: "0px",
        },
    },

    headRow: {
        style: {
            borderBottomColor: 'transparent',
            paddingLeft: "24px",
            color: Colors.dark_gray,
            fontFamily: "Montserrat-SemiBold",
            fontSize: "14px",
            lineHeight: "24px",
            paddingTop: "0px",
            paddingBottom: '0px',

        }
    },
    rows: {
        style: {
            cursor: "pointer",
            paddingLeft: "24px",
            borderBottom: 'none !important',
            '&:nth-child(odd)': {
                backgroundColor: '#0D0D0D05',
            },

        },
    },
    cells: {
        style: {
            paddingTop: '16px',
            paddingBottom: '16px',
            color: Colors.dark_charcoal,
            fontFamily: "Montserrat-Medium",
            fontSize: "14px",
            lineHeight: "24px",
            paddingLeft: "0px",
            userSelect: "none",
        }
    },
    headCells: {
        style: {
            paddingLeft: "0px"
        }
    },
    pagination: {
        style: {
            paddingRight: "50px"
        }
    }
};

const REFERRED_OPTIONS = [
    {
        label: "All",
        value: null
    },
    {
        label: "Manager 1",
        value: 1
    }
]

const RMDashboard = () => {


    const [dashboardSummary, setDashboardSummary] = useState({});
    const [dashboardData, setDashboardData] = useState([]);
    const [cardLoader, setCardLoader] = useState(true);
    const [tableLoader, setTableLoader] = useState(true);
    const [search, setSearch] = useState("");
    const [filterEnabled, setFilterEnabled] = useState(false);
    const [cityOptions, setCityOptions] = useState([]);

    const [city, setCity] = useState(null);
    const [kycOptions, setKycOptions] = useState([]);
    const [kyc, setKyc] = useState(null);
    const [investmentStatusOptions, setInvestmentStatusOptions] = useState([]);;
    const [investmentStatus, setInvestmentStatus] = useState(null);
    const [referredBy, setReferredBy] = useState(REFERRED_OPTIONS[0])



    useEffect(() => {
        _getDashboardData();
        _getFilterData();
        _getAUM();
    }, []);



    function _handleKycStatus(select_value) {
        setKyc(select_value);
    }


    function _handleSearch(search_value) {
        setSearch(search_value);
    }


    function _handleToggleFilter() {
        setFilterEnabled(!filterEnabled)
    }

    function _handleCity(select_value) {
        setCity(select_value);
    }

    function _handleReferredBy(select_value) {
        setReferredBy(select_value);
    }

    function _handleInvestmentStatus(select_value) {
        setInvestmentStatus(select_value);
    }

    function _handleFilter() {
        setFilterEnabled(false)
    }
    function _handleClear() {
        setFilterEnabled(false);
        setKyc(kycOptions[0]);
        setCity(cityOptions[0]);
        setInvestmentStatus(investmentStatusOptions[0]);
        setReferredBy(REFERRED_OPTIONS[0]);
    }

    // Table columns
    const TABLE_COLUMNS = [
        {
            name: "Name",
            cell: row => row.name,
            width: "140px"
        },
        {
            name: "Email ",
            cell: row => <a href={`mailto:${row.email}`}
                className="text-decoration-none color-dark-charcoal">{row.email}</a>,
            width: "180px"
        },
        {
            name: "Phone no",
            cell: row => <a href={`tel:${row.mobile}`}
                className="text-decoration-none color-dark-charcoal">{row.mobile}</a>,
        },
        {
            name: "City",
            cell: row => "Bangalore"
        },
        {
            name: "Referred by",
            cell: row => "Sonic Geigy"
        },
        {
            name: "Referred by",
            cell: row => "Sonic Geigy"
        },
        {
            name: "KYC type",
            cell: row => "Non-KYC"
        },
        {
            name: "Current status",
            cell: row => <Badge status="Invested" color={Config.BADGE_COLORS.green} />
        },
    ]


    // API - get Dashboard data
    const _getDashboardData = () => {
        const url = "/dashboard/get";

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setDashboardSummary(response.data);
            }
            setCardLoader(false);
        });
    }



    const Loading = () => {
        return (
            <div className="h-80vh d-flex align-items-center justify-content-center">
                <img src={require("../../../Assets/Images/loader/page-loader.gif")}
                    alt={"loader"}
                    width={60}
                    height={60}
                />
            </div>
        )
    }

    // API - get Dashboard data
    const _getAUM = () => {
        const url = "/dashboard/get-customer-aum";

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setDashboardData(response.data);
            }
            setTableLoader(false);
        });
    }

    // API - get filter data
    const _getFilterData = () => {
        const url = "/user/filter-data";

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setKycOptions(response.data.kyc_status);
                setCityOptions(response.data.city);
                setInvestmentStatusOptions(response.data.investment_status);
                setKyc(response.data.kyc_status[0]);
                setCity(response.data.city[0]);
                setInvestmentStatus(response.data.investment_status[0]);
            }
        });
    }

    return (


        <Fragment>
            <div className="e-bg-light-lavender pt-3 padding-52px-lr">
                <CustomTitle title="Dashboard" type={1} />
            </div>

            <div className="e-body-wrapper">

                {/* -------- Summary-------- */}
                {
                    cardLoader === true ?
                        <Loading />
                        :
                        Object.keys(dashboardSummary).length === 0 ?
                            <EmptyScreen className="my-5"
                                title="No data found"
                                image={require("../../../Assets/Images/loader/empty-funds.png")}
                                description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" />
                            :
                            <div className="row">

                                <div className="col-lg-3 col-md-6 col-12 mb-4">
                                    <SummaryCard icon={require("../../../Assets/Images/Dashboard/total-users.svg").default}
                                        title="Total users referred"
                                        countText={dashboardSummary.total_users}
                                    />
                                </div>
                                <div className="col-lg-3 col-md-6 col-12 mb-4">
                                    <SummaryCard icon={require("../../../Assets/Images/Dashboard/non-invested-users.svg").default}
                                        title="Users joined"
                                        countText={dashboardSummary.non_invested_user}
                                    />
                                </div>
                                <div className="col-lg-3 col-md-6 col-12 mb-4">
                                    <SummaryCard icon={require("../../../Assets/Images/Dashboard/invested-users.svg").default}
                                        title="Users started investing"
                                        countText={dashboardSummary.invested_user}
                                    />
                                </div>
                            </div>
                }



                {/* =========== Top Customers =========== */}
                <div className="row">
                    <div className="col-12">
                        <div className='e-bg-white e-box-shadow-black py-4 border-radius-16px margin-32px-bottom'>
                            {tableLoader === true ?
                                <Loading />
                                :
                                dashboardData.length === 0 ?
                                    <EmptyScreen className="my-5"
                                        title="No data found"
                                        image={require("../../../Assets/Images/loader/empty-funds.png")}
                                        description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" />
                                    :
                                    <Fragment>
                                        <div className="d-flex justify-content-between align-items-center px-4">
                                            <CustomTitle title="Users list" type={2} />
                                            <div className="d-flex align-items-center">
                                                <div className="col-lg-10">
                                                    <SearchInput value={search}
                                                        placeholder="Search with name"
                                                        clear={() => {
                                                            setSearch("");
                                                        }}
                                                        valueChange={_handleSearch}
                                                        handleSearch={(value) => {

                                                        }} />
                                                </div>
                                                <Icon icon={filterEnabled ? "filter-active" : "filter-inactive"}
                                                    size={42}
                                                    className="ms-2 cursor-pointer"
                                                    onClick={_handleToggleFilter} />
                                            </div>
                                        </div>
                                        {
                                            filterEnabled === true &&
                                            <div className="e-bg-light-gray padding-12px-tb padding-32px-lr margin-10px-bottom">
                                                <div className="row">
                                                    <div className="col-2">
                                                        <label className="e-montserrat-medium e-font-14 e-line-height-18 color-dark-charcoal mb-1">Referred by</label>
                                                        <CustomSelect value={referredBy}
                                                            options={REFERRED_OPTIONS}
                                                            selectChange={_handleReferredBy} />
                                                    </div>
                                                    {/* city filter */}
                                                    <div className="col-2">
                                                        <label className="e-montserrat-medium e-font-14 e-line-height-18 color-dark-charcoal mb-1">City</label>
                                                        <CustomSelect value={city}
                                                            options={cityOptions}
                                                            selectChange={_handleCity} />
                                                    </div>
                                                    <div className="col-2">
                                                        <label className="e-montserrat-medium e-font-14 e-line-height-18 color-dark-charcoal mb-1">KYC status</label>
                                                        <CustomSelect value={kyc}
                                                            options={kycOptions}
                                                            selectChange={_handleKycStatus} />
                                                    </div>
                                                    {/* investment status filter */}
                                                    <div className="col-2">
                                                        <label className="e-montserrat-medium e-font-14 e-line-height-18 color-dark-charcoal mb-1">Investment status</label>
                                                        <CustomSelect value={investmentStatus}
                                                            options={investmentStatusOptions}
                                                            selectChange={_handleInvestmentStatus} />
                                                    </div>
                                                </div>
                                                <div className="margin-32px-top d-flex gap-24px">
                                                    <PrimaryButton label="Filter now"
                                                        className="padding-14px-tb padding-38px-lr"
                                                        handleClick={_handleFilter} />


                                                    <SecondaryButton label="Clear"
                                                        className="padding-14px-tb padding-38px-lr"
                                                        handleClick={_handleClear} />

                                                </div>
                                            </div>
                                        }
                                        <div className="mt-4" >
                                            <DataTableContainer data={dashboardData}
                                                columns={TABLE_COLUMNS}
                                                customStyles={TABLE_STYLE}
                                            />
                                        </div>
                                    </Fragment>
                            }
                        </div>
                        {/* <div className='e-bg-white e-box-shadow-black py-4 border-radius-16px'>
                            <div className="d-flex justify-content-between px-4 align-items-center">
                                <CustomTitle title="Top customers opted for rebalance" type={2} />
                                <TextButton name="View all" onPress={_handleNavigation} />
                            </div>
                            <div className="mt-4" >
                                <DataTableContainer data={TABLE_DATA}
                                    columns={TABLE_COLUMNS}
                                    customStyles={TABLE_STYLE} />
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default RMDashboard