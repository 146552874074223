/*
 *   File : list.js
 *   Author : https://evoqins.com
 *   Description : Email Campaign
 *   Version : 1.0.0
*/

// packages
import { useNavigate } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import { Modal } from "bootstrap";

// Custom components
import { CustomTitle } from "../../../Components/Title";
import { DataTableContainer } from "../../../Components/DataTable";
import { Icon } from '../../../Components/Icon';
import { Badge } from "../../../Components/Badge";
import { CustomSelect } from "../../../Components/FormElements";
import { PrimaryButton, TextButton } from "../../../Components/Buttons";
import { CampaignDetailModal } from "../../../Components/Modal";

// Custom styles
import Colors from '../../../Styles/color.module.scss';
import Config from "../../../Helper/config";


// Email DATA
const EMAIL_DATA = [
    {
        id: 1,
        name: "Financial freedom challenge",
        created_on: "12 May 2024",
        scheduled_date: "12 May 2024",
        status: "Sent",
        type: 1,
        subject: "Introduction for mutual fund",
        target_users: "All users",
        description: `<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit totam cumque eaque, temporibus minus harum voluptas mollitia id reiciendis obcaecati est nobis tenetur, vero quibusdam consectetur ut recusandae laudantium labore.</p>`,
        open_rate: 25,
        click_through_rate: 10,
        bounce_rate: 5,
        unsubscribe_rate: 1
    },
    {
        id: 2,
        name: "Refer-a-friend bonus program",
        created_on: "12 May 2024",
        scheduled_date: "12 May 2024",
        status: "Failed",
        type: 4,
        subject: "Introduction for mutual fund",
        target_users: "All users",
        description: `<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit totam cumque eaque, temporibus minus harum voluptas mollitia id reiciendis obcaecati est nobis tenetur, vero quibusdam consectetur ut recusandae laudantium labore.</p>`,
        open_rate: 25,
        click_through_rate: 10,
        bounce_rate: 5,
        unsubscribe_rate: 1
    },
    {
        id: 3,
        name: "Refer-a-friend bonus program",
        created_on: "12 May 2024",
        scheduled_date: "12 May 2024",
        status: "Scheduled",
        type: 3,
        subject: "Introduction for mutual fund",
        target_users: "All users",
        description: `<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit totam cumque eaque, temporibus minus harum voluptas mollitia id reiciendis obcaecati est nobis tenetur, vero quibusdam consectetur ut recusandae laudantium labore.</p>`,
        open_rate: 25,
        click_through_rate: 10,
        bounce_rate: 5,
        unsubscribe_rate: 1
    },
    {
        id: 4,
        name: "Financial freedom challenge",
        created_on: "12 May 2024",
        scheduled_date: "12 May 2024",
        status: "Sent",
        type: 1,
        subject: "Introduction for mutual fund",
        target_users: "All users",
        description: `<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit totam cumque eaque, temporibus minus harum voluptas mollitia id reiciendis obcaecati est nobis tenetur, vero quibusdam consectetur ut recusandae laudantium labore.</p>`,
        open_rate: 25,
        click_through_rate: 10,
        bounce_rate: 5,
        unsubscribe_rate: 1
    },
    {
        id: 5,
        name: "Refer-a-friend bonus program",
        created_on: "12 May 2024",
        scheduled_date: "12 May 2024",
        status: "Failed",
        type: 4,
        subject: "Introduction for mutual fund",
        target_users: "All users",
        description: `<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit totam cumque eaque, temporibus minus harum voluptas mollitia id reiciendis obcaecati est nobis tenetur, vero quibusdam consectetur ut recusandae laudantium labore.</p>`,
        open_rate: 25,
        click_through_rate: 10,
        bounce_rate: 5,
        unsubscribe_rate: 1
    },
    {
        id: 6,
        name: "Refer-a-friend bonus program",
        created_on: "12 May 2024",
        scheduled_date: "12 May 2024",
        status: "Scheduled",
        type: 1,
        subject: "Introduction for mutual fund",
        target_users: "All users",
        description: `<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit totam cumque eaque, temporibus minus harum voluptas mollitia id reiciendis obcaecati est nobis tenetur, vero quibusdam consectetur ut recusandae laudantium labore.</p>`,
        open_rate: 25,
        click_through_rate: 10,
        bounce_rate: 5,
        unsubscribe_rate: 1
    },
    {
        id: 7,
        name: "Financial freedom challenge",
        created_on: "12 May 2024",
        scheduled_date: "12 May 2024",
        status: "Failed",
        type: 4,
        subject: "Introduction for mutual fund",
        target_users: "All users",
        description: `<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit totam cumque eaque, temporibus minus harum voluptas mollitia id reiciendis obcaecati est nobis tenetur, vero quibusdam consectetur ut recusandae laudantium labore.</p>`,
        open_rate: 25,
        click_through_rate: 10,
        bounce_rate: 5,
        unsubscribe_rate: 1
    },
    {
        id: 8,
        name: "Refer-a-friend bonus program",
        created_on: "12 May 2024",
        scheduled_date: "12 May 2024",
        status: "Failed",
        type: 4,
        subject: "Introduction for mutual fund",
        target_users: "All users",
        description: `<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit totam cumque eaque, temporibus minus harum voluptas mollitia id reiciendis obcaecati est nobis tenetur, vero quibusdam consectetur ut recusandae laudantium labore.</p>`,
        open_rate: 25,
        click_through_rate: 10,
        bounce_rate: 5,
        unsubscribe_rate: 1
    },
    {
        id: 9,
        name: "Refer-a-friend bonus program",
        created_on: "12 May 2024",
        scheduled_date: "12 May 2024",
        status: "Scheduled",
        type: 2,
        subject: "Introduction for mutual fund",
        target_users: "All users",
        description: `<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit totam cumque eaque, temporibus minus harum voluptas mollitia id reiciendis obcaecati est nobis tenetur, vero quibusdam consectetur ut recusandae laudantium labore.</p>`,
        open_rate: 25,
        click_through_rate: 10,
        bounce_rate: 5,
        unsubscribe_rate: 1
    }
]

// Table custom style
const TABLE_STYLE = {
    table: {
        style: {
            borderRadius: "0px",
        },
    },

    headRow: {
        style: {
            borderBottomColor: 'transparent',
            paddingLeft: "24px",
            color: Colors.dark_gray,
            fontFamily: "Montserrat-Bold",
            fontSize: "14px",
            lineHeight: "24px",
            paddingTop: "0px",
            paddingBottom: '0px',

        }
    },
    rows: {
        style: {
            paddingLeft: "24px",
            borderBottom: 'none !important',
            '&:nth-child(odd)': {
                backgroundColor: '#0D0D0D05',
            },

        },
    },
    cells: {
        style: {
            paddingTop: '16px',
            paddingBottom: '16px',
            color: Colors.dark_charcoal,
            fontFamily: "Montserrat-Medium",
            fontSize: "14px",
            lineHeight: "24px",
            paddingLeft: "0px",
            cursor: "pointer",
            userSelect: "none",
        }
    },
    headCells: {
        style: {
            paddingLeft: "0px"
        }
    },
    pagination: {
        style: {
            paddingRight: "50px",
            paddingTop: "10px",
            borderTop: "none"
        }
    }
};

const STATUS_OPTIONS = [
    {
        label: "All",
        value: null
    },
    {
        label: "Sent",
        value: 1
    },
    {
        label: "Scheduled",
        value: 2
    },
    {
        label: "Failed",
        value: 3
    },
]

const List = () => {

    const navigate = useNavigate();
    const [toggle, setToggle] = useState(false);
    const [status, setStatus] = useState(STATUS_OPTIONS[0]);
    const [openCampaignModal, setOpenCampaignModal] = useState(null);

    useEffect(() => {
        if (openCampaignModal !== null) {
            const modal = new Modal(document.getElementById("campaign-details-modal"), {});
            modal.show();
        }
    }, [openCampaignModal]);

    function _openCampaignModal(row) {
        console.log("Row", row)
        setOpenCampaignModal(row)
    }

    function _handleToggle() {
        setToggle(!toggle)
    }

    function _handleStatus(select_value) {
        setStatus(select_value)
    }

    function _viewCampaign(row) {
        setOpenCampaignModal(row)
    }

    function _handleEdit(row) {
        navigate("create", {
            state: {
                data: row,
            }
        });
    }

    function _handleModalClose() {
        setOpenCampaignModal(null);
    }

    // User columns
    const EMAIL_COLUMNS = [
        {
            name: "Name",
            selector: row => row.name,
            width: '300px'
        },
        {
            name: "Created date",
            selector: row => row.created_on,
            width: '150px'
        },
        {
            name: "Status",
            selector: row => <Badge status={row.status} color={row.type === 1 ?
                Config.BADGE_COLORS.green :
                row.type === 4 ? Config.BADGE_COLORS.red :
                    Config.BADGE_COLORS.blue} />,
            width: "150px"
        },
        {
            name: "Scheduled date",
            selector: row => row.scheduled_date,
            width: "150px"
        },
        {
            name: '',
            sortable: false,
            width: "180px",
            cell: row => <div className="d-flex gap-16px">
                <TextButton
                    className="e-font-14 e-line-height-24" name="View" hide_arrow={true}
                    onPress={() => _viewCampaign(row)} />
                <TextButton
                    className="e-font-14 e-line-height-24" name="Edit" hide_arrow={true}
                    onPress={() => _handleEdit(row)} />
                <TextButton
                    className="e-font-14 e-line-height-24" name="Cancel" hide_arrow={true}
                    onPress={() => _openCampaignModal(row)} />
            </div>
        },

    ]

    const _handleCreateCampaign = () => {
        navigate("create")
    }


    return (
        <Fragment>
            <div className="e-bg-light-lavender pt-3 padding-52px-lr">
                <CustomTitle title="Email Campaign" type={1} />
            </div>

            <div className="e-body-wrapper">
                <div className="row">
                    <div className="col-12">
                        <div className="e-bg-white border-radius-32px pt-4 padding-40px-bottom">
                            <div className="px-4 mb-3">
                                <div className="d-flex align-items-center justify-content-between flex-wrap gap-24px">

                                    <h4 className='mb-0 e-montserrat-semi-bold e-font-16 e-line-height-24'>
                                        Email campaigns list
                                    </h4>


                                    <div className="d-flex gap-32px align-items-center justify-content-end">
                                        <Icon icon={toggle ? "filter-active" : "filter-inactive"}
                                            size={42}
                                            className="cursor-pointer"
                                            onClick={_handleToggle} />

                                        <PrimaryButton label="Create new campaign"
                                            className="padding-10px-tb padding-18px-lr"
                                            handleClick={_handleCreateCampaign} />
                                    </div>

                                </div>
                            </div>

                            {/* Filter */}
                            {
                                toggle && <div className="e-bg-light-gray padding-12px-tb padding-32px-lr margin-10px-bottom">
                                    <div className="row">
                                        <div className="col-lg-2 col-md-4 col-sm-6 ps-0">
                                            <label className="e-montserrat-medium e-font-14 e-line-height-18 color-dark-charcoal mb-1">Status</label>
                                            <CustomSelect value={status}
                                                options={STATUS_OPTIONS}
                                                selectChange={_handleStatus} />
                                        </div>


                                    </div>
                                </div>
                            }
                            <div className="position-relative">

                                <DataTableContainer
                                    columns={EMAIL_COLUMNS}
                                    data={EMAIL_DATA}
                                    selectableRows={false}
                                    pagination={false}
                                    customStyles={TABLE_STYLE}
                                    rowClick={_openCampaignModal} />

                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {
                openCampaignModal !== null &&
                <CampaignDetailModal data={openCampaignModal}
                    close={_handleModalClose} />
            }

        </Fragment >
    )
}
export default List;