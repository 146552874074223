/*
 *   File : header.js
 *   Author : https://evoqins.com
 *   Description : Header component
 *   Integrations : js-cookie
 *   Version : 1.0.0
*/

// packages
import React, { memo, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

// Custom components
import { Icon } from '../Icon';
import { ChangePassword } from '../Modal';


import store from '../../Store';

import { _logout, useClickOutside } from '../../Helper/helper';
import { useLocation, useNavigate } from 'react-router-dom';

const CustomHeader = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const SIDEBAR_TOGGLE = useSelector(state => state.Reducer.toggle);
    const COUNT = useSelector(state => state.Reducer.NOTIFICATION_COUNT);
    const dropdownRef = useRef(false);
    const [innerWidth, setInnerWidth] = useState(null);
    const [showDropdown, setShowDropdown] = useState(false);
    const [changePassword, setChangePassword] = useState(false);


    useClickOutside(dropdownRef, () => {
        setShowDropdown(false);
    })



    useEffect(() => {
        // Update browser zoom level
        function windowSize() {
            setInnerWidth(window.innerWidth)
        }


        windowSize();
        window.addEventListener("resize", windowSize);
        return () => {
            window.removeEventListener("resize", windowSize);
        };
    }, []);

    function _handleSideBar() {
        if (innerWidth > 992) {
            store.dispatch({
                type: 'TOGGLE_SIDEBAR', payload: !SIDEBAR_TOGGLE
            });
        } else {
            store.dispatch({
                type: 'TOGGLE_SIDEBAR', payload: false
            })
        }
    }

    function _handleDropdown() {
        setShowDropdown(!showDropdown)
    }

    function _changePassword(status) {
        setChangePassword(status)
    }

    return (
        <div className={`e-bg-light-lavender padding-36px-tb`}>
            <div className='d-flex justify-content-between'>
                <div className='ps-3'>
                    <Icon icon="hamburger"
                        size={"24px"}
                        className='cursor-pointer'
                        onClick={_handleSideBar} />
                </div>

                <div className='d-flex justify-content-end pe-4 align-items-center'>
                    <div className='position-relative cursor-pointer margin-32px-right' onClick={() => {
                        if (location.pathname !== "/all-notifications") {
                            navigate("/all-notifications")
                        } else {
                            window.location.reload();
                        }
                    }}>
                        <Icon icon="notification"
                            size={"24px"} />
                        {COUNT > 0 && <span className="position-absolute top-8px left-16px translate-middle rounded-pill e-bg-crimpson-red e-font-10 e-line-height-12 color-white e-montserrat-bold w-24px h-24px padding-1px-top d-flex align-items-center justify-content-center">
                            {COUNT > 99 ? "99+" : COUNT}
                        </span>}
                    </div>

                    <div ref={dropdownRef} className='e-dropdown-more-wrapper position-relative' >
                        <Icon icon="menu"
                            size={"24px"}
                            className="cursor-pointer"
                            onClick={_handleDropdown} />
                        {
                            showDropdown === true &&
                            <div className='e-box-shadow-tooltip p-3 border-radius-8px position-absolute end-0 top-32px bg-white e-font-14 e-line-height-18 e-montserrat-medium color-dark-charcaol w-max-content cursor-pointer'>
                                <p className='mb-3' onClick={() => {
                                    navigate("/profile");
                                    setShowDropdown(false);
                                }}>Profile & Change password</p>
                                <p className='mb-0' onClick={_logout}>Logout</p>
                            </div>
                        }

                    </div>
                </div>
            </div>

            {
                changePassword === true &&
                <ChangePassword closeModal={() => _changePassword(false)} />
            }


        </div>
    )
}

export default memo(CustomHeader);