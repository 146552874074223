/*
 *   File : privacy.js
 *   Author URI : www.evoqins.com
 *   Description : Container page for privacy policy
 *   Integrations : null
 *   Version : v1.0
 *   Created : 
 */

// Packages
import { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

// Components
import { CustomTitle } from "../../../../Components/Title";
import { CustomTabBar } from "../../../../Components/Tab";
import { PrimaryButton } from "../../../../Components/Buttons";
import { CustomWysiwygEditor } from "../../../../Components/FormElements";
import { EmptyScreen } from "../../../../Components/Others";

// Services
import { _listContent, _manageContent } from "../../../../Helper/api";

const TAB_OPTIONS = [
    {
        label: "Banner images",
        id: 1
    },
    {
        label: "FAQ",
        id: 2
    },
    {
        label: "Terms & conditions",
        id: 3
    },
    {
        label: "Privacy policy",
        id: 4
    },
    {
        label: "Disclaimer",
        id: 5
    },
    {
        label: "Value pair setting",
        id: 6
    },
    {
        label: "User category settings",
        id: 7
    }
]

const PrivacyPolicy = () => {

    const navigate = useNavigate();
    const [tabIndex, setTabIndex] = useState(TAB_OPTIONS[3].id);
    const [editorStateError, setEditorStateError] = useState("");
    const [showEditScreen, setShowEditScreen] = useState(false);

    const [pageLoader, setPageLoader] = useState(true);
    const [apiLoader, setApiLoader] = useState(false);
    const [privacy, setPrivacy] = useState("");

    useEffect(() => {
        _listPrivacy();
    }, []);


    function _handleTab(tab_index) {
        setTabIndex(tab_index);
        switch (tab_index) {
            case 1:
                navigate('/app-settings');
                break;
            case 2:
                navigate('/app-settings/faq');
                break;
            case 3:
                navigate('/app-settings/terms-conditions');
                break;
            case 4:
                navigate('/app-settings/privacy-policy');
                break;
            case 5:
                navigate('/app-settings/disclaimer');
                break;
            case 6:
                navigate('/app-settings/value-pair-setting');
                break;
            case 7:
                navigate('/app-settings/user-category-setting');
                break;

            default:
                break;
        }
    }

    const _handleEdit = () => {
        setShowEditScreen(!showEditScreen);
    }

    // handle editor change
    const _handleEditor = (value) => {
        setPrivacy(value);
        setEditorStateError("");
    };


    // API - get privacy 
    const _listPrivacy = async () => {
        const request = {
            "type": "PRIVACY"
        }

        await _listContent(request).then((response) => {
            setPrivacy(response.data.text);
            setPageLoader(false);
        }).catch((error) => {
            setPrivacy("");
            setPageLoader(false);
        })
    }

    // API - manage terms
    const _managePrivacy = async () => {
        setApiLoader(true);
        const request = {
            "type": "PRIVACY",
            "text": privacy
        }

        await _manageContent(request).then((response) => {
            toast.dismiss();
            toast.success("Privacy content updated successfully", {
                type: 'success'
            });
            setApiLoader(false);
            setShowEditScreen(false);
        }).catch((error) => {
            setApiLoader(false)
        })
    }
    return (
        <Fragment>
            <div className="e-bg-light-lavender pt-3 padding-52px-lr">
                <CustomTitle title="App Settings" type={1} />
            </div>


            <div className="e-body-wrapper">
                <div className="bg-white border-radius-32px padding-40px-tb ">
                    <div className="row padding-32px-lr">
                        <div className="col-12">
                            <CustomTabBar data={TAB_OPTIONS}
                                selectedTab={tabIndex}
                                onSelectTab={_handleTab} />
                        </div>
                        {
                            pageLoader === true ?
                                <div className="h-80vh d-flex align-items-center justify-content-center">
                                    <img src={require("../../../../Assets/Images/loader/page-loader.gif")}
                                        alt={"loader"}
                                        width={60}
                                        height={60}
                                    />
                                </div>
                                :
                                <div className="col-12 padding-32px-top margin-22px-bottom">
                                    <div className="d-flex justify-content-between align-items-center mb-4">
                                        <h4 className="e-montserrat-semi-bold e-font-16 e-line-height-20 color-dark-charcoal mb-0">
                                            Privacy policy
                                        </h4>
                                        {
                                            showEditScreen === true ?
                                                <PrimaryButton label="Submit"
                                                    className="padding-10px-tb px-4 "
                                                    disabled={privacy.length === 0 ? true : apiLoader}
                                                    loader={apiLoader}
                                                    handleClick={_managePrivacy} />
                                                :
                                                <PrimaryButton label={privacy.length === 0 ? "Add" : "Edit"}
                                                    className="padding-10px-tb padding-32px-lr bg-white color-rebecca-purple border-rebecca-purple border-radius-8px"
                                                    handleClick={_handleEdit} />
                                        }
                                    </div>

                                    {
                                        showEditScreen === true ?
                                            <CustomWysiwygEditor value={privacy}
                                                label=""
                                                error={editorStateError} // Pass any additional props as needed
                                                onEditorStateChange={_handleEditor} />
                                            :
                                            privacy === "" ?
                                                <EmptyScreen className="my-5"
                                                    title="No Privacy added"
                                                    image={require("../../../../Assets/Images/loader/empty-funds.png")}
                                                    description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" />
                                                :
                                                <div className="e-bg-pastel-lavender border-radius-12px px-4 pb-2 pt-4 e-montserrat-medium">
                                                    <div dangerouslySetInnerHTML={{ __html: privacy }} />
                                                </div>
                                    }
                                </div>
                        }
                    </div>
                </div>
            </div>

        </Fragment>
    )
}
export default PrivacyPolicy;