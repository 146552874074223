/*
 *   File : create-campaign.js
 *   Author : https://evoqins.com
 *   Description : Container screen to add or edit email campaigns.
 *   Version : 1.0.0
*/

import { Fragment, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// import { CustomSelect, CustomTextInput1, EditorComponent, CustomTimePicker } from "../../../Components/FormElements";
import { CustomSelect, CustomTextInput1, CustomWysiwygEditor, CustomTimePicker } from "../../../Components/FormElements";
import { Breadcrumb, CustomCalendar } from "../../../Components/Others";
import { PrimaryButton, SecondaryButton } from "../../../Components/Buttons";

import moment from "moment";

const TARGET_USER_BASKET = [
    {
        label: "Image-text",
        value: 1
    },
    {
        label: "Video",
        value: 2
    }
]

const CreateCampaign = () => {

    const location = useLocation();
    const navigate = useNavigate();

    const emailRef = useRef(false);
    const targetRef = useRef(false);
    const contentRef = useRef(false);
    const dateRef = useRef(false);
    const timeRef = useRef(false);

    const [emailSubject, setEmailSubject] = useState("");
    const [emailSubjectError, setEmailSubjectError] = useState("");
    const [targetUsers, setTargetUsers] = useState(null);
    const [targetUsersError, setTargetUsersError] = useState("");
    const [editorState, setEditorState] = useState(location.state !== null ? location.state.data.description : "");
    const [editorStateError, setEditorStateError] = useState("");
    const [scheduledDate, setScheduledDate] = useState("DD-MM-YYYY");
    const [scheduledDateError, setScheduledDateError] = useState("");
    const [selectedTime, setSelectedTime] = useState(moment());
    const [selectedTimeError, setSelectedTimeError] = useState("");
    const disabledDays = { before: new Date() };

    useEffect(() => {
        if (location.state !== null) {
            setEmailSubject(location.state.data.subject);
            setTargetUsers(location.state.data.target_users);
            setScheduledDate(moment(location.state.data.scheduled_date, "DD MMM YYYY").format("DD/MM/YYYY"));
        }
    }, [location.state]);

    useEffect(() => {
        console.log("editorState", editorState);
    }, [editorState])

    const _handleNavigate = () => {
        navigate(-1);
    }

    const _handleEmailSubject = (emailSubject) => {
        setEmailSubject(emailSubject);
        setEmailSubjectError("");
    }

    function _handleTargetUsers(select_value) {
        setTargetUsers(select_value)
        setTargetUsersError("");
    }

    const _handleEditor = (value) => {
        setEditorState(value);
        setEditorStateError("");
    };

    function _handleDate(selected_date) {
        console.log("date selected", selected_date);
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };

        const start_date = new Date(selected_date);
        const formatted_start_date = start_date.toLocaleDateString('en-GB', options).replace(/\//g, '/');
        setScheduledDate(formatted_start_date);
        setScheduledDateError("");
    }

    const _handleTimeChange = (newTime) => {
        setSelectedTime(newTime);
        setSelectedTimeError("");
        console.log("Selected Time:", newTime);
        // You can perform any other actions with the selected time here
    };

    const _handleCreate = () => {
        let valid = true;
        if (selectedTime === null) {
            timeRef.current.scrollIntoView({ block: 'start' });
            setSelectedTimeError("Time is required");
            valid = false;
        }
        if (scheduledDate === "dd/mm/yyyy") {
            dateRef.current.scrollIntoView({ block: 'start' });
            setScheduledDateError("Select date");
            valid = false;
        }
        if (selectedTime === null) {
            timeRef.current.scrollIntoView({ block: 'start' });
            valid = false;
            setSelectedTimeError("Enter time");
        }
        if (editorState.length === 0) {
            contentRef.current.scrollIntoView({ block: 'start' });
            valid = false;
            setEditorStateError("Enter email content");
        }
        if (targetUsers === null) {
            targetRef.current.scrollIntoView({ block: 'start' });
            valid = false;
            setTargetUsersError("Select target users");
        }
        if (emailSubject.length === 0) {
            emailRef.current.scrollIntoView({ block: 'start' });
            setEmailSubjectError("Enter subject")
            valid = false
        }


        if (valid === true) {
            navigate("/email-campaign");
        }
    }

    const _handleCancel = () => {
        navigate(-1);
    }

    return (
        <Fragment>
            <div className="e-bg-light-lavender pt-3 padding-52px-lr">
                <div className="padding-110px-bottom">
                    <Breadcrumb handleNavigate={_handleNavigate}
                        mainTitle="Manage email campaigns"
                        subTitle="Create new campaign" />
                </div>
            </div>
            <div className="e-body-wrapper">
                <div className="row ">
                    <div className="col-12">
                        <div className="e-bg-white border-radius-32px padding-40px-tb padding-32px-lr">

                            <p className="e-montserrat-semi-bold e-font-16 e-line-height-20 color-dark-charcoal mb-4">Add new email campaign </p>
                            <div className="row">
                                <div className="col-md-8" ref={emailRef}>
                                    <CustomTextInput1 label="Email subject"
                                        type='text'
                                        id="emailSubject"
                                        className='mb-4'
                                        postfix="*"
                                        placeHolder="Enter email subject"
                                        error={emailSubjectError}
                                        value={emailSubject}
                                        handleChange={_handleEmailSubject} />
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-6" ref={targetRef}>
                                    <CustomSelect type={3}
                                        postFix={true}
                                        placeholder="Select target user baskets"
                                        label="Target user baskets"
                                        error={targetUsersError}
                                        value={targetUsers}
                                        options={TARGET_USER_BASKET}
                                        selectChange={_handleTargetUsers} />

                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="col-md-8" ref={contentRef}>
                                    <CustomWysiwygEditor value={editorState}
                                        label="Upload email content"
                                        postfix="*"
                                        error={editorStateError} // Pass any additional props as needed
                                        onEditorStateChange={_handleEditor}
                                    />
                                </div>
                            </div>

                            <div className="row ">
                                <div className="col-lg-4 col-md-6 pb-md-0 pb-4" ref={dateRef}>
                                    <CustomCalendar error={scheduledDateError}
                                        disabledDays={disabledDays}
                                        label="Schedule date"
                                        postfix={true}
                                        start={scheduledDate}
                                        mode="single"
                                        selectDate={_handleDate} />
                                </div>
                                <div className="col-lg-4 col-md-6" ref={timeRef}>
                                    <CustomTimePicker label="Schedule time"
                                        error={selectedTimeError}
                                        postFix={true}
                                        time={selectedTime}
                                        onTimeChange={_handleTimeChange} />
                                </div>
                            </div>
                            <div className="row margin-40px-top">
                                <div className="col-lg-8">
                                    <div className="d-flex justify-content-end gap-16px">
                                        <SecondaryButton label="Cancel"
                                            className="padding-14px-tb padding-50px-lr"
                                            handleClick={_handleCancel} />
                                        <PrimaryButton label="Create"
                                            className="padding-14px-tb padding-56px-lr"
                                            handleClick={_handleCreate} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>

    )
}

export default CreateCampaign
