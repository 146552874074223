/*
 *   File : empty-screen.js
 *   Author : https://evoqins.com
 *   Description : Empty screen
 *   Version : 1.0.0
*/

import NoData from '../../Assets/Images/loader/empty-funds.png'

const EmptyScreen = ({
    width,
    className = '',
    image = NoData,
    title = 'No Data Available',
    description = 'There is no data to display at the moment.'
}) => {
    return (
        <div className={`row d-flex flex-column justify-content-center align-items-center ${className}`}>
            <div className="col-6 text-center">
                <img src={image}
                    alt={title}
                    draggable={false}
                    width={width ? width : 250}
                />
                <p className="e-montserrat-semi-bold e-font-18 e-line-height-32 color-charleston-green mt-2 mb-1">{title}</p>
                {/* <p className="e-montserrat-regular e-font-16 e-line-height-28 color-dark-grayish-blue mb-0">{description}</p> */}
            </div>
        </div>
    )
}

export default EmptyScreen;