/*
 *   File : settings.js
 *   Author URI : www.evoqins.com
 *   Description : Container screen to list available settings.
 *   Integrations : null
 *   Version : v1.0
 *   Created : 
 */

// Packages
import { Fragment, useEffect, useState } from "react";
import { Modal } from "bootstrap";

// Custom components
import { PrimaryButton } from "../../../Components/Buttons";
import { ListBanners } from ".";
import { ManageBannerModal } from "../../../Components/Modal";

// import services
import APIService from "../../../Services/api-service";


const Settings = () => {

    const [bannerDetail, setBannerDetail] = useState(null);
    const [openBannerModal, setOpenBannerModal] = useState(false);

    const [pageLoader, setPageLoader] = useState(true);
    const [bannerList, setBannerList] = useState([]);

    const [bannerOptions, setBannerOptions] = useState([]);
    const [navTypeOptions, setNavTypeOptions] = useState([]);
    const [resourceOptions, setResourceOptions] = useState([]);
    const [goalOptions, setGoalOptions] = useState([]);

    useEffect(() => {
        _getBannerList();
        _getBannerFilter();
        _getResources();
        _getGoals();
    }, []);


    useEffect(() => {
        if (openBannerModal === true) {
            const modal = new Modal(document.getElementById("manage-banner"));
            modal.show();
        }
    }, [openBannerModal])


    function _handleBannerModal(status, detail) {
        setOpenBannerModal(status);
        setBannerDetail(detail);
    }

    // API - banner list
    const _getBannerList = () => {
        const url = "/banner/list";
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setBannerList(response.data);
            } else {
                setBannerList([])
            }
            setPageLoader(false);
        })
    }

    // API - Banner filter 
    const _getBannerFilter = async () => {
        const url = "/banner/filter-data";

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setBannerOptions(response.data.banner_type);
                setNavTypeOptions(response.data.nav_type);
            }
        });
    }

    // API - get resources
    const _getResources = () => {
        const url = "/banner/get-resource";

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setResourceOptions(response.data);
            }
        });
    }

    // API - get goals
    const _getGoals = () => {
        const url = "/banner/get-goals";

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setGoalOptions(response.data);
            }
        });
    }


    return (
        <Fragment>
            <div className="d-flex justify-content-between align-items-center px-4 mb-3">
                <h4 className="e-montserrat-semi-bold e-font-16 e-line-height-20 color-dark-charcoal mb-0">
                    Banner images
                </h4>
                <PrimaryButton
                    label="Add banner"
                    className="padding-10px-tb px-4"
                    handleClick={() => _handleBannerModal(true, null)} />
            </div>

            {
                pageLoader === true ?
                    <div className="h-80vh d-flex align-items-center justify-content-center">
                        <img src={require("../../../Assets/Images/loader/page-loader.gif")}
                            alt={"loader"}
                            width={60}
                            height={60}
                        />
                    </div>
                    :
                    <ListBanners bannerList={bannerList}
                        handleEdit={(row) => _handleBannerModal(true, row)}
                        updateList={_getBannerList} />
            }


            {
                openBannerModal === true && <ManageBannerModal bannerOptions={bannerOptions}
                    navTypeOptions={navTypeOptions}
                    resourceOptions={resourceOptions}
                    goalOptions={goalOptions}
                    banner={bannerDetail}
                    closeModal={() => _handleBannerModal(false, null)}
                    bannerUpdated={() => {
                        _getBannerList();
                        _handleBannerModal(false, null)
                    }} />
            }

        </Fragment >
    )
}

export default Settings