/*
 *   File : secondary.js
 *   Author URI : https://evoqins.com
 *   Description : Secondary button for site
 *   Integrations : null
 *   Version : 1
 */

import style from "../../Styles/Components/primary.module.scss";

const SecondaryButton = (props) => {

    return (
        // secondary button
        <button
            className={`e-montserrat-semi-bold e-font-14 e-line-height-20 e-bg-lavender-gray color-rebecca-purple border-radius-14px border-0 text-nowrap ${props.className}
                ${props.disabled === false ?
                    `${style.e_active_btn}  cursor-pointer`
                    :
                    `${style.e_inactive_btn} `
                }`}

            id={props.id}
            type={props.type ? props.type : "submit"}
            style={{ width: props.type === 1 ? "100%" : null }}
            data-bs-toggle={props.toggle}
            data-loading={`${props.loading}`}
            data-bs-target={props.target}
            data-bs-dismiss={props.cancel}
            onClick={props.handleClick}>
            <span className="position-relative d-flex justify-content-center">
                {/* {
                    props.icon ?
                        <Icon icon={props.icon}
                            size={24}
                            className='me-2' />
                        :
                        null
                } */}
                {props.label ? props.label : "Submit"}
                {/* {
                    props.loading === true && (
                        <img src={require('../../Assets/Images/mt-wealth-button-loader.gif')}
                            className={style.loader_gif} />
                    )
                } */}
            </span>
        </button>
    );
};

SecondaryButton.defaultProps = {
    onPress: () => { },
    className: '',
    cancel: null,
    toggle: null,
    target: null,
    disabled: false,
    loading: false,
    type: '',
    id: null,
    label: "",
    handleClick: () => { }
}

export default SecondaryButton;
