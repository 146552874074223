/*
 *   File : list.js
 *   Author : https://evoqins.com
 *   Description : Portfolio list
 *   Version : 1.0.0
*/

// Packages
import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Components
import { CustomTitle } from "../../../Components/Title";
import { CustomTabBar } from "../../../Components/Tab";


// Service
import { ModelPortfolioListing } from "./ModelPortfolio";
import { CustomPortfolioListing } from "./CustomPortfolio";


const TAB_OPTIONS = [
    {
        label: "Model portfolio",
        id: 1
    },
    {
        label: "Custom portfolio",
        id: 2
    },
]


const ManagePortfolios = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [tabIndex, setTabIndex] = useState(TAB_OPTIONS[0].id);

    useEffect(() => {
        const handleNavigation = () => {
            // This function will be called on navigation changes
            document.getElementById("close-modal").dispatchEvent(new Event("click"));
        };

        // Add event listener for the 'popstate' event
        window.addEventListener('popstate', handleNavigation);

        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener('popstate', handleNavigation);
        };

    }, []);

    useEffect(() => {

        if (location.state !== null) {
            setTabIndex(location.state.index);
        } else {
            setTabIndex(1);
        }
        // eslint-disable-next-line
    }, [location.state]);

    function _handleTab(tab_index) {
        setTabIndex(tab_index);
        navigate('/manage-portfolio', { state: { index: tab_index, tab_index: location.state ? location.state.tab_index : 1, basket: location.state ? location.state.basket : 1 } }, { replace: true })
    }

    return (
        <Fragment>
            <div className="e-bg-light-lavender pt-3 padding-52px-lr">
                <CustomTitle title="Manage Portfolios" type={1} />
            </div>
            <div className="e-body-wrapper">
                <div className="bg-white border-radius-32px padding-40px-tb padding-32px-lr">
                    <div className="row">
                        <div className="col-12">
                            <CustomTabBar data={TAB_OPTIONS}
                                selectedTab={tabIndex}
                                onSelectTab={_handleTab} />
                        </div>
                        {
                            tabIndex === 1 ?
                                <ModelPortfolioListing />
                                :
                                <CustomPortfolioListing />
                        }
                    </div>

                </div>
            </div>

        </Fragment >
    )
}

export default ManagePortfolios