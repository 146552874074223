/*
 *   File : all-notifications.js
 *   Author : https://evoqins.com
 *   Description : Notification list
 *   Version : 1.0.0
*/

// Packages
import { Fragment, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";

// Components
import { TextButton } from "../../../Components/Buttons"
import { CustomTitle } from "../../../Components/Title"
import { NotificationCard } from "../../../Components/Cards"
import { EmptyScreen } from "../../../Components/Others"
import { Icon } from "../../../Components/Icon"

// Services
import APIService from "../../../Services/api-service";
import { _getNotificationCount } from "../../../Helper/api";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const NextIcon = () => {
    return (
        <Icon icon='pagination-next'
            width="24px"
            height="24px"
        />
    )
}

const PrevIcon = () => {
    return (
        <Icon icon='pagination-previous'
            width="24px"
            height="24px"

        />
    )
}

const BreakLabel = () => {
    return (
        <span>...</span>
    )
}


const AllNotificationsSupport = () => {
    const navigate = useNavigate();

    const COUNT = useSelector(state => state.Reducer.NOTIFICATION_COUNT);

    const [pageLoader, setPageLoader] = useState(true);
    const [pageCount, setPageCount] = useState(0); //total number of pages


    const [currentPage, setCurrentPage] = useState(1);
    const [notificationList, setNotificationList] = useState([]);

    useEffect(() => {
        _getReceivedNotifications();
        //eslint-disable-next-line
    }, [currentPage]);

    function _navigateTarget(obj) {
        let type = obj.notification_type
        switch (type) {
            case 1:
                navigate("/customer/detail", {
                    state: {
                        id: obj.data_id
                    }
                })
                break;
            case 2:
                navigate("/orders/detail", {
                    state: {
                        id: obj.data_id,
                        page: 1,
                        selectedPaymentStatus: { label: "All", value: null },
                        selectedOrderStatus: { label: "All", value: null },
                        selectedOrderType: { label: "All", value: null },
                        selectedMilestone: { label: "All", value: null },
                        fromDate: null,
                        endDate: null,
                        amountFrom: null,
                        amountTo: null,
                        search: ""
                    }
                })
                break;

            default:
                break;
        }
    }

    // Handle page change
    function _handlePageChange(pageNumber) {
        window.scrollTo(0, 0)
        setCurrentPage(pageNumber + 1);
    };

    function _handleNavigate(obj, index) {
        if (obj.is_read === true) {
            _navigateTarget(obj)
        } else {
            _readNotification(obj, index)
        }
    }

    // API - get received notifications
    const _getReceivedNotifications = () => {
        const url = "/notification/list-received-notification";

        const request = {
            page_num: currentPage
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setNotificationList(response.data.notifications);
                setPageCount(response.data.total_page);
            }
            setPageLoader(false);
        })
    }




    // API - read notification
    const _readNotification = (notification, index) => {
        const url = "/notification/read";

        const request = {
            notification_id: notification.notification_id
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {

                const notification_list = [...notificationList]
                notification_list[index].is_read = !notification.is_read;
                setNotificationList(notification_list);
                _navigateTarget(notification);
                _getNotificationCount();
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: 'error'
                });
            }
        });
    }

    // API - read notification
    const _readAllNotification = (notification) => {
        const url = "/notification/read-all";
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {

                setPageLoader(true);
                _getReceivedNotifications();

                _getNotificationCount();
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: 'error'
                });
            }
        });
    }


    return (
        <Fragment>
            <div className="e-bg-light-lavender pt-3 padding-52px-lr">
                <CustomTitle title="Notification" type={1} />
            </div>
            <div className="e-body-wrapper">
                <div className="row">
                    <div className="col-12">
                        <div className="bg-white border-radius-32px padding-40px-tb padding-32px-lr">
                            <div className="d-flex justify-content-between align-items-center ">
                                <h4 className="e-font-16 e-line-height-20 e-montserrat-semi-bold color-dark-charcoal">
                                    All notifications
                                </h4>
                                {COUNT > 0 && <TextButton name="Mark all as read"
                                    hide_arrow={true}
                                    onPress={_readAllNotification} />}
                            </div>
                            <div className="row margin-32px-top">
                                <div className="col-11">
                                    {
                                        pageLoader === true ?
                                            <div className="h-80vh d-flex align-items-center justify-content-center">
                                                <img src={require("../../../Assets/Images/loader/page-loader.gif")}
                                                    alt={"loader"}
                                                    width={60}
                                                    height={60}
                                                />
                                            </div>
                                            :
                                            notificationList.length === 0 ?
                                                <EmptyScreen className="my-5"
                                                    title="No notification found"
                                                    image={require("../../../Assets/Images/loader/empty-funds.png")}
                                                    description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" />
                                                :
                                                <Fragment>
                                                    {notificationList.map((item, index) => {
                                                        return (
                                                            <NotificationCard data={item}
                                                                key={index}
                                                                readNotification={() => _handleNavigate(item, index)} />
                                                        )
                                                    })}
                                                    <ReactPaginate previousLabel={<PrevIcon />}
                                                        nextLabel={<NextIcon />}
                                                        breakLabel={<BreakLabel />}
                                                        pageCount={pageCount}
                                                        pageRangeDisplayed={2}
                                                        marginPagesDisplayed={1}
                                                        onPageChange={(value) => _handlePageChange(value.selected)}
                                                        renderOnZeroPageCount={null}
                                                        forcePage={currentPage - 1}
                                                        containerClassName="e-pagination mt-5"
                                                        activeClassName="e-pagination-active" />
                                                </Fragment>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default AllNotificationsSupport