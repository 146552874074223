/*
 *   File : search-input.js
 *   Author URI : www.evoqins.com
 *   Description : Search input
 *   Integrations : null
 *   Version : v1.0
 *   Created : 
 */

/* packages */
import React, { Fragment, memo, useEffect, useState } from "react";

// Custom components
import { Icon } from "../Icon";

// Custom styles 
import styles from "../../Styles/Components/search.module.scss";

const SearchInput = (props) => {
    const [value, setValue] = useState(props.value);

    useEffect(() => {
        setValue(props.value);
    }, [props.value])

    // onchange value
    const _handleChange = (event) => {
        const input_value = event.target.value;
        // Check if acceptNumber prop is true and the input is not a number
        if (props.acceptNumber && isNaN(input_value)) {
            return; // Do nothing if not a number
        }
        setValue(input_value);
        props.valueChange(input_value);
    }

    //on press of enter
    const _handleSearch = (event) => {
        const value = event.target.value;
        if (event.keyCode === 13) {
            props.handleSearch(value);
        }
    }

    return (
        <Fragment>
            <div className={`${styles.e_search_input}  w-100 position-relative ${props.divClass}`}>
                {
                    value.length !== 0 ?
                        <>
                            <span className={`position-absolute top-0 bottom-0 right-0px ${styles.e_submit} h-100 p-2 d-flex align-items-center cursor-pointer`}
                                onClick={() => props.handleSearch(value)}>
                                <Icon icon="arrow-right-white"
                                    size={18}
                                    className="w-100 my-auto" />
                            </span>
                            <Icon icon="close"
                                size={16}
                                className="position-absolute top-0 bottom-0 right-40px my-auto cursor-pointer"
                                onClick={() => props.clear("")} />
                        </>
                        :
                        null
                }

                <Icon icon="search"
                    size={16}
                    className="position-absolute top-0 bottom-0 left-16px cursor-pointer my-auto" />

                <input type="text"
                    id={props.id}
                    spellCheck="false"
                    autoComplete="off"
                    placeholder={props.placeholder}
                    value={value}
                    onFocus={() => props.onClick()}
                    className={`${props.className} py-2 border-color-stroke-gray`}
                    onChange={_handleChange}
                    onKeyDown={(e) => _handleSearch(e)} />
            </div>
        </Fragment>
    );
};

SearchInput.defaultProps = {
    valueChange: () => { },
    id: 1,
    value: "",
    acceptNumber: false,
    placeholder: "Search",
    className: '',
    onClick: () => { },
    handleSearch: () => { },
    clear: () => { }
}

export default memo(SearchInput);