/*
 *   File : primary.js
 *   Author URI : https://evoqins.com
 *   Description : Primary button for site
 *   Integrations : null
 *   Version : 1
 */

import style from "../../Styles/Components/primary.module.scss";
// import { Icon } from "../Icon";

const PrimaryButton = (props) => {

    return (
        // primary button
        <button
            className={`e-montserrat-semi-bold e-font-14 e-line-height-20 e-bg-rebecca-purple color-white border-radius-14px border-0 text-nowrap ${props.className}
        ${props.disabled === false ?
                    `${style.e_active_btn}  cursor-pointer`
                    :
                    `${style.e_inactive_btn} `
                }`}

            id={props.id}
            type={props.type ? props.type : "submit"}
            style={{ width: props.type === 1 ? "100%" : null }}
            data-bs-toggle={props.toggle}
            data-loading={`${props.loading}`}
            data-bs-target={props.target}
            data-bs-dismiss={props.cancel}
            onClick={props.handleClick}
            tabIndex="-1"
        >
            {
                props.loader === true ?
                    <img src={require("../../Assets/Images/loader/btn-loader.gif")}
                        alt={"loader"}
                        height={18} />

                    : <span className="position-relative d-flex justify-content-center">

                        {props.label ? props.label : "Submit"}

                    </span>}
        </button>
    );
};

PrimaryButton.defaultProps = {
    onPress: () => { },
    className: '',
    cancel: null,
    toggle: null,
    target: null,
    disabled: false,
    loading: false,
    type: '',
    id: null,
    label: "",
    handleClick: () => { }
}

export default PrimaryButton;
