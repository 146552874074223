
/*
 *   File : view-portfolio.js
 *   Author URI : https://evoqins.com
 *   Description : The modal component used for showing portfolio fund details.
 *   Integrations : null
 *   Version : v1.1
 */

// Packages
import { Fragment, memo, useEffect } from "react"


// Custom components
import { Icon } from "../Icon";

// Custom styles
import style from "../../Styles/Components/modal.module.scss";

const PortfolioDetailModal = (props) => {

    useEffect(() => {
        var my_modal = document.getElementById("portfolio-detail");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
        });
        //eslint-disable-next-line
    }, [props.closeModal]);

    return (
        <Fragment>
            <div className={`modal fade ${style.e_modal}`}
                id="portfolio-detail"
                tabIndex="-1"
                aria-labelledby="portfolio-detail"
                aria-hidden="true"
                data-bs-backdrop="true" >
                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                    <div className={`modal-content padding-32px-all ${style.e_content} `}>
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <h5 className="e-font-16 e-line-height-24 e-montserrat-semi-bold color-dark-charcoal mb-0">
                                Portfolio details
                            </h5>
                            <Icon icon="close"
                                size={18}
                                id="close-modal"
                                data-bs-dismiss="modal"
                                className='cursor-pointer' />
                        </div>
                        <div className="row pb-2 pt-4">
                            <div className="col-8">
                                <h6 className="e-font-14 e-line-height-24 color-dark-gray e-montserrat-bold mb-0">Fund name</h6>
                            </div>
                            <div className="col-4 text-end">
                                <h6 className="e-font-14 e-line-height-24 color-dark-gray e-montserrat-bold mb-0">Allocation</h6>
                            </div>
                        </div>

                        {
                            Object.entries(props.funds).map(([key, funds]) => {
                                return (
                                    funds.map((item) => {
                                        return (
                                            <div className="row pb-3">
                                                <div className="col-8">
                                                    <span className="e-font-12 e-line-height-18 color-dark-charcoal e-montserrat-medium">{item.fund_name}</span>
                                                </div>
                                                <div className="col-4 text-end">
                                                    <span className="e-font-14 e-line-height-20 color-dark-charcoal e-montserrat-semi-bold">{item.allocation}%</span>
                                                </div>
                                            </div>
                                        )
                                    })
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default memo(PortfolioDetailModal);
