/*
 *   File : footer.js
 *   Author URI : https://evoqins.com
 *   Description : footer for site
 *   Integrations : null
 *   Version : 1
 */

import React from "react";

const Footer = React.memo((props) => {

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    return (
        <div className="e-bg-footer">
            <div className="padding-52px-all d-flex justify-content-between align-items-center">
                <p className="color-dark-charcoal e-montserrat-medium e-font-12 e-line-height-20 mb-0 ">
                    {currentYear} ©  MINTIT All Rights Reserved
                </p>

            </div>
        </div>
    )
})

export default Footer