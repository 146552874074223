// store.js

import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './Reducers';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './Saga';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware().concat(sagaMiddleware);
    },
});

sagaMiddleware.run(rootSaga);

export default store;
