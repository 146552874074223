/*
 *   File : manage-banner.js
 *   Author URI : https://evoqins.com
 *   Description : The modal component used for creating or updating banner.
 *   Integrations : null
 *   Version : v1.1
 */


import { Fragment, memo, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

// Custom components
import { Icon } from "../Icon";
import { CustomFileInput, CustomSelect, CustomTextInput1 } from "../FormElements";
import { CustomCalendar } from "../Others";
import { PrimaryButton, SecondaryButton } from "../Buttons";

// Custom styles
import style from "../../Styles/Components/modal.module.scss";

// Services
import APIService from "../../Services/api-service";
import { _uploadDocumentToS3 } from "../../Services/aws-service";
import { _getFilePath } from "../../Helper/api";
import { _formatDate } from "../../Helper/helper";
import { isValid } from "date-fns";


const ManageBannerModal = (props) => {
    const disabledDays = { before: new Date() };

    const dateRef = useRef(null);
    const bannerRef = useRef(null);
    const mobileRef = useRef(null);

    const [bannerType, setBannerType] = useState(null);
    const [bannerTypeError, setBannerTypeError] = useState("");

    const [navType, setNavType] = useState(null);
    const [navTypeError, setNavTypeError] = useState("");

    const [resourceType, setResourceType] = useState(null);
    const [resourceTypeError, setResourceTypeError] = useState("");

    const [goalType, setGoalType] = useState(null);
    const [goalTypeError, setGoalTypeError] = useState("");

    const [bannerImage, setBannerImage] = useState("");
    const [bannerLoader, setBannerLoader] = useState(false);
    const [bannerImageError, setBannerImageError] = useState("");

    const [image, setImage] = useState("");
    const [imageLoader, setImageLoader] = useState(false);
    const [imageError, setImageError] = useState("");

    const [redirectionUrl, setRedirectionUrl] = useState("");
    const [redirectionUrlError, setRedirectionUrlError] = useState("");

    const [startDate, setStartDate] = useState("DD-MM-YYYY");
    const [startDateError, setStartDateError] = useState("");
    const [startMonth, setStartMonth] = useState(new Date());

    const [endDate, setEndDate] = useState("DD-MM-YYYY");
    const [endDateError, setEndDateError] = useState("");
    const [endMonth, setEndMonth] = useState(new Date());

    const [apiLoader, setApiLoader] = useState(false);

    useEffect(() => {
        var my_modal = document.getElementById("manage-banner");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
            _clearFields();
        });
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        let nav_options = []

        if (props.banner !== null) {
            const banner_index = props.bannerOptions.findIndex((item) => item.value === props.banner.type);
            const banner_type = props.bannerOptions[banner_index]
            setBannerType(banner_type)
            setBannerImage(props.banner.web_image_url);
            setImage(props.banner.image_url);
            if (props.banner.start_date !== null) {
                const [day, month, year] = props.banner.start_date.split('-');
                const start_date = new Date(`${year}-${month}-${day}`);
                _handleDate(start_date, 1);
            }
            if (props.banner.end_date !== null) {
                const [day, month, year] = props.banner.end_date.split('-');
                const end_date = new Date(`${year}-${month}-${day}`);
                _handleDate(end_date, 2);
            }
            setRedirectionUrl(props.banner.redirection_url);

            if (props.banner.nav_type >= 3) {

                const nav_type_index = props.navTypeOptions.findIndex((item) => item.value === props.banner.nav_type);
                const nav_type = props.navTypeOptions[nav_type_index]
                setNavType(nav_type)
            }

            if (props.banner.nav_id !== null) {

                if (props.banner.nav_type === 3) {
                    nav_options = props.resourceOptions
                } else if (props.banner.nav_type === 4) {
                    nav_options = props.goalOptions
                }


                const nav_id_index = nav_options.findIndex((item) => item.value === props.banner.nav_id);
                const nav_id = nav_options[nav_id_index]
                if (props.banner.nav_type === 3) {
                    setResourceType(nav_id)
                } else if (props.banner.nav_type === 4) {
                    setGoalType(nav_id);
                }
            }
        }

        //eslint-disable-next-line
    }, [props.banner])


    function _handleBannerType(select_value) {
        setBannerType(select_value);
        setBannerTypeError("");
    }


    function _handleNavType(select_value) {
        setNavType(select_value);
        setNavTypeError("");
    }


    function _handleResourceType(select_value) {
        setResourceType(select_value);
        setResourceTypeError("");
    }


    function _handleGoalType(select_value) {
        setGoalType(select_value);
        setGoalTypeError("");
    }

    async function _handleWebFileUpload(file) {
        setBannerLoader(true);
        setBannerImageError("");

        const request = {
            file: file.name,
            is_public: true
        }

        await _getFilePath(request).then(async (response) => {
            toast.dismiss();
            await _uploadDocumentToS3(response.data, file).then((result) => {
                setTimeout(() => {
                    setBannerImage(response.data.view_info);
                }, 100)
                setBannerLoader(false);
            }).catch((error) => {
                toast.error(error, {
                    type: "error"
                });
            });

        });
    }

    async function _handleImageFileUpload(file) {
        setImageLoader(true);
        setImageError("");

        const request = {
            file: file.name,
            is_public: true
        }

        await _getFilePath(request).then(async (response) => {
            toast.dismiss();
            await _uploadDocumentToS3(response.data, file).then((result) => {
                setTimeout(() => {
                    setImage(response.data.view_info);
                }, 100);
                setImageLoader(false);
            }).catch((error) => {
                toast.error(error, {
                    type: "error"
                });
            });
        });
    }

    function _handleRedirectionUrl(input_value) {
        setRedirectionUrl(input_value);
        setRedirectionUrlError("");
    }

    function _handleDate(selected_date, type) {
        if (isValid(selected_date)) {
            switch (type) {
                case 1:
                    const start_date = _formatDate(selected_date)
                    setStartDate(start_date);
                    setStartDateError("");
                    setStartMonth(selected_date);
                    break;
                case 2:
                    const end_date = _formatDate(selected_date)
                    setEndDate(end_date);
                    setEndMonth(selected_date);
                    setEndDateError("");
                    break;

                default:
                    break;
            }
        } else {
            switch (type) {
                case 1:
                    setStartDate("DD-MM-YYYY");
                    break;
                case 2:
                    setEndDate("DD-MM-YYYY");
                    break;

                default:
                    break;
            }
        }
    }

    function _handleMenuOpen(value) {
        // modelRef.current.scrollTop = modelRef.current.scrollHeight;
    }

    function _clearFields() {
        setBannerType(null);
        setBannerTypeError(null);
        setBannerImage("");
        setBannerImageError("");
        setImage("");
        setImageError("");
        setRedirectionUrl("");
        setRedirectionUrlError("");
        setStartDate("DD-MM-YYYY");
        setStartDateError("");
        setEndDate("DD-MM-YYYY");
        setEndDateError("");
    }

    function _handleSubmitForm() {
        let valid = true;
        if (bannerType === null) {
            valid = false;
            setBannerTypeError("Banner type is required");
        }

        if (bannerType !== null && bannerType.value === 3 && navType === null) {
            valid = false;
            setNavTypeError("Navigation type is required");
        }

        if (bannerType !== null && bannerType.value === 3) {

            if (startDate === "DD-MM-YYYY") {
                valid = false;
                dateRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
                setStartDateError("Start date is required");
            }
            if (endDate === "DD-MM-YYYY") {
                valid = false;
                dateRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
                setEndDateError("End date is required");
            }
            if (navType !== null && navType.value === 3 && resourceType === null) {
                valid = false;
                setResourceTypeError("Resource type is required");
            }
            if (navType !== null && navType.value === 4 && goalType === null) {
                valid = false;
                setGoalTypeError("Goal type is required");
            }
        }


        if (bannerImage === "") {
            valid = false;
            setBannerImageError("Banner image is required");
            bannerRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
        }
        if (image === "") {
            valid = false;
            setImageError("Image is required");
            mobileRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
        }

        if (valid === true) {
            _manageBanner();
        }
    }

    function _handleSizeError(type) {
        switch (type) {
            case 1: setBannerImageError("File size exceeds 2mb");
                break;
            case 2: setImageError("File size exceeds 2mb");
                break;
            default:
                break
        }
    }



    // API - add/update banner
    const _manageBanner = () => {
        setApiLoader(true);
        const url = "/banner/upsert";
        const request = {
            "banner_id": props.banner !== null ? props.banner.banner_id : null,
            "web_image_url": bannerImage,
            "image_url": image,
            "redirection_url": redirectionUrl,
            "type": bannerType.value,
            "nav_type": navType !== null ? navType.value : null,
            "nav_id": navType !== null ? navType.value === 3 ? resourceType.value : navType.value === 4 ? goalType.value : null : null,
            "start_date": startDate !== "DD-MM-YYYY" ? startDate : null,
            "end_date": endDate !== "DD-MM-YYYY" ? endDate : null,

        }

        APIService(true, url, request).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                toast.success("Banner updated", {
                    type: 'success'
                });
                _clearFields();
                props.bannerUpdated()
                document.getElementById("close-modal").dispatchEvent(new Event("click"));
            } else {
                toast.error(response.message, {
                    type: 'error'
                })
            }
            setApiLoader(false)
        });
    }


    return (
        <Fragment>
            <div className={`modal fade ${style.e_notification_modal}`}
                id="manage-banner"
                tabIndex="-1"
                aria-labelledby="manage-banner"
                aria-hidden="true"
                data-bs-backdrop="true" >
                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                    <div className={`modal-content padding-24px-lr padding-32px-tb ${style.e_content} `}>

                        <div className="d-flex justify-content-between align-items-center mb-4 px-2">
                            <h5 className="e-font-16 e-line-height-24 e-montserrat-semi-bold color-dark-charcoal mb-0">
                                {props.banner !== null ? "Edit banner image" : "Add banner image"}
                            </h5>
                            <Icon icon="close"
                                size={18}
                                id="close-modal"
                                data-bs-dismiss="modal"
                                className='cursor-pointer' />
                        </div>
                        <div className={`${style.e_modal_fields} px-2 pb-2`}>
                            <div className="row padding-32px-top">
                                <div className="col-6 mb-4">
                                    <CustomSelect type={3}
                                        postFix={true}
                                        placeholder="Notification type"
                                        label="Notification type"
                                        error={bannerTypeError}
                                        value={bannerType}
                                        options={props.bannerOptions}
                                        selectChange={_handleBannerType} />
                                </div>
                                {
                                    bannerType !== null && bannerType.value === 3 &&
                                    <div className="col-6 mb-4">
                                        <CustomSelect type={3}
                                            postFix={true}
                                            placeholder="Navigation type"
                                            label="Navigation type"
                                            error={navTypeError}
                                            value={navType}
                                            options={props.navTypeOptions}
                                            selectChange={_handleNavType} />
                                    </div>
                                }
                                {
                                    navType !== null && navType.value === 3 &&
                                    <div className="col-6 mb-4">
                                        <CustomSelect type={3}
                                            postFix={true}
                                            placeholder="Resource type"
                                            label="Resource type"
                                            error={resourceTypeError}
                                            value={resourceType}
                                            options={props.resourceOptions}
                                            selectChange={_handleResourceType} />
                                    </div>
                                }
                                {
                                    navType !== null && navType.value === 4 &&
                                    <div className="col-6 mb-4">
                                        <CustomSelect type={3}
                                            postFix={true}
                                            placeholder="Goal type"
                                            label="Goal type"
                                            error={goalTypeError}
                                            value={goalType}
                                            options={props.goalOptions}
                                            selectChange={_handleGoalType} />
                                    </div>
                                }
                                <div className="col-12 mb-4" ref={bannerRef}>
                                    <CustomFileInput shape="rectangle"
                                        label="Upload web image"
                                        postfix={true}
                                        file={bannerImage}
                                        loader={bannerLoader}
                                        accept={["JPG", "PNG", "JPEG", "GIF"]}
                                        error={bannerImageError}
                                        sizeError={() => _handleSizeError(1)}
                                        onSelectFile={_handleWebFileUpload}
                                        clearFile={() => setBannerImage("")} />
                                </div>
                                <div className="col-6 mb-4" ref={mobileRef}>
                                    <CustomFileInput shape="square"
                                        label="Upload mobile image"
                                        postfix={true}
                                        file={image}
                                        accept={["JPG", "PNG", "JPEG", "GIF"]}
                                        error={imageError}
                                        loader={imageLoader}
                                        sizeError={() => _handleSizeError(2)}
                                        onSelectFile={_handleImageFileUpload}
                                        clearFile={() => setImage("")} />
                                </div>
                                <div className="col-12 mb-4">
                                    <CustomTextInput1 label="Redirection url"
                                        placeHolder="https://mintit.in"
                                        type="text"
                                        inputClass="e-redirection-input"
                                        error={redirectionUrlError}
                                        value={redirectionUrl}
                                        handleChange={_handleRedirectionUrl} />
                                </div>


                            </div>
                            {bannerType !== null && bannerType.value === 3 && <div className="row" >
                                <div className="col-6" >
                                    <CustomCalendar error={startDateError}
                                        bodyDisable={false}
                                        disabledDays={disabledDays}
                                        label="Start date"
                                        postfix={true}
                                        start={startDate}
                                        mode="single"
                                        className={style.e_date_input}
                                        menuOpen={() => _handleMenuOpen()}
                                        defaultMonth={startMonth}
                                        selectDate={(date) => _handleDate(date, 1)} />

                                </div>
                                <div className="col-6" >
                                    <CustomCalendar error={endDateError}
                                        bodyDisable={false}
                                        disabledDays={disabledDays}
                                        label="End date"
                                        postfix={true}
                                        start={endDate}
                                        mode="single"
                                        className={style.e_date_input}
                                        menuOpen={() => _handleMenuOpen()}
                                        defaultMonth={endMonth}
                                        selectDate={(date) => _handleDate(date, 2)} />
                                </div>
                            </div>}
                            <div className="py-3" ref={dateRef}></div>
                        </div>

                        <div className="d-flex gap-8px justify-content-end padding-40px-top px-2">
                            <SecondaryButton label="Cancel"
                                cancel="modal"
                                className="padding-14px-tb padding-50px-lr" />
                            <PrimaryButton label={props.banner !== null ? "Update" : "Add"}
                                className="padding-14px-tb padding-50px-lr"
                                loader={apiLoader}
                                disabled={apiLoader}
                                handleClick={_handleSubmitForm} />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default memo(ManageBannerModal);