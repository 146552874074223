/*
 *   File : list.js
 *   Author : https://evoqins.com
 *   Description : Portfolio list
 *   Version : 1.0.0
*/

// Packages
import { Fragment, useEffect, useState } from "react";
import { Modal } from "bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

// Components
import { CustomTabBar } from "../../../../Components/Tab";
import { PrimaryButton } from "../../../../Components/Buttons";
import { PortfolioCard } from "../../../../Components/Cards";
import { PortfolioDetailModal } from "../../../../Components/Modal";

// Service
import APIService from "../../../../Services/api-service";


const TAB_OPTIONS = [
    {
        label: "Conservative",
        short_name: "conservative",
        id: 1
    },
    {
        label: "Moderate",
        short_name: "moderate",
        id: 2
    },
    {
        label: "Aggressive",
        short_name: "aggressive",
        id: 3
    },
    {
        label: "Very aggressive",
        short_name: "very_aggressive",
        id: 4
    }
]


const ModelPortfolioListing = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [tabIndex, setTabIndex] = useState(TAB_OPTIONS[0].id);

    const [riskTitle, setRiskTitle] = useState(TAB_OPTIONS[0].label);

    const [portfolioDetail, setPortfolioDetail] = useState(null);

    const [pageLoader, setPageLoader] = useState(true);
    const [portfolioList, setPortfolioList] = useState({});

    const [riskType, setRiskType] = useState(TAB_OPTIONS[0].short_name);

    // _forceNavigate(() => {
    //     if (location.state !== null) {
    //         if (location.state.tab_index) {
    //             _handleTab(location.state.tab_index);
    //         }
    //     }
    // })

    useEffect(() => {
        _getPortfolio();
    }, [])

    useEffect(() => {

        if (location.state !== null) {
            if (location.state.tab_index) {
                setTabIndex(location.state.tab_index);
                const index = TAB_OPTIONS.findIndex((item) => item.id === location.state.tab_index);
                setRiskTitle(TAB_OPTIONS[index]['label']);
                setRiskType(TAB_OPTIONS[index]['short_name']);
            }
        } else {
            setTabIndex(1);
            const index = TAB_OPTIONS.findIndex((item) => item.id === 1);
            setRiskTitle(TAB_OPTIONS[index]['label']);
            setRiskType(TAB_OPTIONS[index]['short_name']);
        }
        // eslint-disable-next-line
    }, [location.state]);

    useEffect(() => {
        if (portfolioDetail !== null) {
            const modal = new Modal(document.getElementById("portfolio-detail"));
            modal.show()
        }
    }, [portfolioDetail]);

    function _handleTab(tab_index) {
        setTabIndex(tab_index);
        const index = TAB_OPTIONS.findIndex((item) => item.id === tab_index);
        setRiskTitle(TAB_OPTIONS[index]['label']);
        setRiskType(TAB_OPTIONS[index]['short_name']);

        navigate('/manage-portfolio', { state: { tab_index: tab_index, index: 1, basket: 1 } }, { replace: true })
    }

    function _handlePortfolio() {
        navigate("manage-model", {
            state: {
                portfolio: null,
                type: tabIndex
            }
        });
    }

    function _handleViewPortfolio(detail) {
        setPortfolioDetail(detail);
    }

    // API - list portfolio
    const _getPortfolio = () => {
        const url = "/model-portfolio/list";

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setPortfolioList(response.data);
            } else {
                setPortfolioList({});
            }
            setPageLoader(false);
        })
    }

    return (
        <Fragment>

            <div className="row">
                <div className="col-12">
                    <CustomTabBar data={TAB_OPTIONS}
                        selectedTab={tabIndex}
                        onSelectTab={_handleTab} />
                </div>
                <div className="col-12 padding-32px-top">
                    <div className="d-flex justify-content-between align-items-center">
                        <h4 className="e-montserrat-semi-bold e-font-16 e-line-height-20 color-dark-charcoal mb-0">
                            {riskTitle} model portfolio
                        </h4>
                        <PrimaryButton
                            label="Create model portfolio"
                            className="padding-10px-tb px-3"
                            handleClick={_handlePortfolio} />
                    </div>
                </div>
            </div>
            <div className="row padding-32px-top">
                {
                    pageLoader === true ?
                        <div className="h-80vh d-flex align-items-center justify-content-center">
                            <img src={require("../../../../Assets/Images/loader/page-loader.gif")}
                                alt={"loader"}
                                width={60}
                                height={60}
                            />
                        </div>
                        :

                        portfolioList[`${riskType}`].data.map((item, index) => {
                            return (
                                <div key={index} className="col-lg-6 pb-3">
                                    <PortfolioCard data={item}
                                        index={tabIndex}
                                        viewMore={(data) => _handleViewPortfolio(data)} />
                                </div>
                            )
                        })
                }
            </div>

            {
                portfolioDetail !== null &&
                <PortfolioDetailModal funds={portfolioDetail}
                    closeModal={() => _handleViewPortfolio(null)} />
            }
        </Fragment >
    )
}

export default ModelPortfolioListing