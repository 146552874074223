/*
 *   File : resource-publish-later.js
 *   Author URI : https://evoqins.com
 *   Description : The modal component used for scheduling resource
 *   Integrations : null
 *   Version : v1.1
 */


import { useEffect, useState } from "react";
import style from "../../Styles/Components/resource-publish-later.module.scss";
import { CustomTimePicker } from "../FormElements";
import { CustomCalendar } from "../Others";
import { _formatDate, _formatTime } from "../../Helper/helper";
import { PrimaryButton, SecondaryButton } from "../Buttons";

import { toast } from "react-toastify";
import { format, parse } from "date-fns";
import APIService from "../../Services/api-service";
import { Icon } from "../Icon";

const ResourcePublishLater = (props) => {

    const disabledDays = { before: new Date() };

    const [startDate, setStartDate] = useState("DD-MM-YYYY");
    const [startDateError, setStartDateError] = useState("");
    const [startMonth, setStartMonth] = useState(new Date());

    const [selectedTime, setSelectedTime] = useState(null);
    const [selectedTimeError, setSelectedTimeError] = useState("");

    const [buttonLoader, setButtonLoader] = useState(false);

    useEffect(() => {
        var my_modal = document.getElementById("resource-publish-later");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
        });
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        console.log("startDate", startDate);
    }, [startDate]);

    useEffect(() => {
        console.log("props.data.scheduleDate", props.data.scheduleDate);
        if (props.data.scheduleDate && props.data.scheduleDate !== "DD-MM-YYYY") {
            const [day, month, year] = props.data.scheduleDate.split('-');
            const start_date = new Date(`${year}-${month}-${day}`);
            _handleDate(start_date);
            // setStartMonth(start_date);
        }
        if (props.data.scheduleTime) {
            setSelectedTime(_formatTime(props.data.scheduleTime))
        }
    }, [props])

    function _handleDate(selected_date) {
        const start_date = _formatDate(selected_date)
        setStartDate(start_date);
        setStartDateError("");
        setStartMonth(selected_date);
    }

    function _handleTimeChange(newTime) {
        setSelectedTime(newTime);
        setSelectedTimeError("");
        // You can perform any other actions with the selected time here
    };

    function _validate() {
        let valid = true;

        if (selectedTime === null) {
            setSelectedTimeError("Time is required");
            valid = false;
        }
        if (startDate === "DD-MM-YYYY") {
            setStartDateError("Date is required");
            valid = false;
        }
        if (valid === true) {
            _schedule();
            setButtonLoader(true);
        }
    }

    // API - update resource
    const _schedule = () => {
        let time = ""
        const date = parse(selectedTime, 'hh:mm a', new Date());
        time = format(date, 'HH:mm:ss');

        const url = "/resource/upsert";
        const request = {
            resource_id: props.data.resourceId,
            title: props.data.title,
            category_id: props.data.category.value,
            resource_type_id: props.data.resourceType.value,
            resource_url: props.data.resourceType.value === 1 ? null : props.data.resourceUrl,
            thumbnail_url: props.data.thumbnailUrl,
            banner_url: props.data.bannerUrl,
            content: props.data.editorState,
            is_draft: false,
            schedule_date: startDate,
            schedule_time: time
        }
        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                document.getElementById("close-modal").dispatchEvent(new Event("click"));
                toast.success("Resource updated", {
                    type: "success",
                });
                props.confirm();
            }
            else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
            setButtonLoader(false);
        })
    }

    return (
        <div className={`modal fade ${style.e_resource_publish_later}`}
            id="resource-publish-later"
            tabIndex="-1"
            aria-labelledby="resource-publish-later"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content padding-24px-lr padding-32px-tb ${style.e_content} `}>
                    <Icon icon="close"
                        size={18}
                        id="close-modal"
                        data-bs-dismiss="modal"
                        className='cursor-pointer' />
                    <div className="row d-flex align-items-center mt-4">
                        <div className="col-6">
                            <CustomCalendar error={startDateError}
                                disabledDays={disabledDays}
                                bodyDisable={false}
                                label="Start date"
                                postfix={true}
                                start={startDate}
                                mode="single"
                                className={style.e_date_input}
                                defaultMonth={startMonth}
                                selectDate={_handleDate} />
                        </div>
                        <div className="col-6">
                            <CustomTimePicker label="Schedule time"
                                error={selectedTimeError}
                                postFix={true}
                                time={selectedTime}
                                onTimeChange={_handleTimeChange} />
                        </div>
                    </div>
                    <div className="d-flex gap-8px justify-content-end padding-40px-top px-2">
                        <SecondaryButton label="Cancel"
                            cancel="modal"
                            className="padding-14px-tb padding-50px-lr" />
                        <PrimaryButton label="Send"
                            disabled={buttonLoader}
                            loader={buttonLoader}
                            className="padding-14px-tb padding-50px-lr"
                            handleClick={_validate} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResourcePublishLater