
/*
 *   File : confirm-delete.js
 *   Author URI : https://evoqins.com
 *   Description : Confirm delete modal 
 *   Integrations : null
 *   Version : v1.1
 */

// Packages
import { Fragment, memo, useEffect } from "react";

// Custom components
import { Icon } from "../Icon";

// Custom styles
import style from "../../Styles/Components/modal.module.scss";

const NotificationDetailModal = (props) => {

    useEffect(() => {
        var my_modal = document.getElementById("notification-detail");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
        });
        //eslint-disable-next-line
    }, []);


    return (
        <Fragment>
            <div className={`modal fade ${style.e_notification_wrapper}`}
                id="notification-detail"
                tabIndex="-1"
                aria-labelledby="notification-detail"
                aria-hidden="true"
                data-bs-backdrop="true" >
                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                    <div className={`modal-content padding-32px-all ${style.e_content} `}>
                        <div className="d-flex justify-content-between align-items-center">
                            <h5 className="e-font-16 e-line-height-24 e-montserrat-semi-bold color-dark-charcoal mb-0">
                                View push notification
                            </h5>
                            <Icon icon="close"
                                size={24}
                                id="close-modal"
                                data-bs-dismiss="modal"
                                className='cursor-pointer' />
                        </div>
                        <div className={`margin-32px-top ${style.e_notification_content} padding-20px-all border-radius-16px`}>
                            <p className="gap-4px d-flex align-items-center e-montserrat-semi-bold e-font-20 e-line-height-32 color-white margin-20px-bottom">
                                <img src={require("../../Assets/Images/Icons/minit.svg").default}
                                    alt="logo"
                                    width={38}
                                    height={22}
                                    draggable={false} />
                                {props.data.title}
                            </p>
                            <span className="e-montserrat-regular e-font-20 e-line-height-32 color-white">
                                {props.data.content}
                            </span>
                        </div>
                    </div>

                </div>
            </div>
        </Fragment>
    )
}

export default memo(NotificationDetailModal)